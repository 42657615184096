import {Dispatch} from "redux";
import {FEEDBACK_STORE, FeedbackDispatchTypes} from "./FeedbackActionTypes";
import {AuditFeedback} from "../../../api/prf";
import PrfApiModel from "../../apiModel/PrfApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the Feedback store. */
export const nullifyFeedbackStore = () => {
    return async (dispatch: Dispatch<FeedbackDispatchTypes>) => {
        dispatch({
            type: FEEDBACK_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Sends feedback to clinician in the audit */
export const sendFeedbackForAudit = (feedback: AuditFeedback) => {
    return async (dispatch: Dispatch<FeedbackDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                FEEDBACK_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().giveFeedbackForAudit(feedback),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: FEEDBACK_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
