import React from "react";
import {MentalHealthAndRestraintAudit} from "../../../../../../../api/prf";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyMentalHealthAndOrRestraintAudit = ({
    questions
}: ReadOnlyQuestions<MentalHealthAndRestraintAudit>) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            {/** Observation recorded */}
            <ReadOnlyQuestionAndAnswer
                {...questions.observationRecorded}
                isOptionalQuestion={false}
            />
            {/** BM recorded */}
            <ReadOnlyQuestionAndAnswer {...questions.bmRecorded} isOptionalQuestion={false} />
            {/** Consent obtained */}
            <ReadOnlyQuestionAndAnswer {...questions.consentObtained} isOptionalQuestion={false} />
            {/** Capacity assessed */}
            <ReadOnlyQuestionAndAnswer {...questions.capacityAssessed} isOptionalQuestion={false} />
            {/** Assessment Documented */}
            {questions.assessmentDocumented && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.assessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            {/** Current MH care facility recorded*/}
            <ReadOnlyQuestionAndAnswer
                {...questions.currentMhCareFacilityRecorded}
                isOptionalQuestion={false}
            />
            {/** Psychiatric history recorded */}
            <ReadOnlyQuestionAndAnswer
                {...questions.psychiatricHistoryRecorded}
                isOptionalQuestion={false}
            />
            {/** MH risk assessment undertaken */}
            <ReadOnlyQuestionAndAnswer
                {...questions.mhRiskAssessmentUndertaken}
                isOptionalQuestion={false}
            />
            {/** Suicide risk assessment undertaken */}
            <ReadOnlyQuestionAndAnswer
                {...questions.suicideRiskAssessmentUndertaken}
                isOptionalQuestion={false}
            />
            {/** Patient's behaviour documented */}
            <ReadOnlyQuestionAndAnswer
                {...questions.patientsBehaviourDocumented}
                isOptionalQuestion={false}
            />
            {/** Need for restraint */}
            <ReadOnlyQuestionAndAnswer {...questions.needForRestraint} isOptionalQuestion={false} />
            {/** Restraint proportionate/best interests */}
            {questions.restraintInProportionOrBestInterests && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.restraintInProportionOrBestInterests}
                    isOptionalQuestion={true}
                />
            )}
            {/** Restraint necessary */}
            {questions.restraintNecessary && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.restraintNecessary}
                    isOptionalQuestion={true}
                />
            )}
            {/** Destination */}
            <ReadOnlyQuestionAndAnswer {...questions.destination} isOptionalQuestion={false} />
            {/** Appropriate destination */}
            {questions.appropriateDestination && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.appropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {/** Handover times */}
            {questions.handoverTimes && (
                <ReadOnlyQuestionAndAnswer {...questions.handoverTimes} isOptionalQuestion={true} />
            )}
            {/** Patient outcome reasonable */}
            {questions.patientOutcomeReasonable && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.patientOutcomeReasonable}
                    isOptionalQuestion={true}
                />
            )}
            {/** Pathfinder outcome */}
            {questions.pathFinderOutcome && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.pathFinderOutcome}
                    isOptionalQuestion={true}
                />
            )}
            {/** CAL contacted */}
            {questions.calContacted && (
                <ReadOnlyQuestionAndAnswer {...questions.calContacted} isOptionalQuestion={true} />
            )}
            {/** NEWS2 score recorded */}
            {questions.news2ScoreRecorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.news2ScoreRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {/** Worsening care advice documented */}
            {questions.worseningCareAdviceDocumented && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.worseningCareAdviceDocumented}
                    isOptionalQuestion={true}
                />
            )}
            {/** Call closed correctly */}
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlyMentalHealthAndOrRestraintAudit;
