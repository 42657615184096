import React from "react";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {AuditTypeStats} from "../../../../../../../api/prf";
import {useReportDateView} from "../../../../../../Hooks/useReportDateView";
import {getYearOrMonthTextForReportHeader} from "../../../../Components/Filters/Helpers/filterHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const QualityReportStatsHeader = (props: AuditTypeStats) => {
    const reportDateView = useReportDateView();

    return (
        <React.Fragment>
            <FormHeader
                headerName={`Quality report statistics for ${getUiFriendlyText(
                    props.auditType
                )} audits for ${getYearOrMonthTextForReportHeader(reportDateView)}`}
            />
        </React.Fragment>
    );
};

export default QualityReportStatsHeader;
