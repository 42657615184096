import React from "react";
import {AuditForm} from "../../../../../../api/prf";
import FormHeader from "../../../../../Form/FormHeader";

const AuditComments = (props: AuditForm) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Comments"} />
            <div className="row ml-0 mr-0 mb-4">
                <div className="col">
                    <p style={{minHeight: `150px`}} className="mt-2 mb-0 section-borders p-2">
                        {props.comments}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuditComments;
