import React from "react";
import {
    Answer,
    QuestionWithAnswer,
    RecognitionOfLifeExtinctAudit
} from "../../../../../../../api/prf";
import {useDispatch} from "react-redux";
import {updateRecognitionOfLifeExtinctQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import FormHeader from "../../../../../../Form/FormHeader";

const RecognitionOfLifeExtinctQuestions = (props: RecognitionOfLifeExtinctAudit) => {
    const dispatch = useDispatch();

    /** Updates History of presenting complaint/collapse  */
    const updateHistoryOfPresentingComplaintOrCollapse = (answer: Answer) => {
        const historyOfPresentingComplaintOrCollapse: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaintOrCollapse,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                historyOfPresentingComplaintOrCollapse
            })
        );
    };
    /** Primary Survey  */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** CPR not performed in the last 15 minutes */
    const updateCprNotPerformedInLast15Minutes = (answer: Answer) => {
        const cprNotPerformedInLast15Minutes: QuestionWithAnswer = {
            ...props.cprNotPerformedInLast15Minutes,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                cprNotPerformedInLast15Minutes
            })
        );
    };
    /** Asystole rhythm strip  */
    const updateAsystoleRhythmStrip = (answer: Answer) => {
        const asystoleRhythmStrip: QuestionWithAnswer = {
            ...props.asystoleRhythmStrip,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                asystoleRhythmStrip
            })
        );
    };
    /** Rigor mortis present  */
    const updateRigorMortisPresent = (answer: Answer) => {
        const rigorMortisPresent: QuestionWithAnswer = {
            ...props.rigorMortisPresent,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                rigorMortisPresent
            })
        );
    };
    /** Updates Hypostasis present  */
    const updateHypostasisPresent = (answer: Answer) => {
        const hypostasisPresent: QuestionWithAnswer = {
            ...props.hypostasisPresent,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                hypostasisPresent
            })
        );
    };
    /** Factors incompatible with life present  */
    const updateFactorsIncompatibleWithLifePresent = (answer: Answer) => {
        const factorsIncompatibleWithLifePresent: QuestionWithAnswer = {
            ...props.factorsIncompatibleWithLifePresent,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                factorsIncompatibleWithLifePresent
            })
        );
    };
    /** Updates Time of verification recorded  */
    const updateTimeOfVerificationRecorded = (answer: Answer) => {
        const timeOfVerificationRecorded: QuestionWithAnswer = {
            ...props.timeOfVerificationRecorded,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                timeOfVerificationRecorded
            })
        );
    };
    /** Updates Guidelines followed  */
    const updateGuidelinesFollowed = (answer: Answer) => {
        const guidelinesFollowed: QuestionWithAnswer = {
            ...props.guidelinesFollowed,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                guidelinesFollowed
            })
        );
    };
    /** Updates Police requested   */
    const updatePoliceRequested = (answer: Answer) => {
        const policeRequested: QuestionWithAnswer = {
            ...props.policeRequested,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                policeRequested
            })
        );
    };
    /** Updates Call closed correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateRecognitionOfLifeExtinctQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Recognition Of Life Extinct Audit"} />
            {/** History of presenting complaint/collapse */}
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaintOrCollapse}
                onChange={updateHistoryOfPresentingComplaintOrCollapse}
                isOptionalQuestion={false}
            />
            {/** Primary Survey */}
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            {/** CPR not performed in the last 15 minutes */}
            <QuestionAndAnswer
                {...props.cprNotPerformedInLast15Minutes}
                onChange={updateCprNotPerformedInLast15Minutes}
                isOptionalQuestion={false}
            />
            {/** Asystole rhythm strip */}
            <QuestionAndAnswer
                {...props.asystoleRhythmStrip}
                onChange={updateAsystoleRhythmStrip}
                isOptionalQuestion={false}
            />
            {/** Rigor mortis present */}
            <QuestionAndAnswer
                {...props.rigorMortisPresent}
                onChange={updateRigorMortisPresent}
                isOptionalQuestion={false}
            />
            {/** Hypostasis present */}
            <QuestionAndAnswer
                {...props.hypostasisPresent}
                onChange={updateHypostasisPresent}
                isOptionalQuestion={false}
            />
            {/** Factors incompatible with life present */}
            <QuestionAndAnswer
                {...props.factorsIncompatibleWithLifePresent}
                onChange={updateFactorsIncompatibleWithLifePresent}
                isOptionalQuestion={false}
            />
            {/** Time of verification recorded */}
            <QuestionAndAnswer
                {...props.timeOfVerificationRecorded}
                onChange={updateTimeOfVerificationRecorded}
                isOptionalQuestion={false}
            />
            {/** Guidelines followed */}
            <QuestionAndAnswer
                {...props.guidelinesFollowed}
                onChange={updateGuidelinesFollowed}
                isOptionalQuestion={false}
            />
            {/** Police requested */}
            <QuestionAndAnswer
                {...props.policeRequested}
                onChange={updatePoliceRequested}
                isOptionalQuestion={false}
            />
            {/** Call closed correctly */}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default RecognitionOfLifeExtinctQuestions;
