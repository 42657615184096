import {Dispatch} from "redux";
import {AUDIT_STORE, AuditDispatchTypes} from "./AuditActionTypes";
import {
    calculateScoreForAuditForm,
    createNewBlankAudit,
    generateOutcomeQuestions,
    validateAudit
} from "../helpers/auditHelpers";
import {UserData} from "../../../api/staff";
import {
    AuditForm,
    AuditType,
    BaseAudit,
    CardiacArrestAndROSCAudit,
    CardiacChestPainAudit,
    GeneralAudit,
    MentalHealthAndRestraintAudit,
    NotConveyedAudit,
    OutcomeQuestions,
    OutcomeType,
    PrfAudit,
    RecognitionOfLifeExtinctAudit,
    SepsisAudit,
    StrokeAudit
} from "../../../api/prf";
import store, {RootStore} from "../../Store";
import {generateRecognitionOfLifeExtinctAudit} from "../helpers/recognitionOfLifeExtinctionAuditHelpers";
import {getMentalHealthAndRestraintQuestionsForNewAudit} from "../helpers/mentalHealthAndRestraintAuditHelpers";
import {getCardiacArrestAndRoscQuestionsForNewAudit} from "../helpers/cardiacArrestAndRoscAuditHelpers";
import {getStrokeQuestionsForNewAudit} from "../helpers/strokeAuditHelpers";
import {getGeneralQuestionsForNewAudit} from "../helpers/generalAuditHelpers";
import {getNotConveyedQuestionsForNewAudit} from "../helpers/notConveyedAuditHelpers";
import {getSepsisQuestionsForNewAudit} from "../helpers/sepsisAuditHelpers";
import {getCardiacChestPainQuestionsForNewAudit} from "../helpers/cardiacChestPainAuditHelpers";
import {
    deleteDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers/dist";
import PrfApiModel from "../../apiModel/PrfApiModel";
import {sendFeedbackForAudit} from "../../feedback/actions/FeedbackActions";
import moment from "moment";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";

/** Nukes the store of stale data. */
export const nullifyAuditStore = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

/** Creates new blank audit. */
export const createNewAudit = (user: UserData) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: createNewBlankAudit(user)
        });
    };
};

/** Updates Audit. */
export const setAudit = (audit: PrfAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: audit
        });
    };
};

/** Updates Audit type and will generate specific audit. */
export const setAuditType = (auditType: AuditType) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);
        //Add the specified audit to the audit form
        const updatedAuditForm: AuditForm = addSpecifiedAuditToAuditForm(auditType, auditForm);
        // Update the audit type
        const updatedAudit: PrfAudit = {
            ...audit,
            auditType
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(updatedAudit, updatedAuditForm)
        });
    };
};

/** Saves Audit to the service */
export const saveAuditToService = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        try {
            const audit = state().audit.data;
            if (!audit) return false;
            const auditForm: AuditForm = JSON.parse(audit.payload);
            const validAudit: boolean = validateAudit(audit);

            // The form is not valid, we want to throw all the error messages to the error modal
            if (!validAudit) return false;

            // Calculate the score of the audit before we save it
            audit.score = calculateScoreForAuditForm(auditForm, audit.auditType);
            // Anytime we save, save the current time as the modified date in the linux epoch time
            audit.modifiedDate = moment().unix();

            const cleanedAudit = cleanUpAudit(audit);
            await postDataToServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().savePrfAudit(cleanedAudit),
                statusCodeCallback
            );

            // Once saved the store will be updated, so we get the fresh data from the store here.
            const saveAudit = state().audit.data;
            if (!saveAudit) return false;

            const savedAuditForm: AuditForm = JSON.parse(saveAudit.payload);
            if (!savedAuditForm.sendFeedback) return true;

            // Only continue here if we are sending feedback
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const success = await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sendFeedbackForAudit({auditId: saveAudit.id, comments: savedAuditForm.comments})
            );

            return success;
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** fetches Audit by id */
export const fetchAuditById = (id: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getPrfAudit(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** fetches Audit by id and historic id */
export const fetchAuditByHistoricId = (id: number, hid: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getHistoricAudit(id, hid),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Saves Audit to the service */
export const deleteAuditById = (id: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().deletePrfAudit(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Cleans up audit */
function cleanUpAudit(audit: PrfAudit): PrfAudit {
    if (audit.cadNumber) {
        if (audit.cadNumber.length === 0) {
            audit.cadNumber = undefined;
        }
    }
    if (audit.eventName) {
        if (audit.eventName.length === 0) {
            audit.eventName = undefined;
        }
    }
    return audit;
}

/** Updates the outcome of the form. */
export const setAuditFormOutcome = (outcome: OutcomeType) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);
        //Add the specified audit to the audit form
        auditForm.outcome = outcome;
        // Anytime we select a new option, we want to reset the questions back to their default state
        auditForm.outcomeData = generateOutcomeQuestions();
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

/** Updates the outcome questions. */
export const setAuditFormOutcomeQuestions = (outcomeData: OutcomeQuestions) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);
        //Add the specified audit to the audit form
        auditForm.outcomeData = outcomeData;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

/** Updates the audit form questions. */
export const setAuditFormComments = (comments: string) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);
        //Add the specified audit to the audit form
        auditForm.comments = comments;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

/** Updates the audit form questions. */
export const setAuditFormSendFeedbackToggle = (value: boolean) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);
        //Add the specified audit to the audit form
        auditForm.sendFeedback = value;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

/** Updates Base Questions */
export const updateBaseQuestions = (baseAudit: BaseAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.baseQuestions = baseAudit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates General Questions */
export const updateGeneralQuestions = (audit: GeneralAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.generalAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Not Conveyed Questions */
export const updateNotConveyedQuestions = (audit: NotConveyedAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.notConveyedAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Cardiac Chest Pain Questions */
export const updateCardiacChestPainQuestions = (audit: CardiacChestPainAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.cardiacChestPainAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Sepsis Questions */
export const updateSepsisQuestions = (audit: SepsisAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.sepsisAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Stroke Questions */
export const updateStrokeQuestions = (audit: StrokeAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.strokeAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Cardiac Arrest and Rosc Questions */
export const updateCardiacArrestAndRoscQuestions = (audit: CardiacArrestAndROSCAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.cardiacArrestAndROSCAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Recognition Of Life Extinct Questions */
export const updateRecognitionOfLifeExtinctQuestions = (audit: RecognitionOfLifeExtinctAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.recognitionOfLifeExtinctAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Updates Mental Health and or Restraint Questions */
export const updateMentalHealthAndOrRestraintQuestions = (audit: MentalHealthAndRestraintAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const prfAudit = state().audit.data;

        if (!prfAudit) return;

        //Parse and update the base questions
        const auditForm: AuditForm = JSON.parse(prfAudit.payload);
        auditForm.mentalHealthAndRestraintAudit = audit;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: stringifyPayload(prfAudit, auditForm)
        });
    };
};

/** Takes the audit form and stringifies the payload */
function stringifyPayload(audit: PrfAudit, auditForm: AuditForm): PrfAudit {
    return {
        ...audit,
        payload: JSON.stringify(auditForm)
    };
}

/** Adds specified audit to the form */
function addSpecifiedAuditToAuditForm(auditType: AuditType, auditForm: AuditForm): AuditForm {
    switch (auditType) {
        case AuditType.General:
            return addGeneralFormToAuditForm(auditForm);
        case AuditType.NotConveyed:
            return addNotConveyedFormToAuditForm(auditForm);
        case AuditType.CardiacChestPain:
            return addCardiacChestPainFormToAuditForm(auditForm);
        case AuditType.Sepsis:
            return addSepsisFormToAuditForm(auditForm);
        case AuditType.Stroke:
            return addStrokeFormToAuditForm(auditForm);
        case AuditType.CardiacArrestAndRosc:
            return addCardiacArrestAndRoscFormToAuditForm(auditForm);
        case AuditType.RecognitionOfLifeExtinct:
            return addRecognitionOfLifeExtinctFormToAuditForm(auditForm);
        case AuditType.MentalHealthAndOrRestraint:
            return addMentalHealthAndRestraintFormToAuditForm(auditForm);
        default:
            return auditForm;
    }
}

/** Add the general audit to the audit form whilst nullifying the rest of the audits */
function addGeneralFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: getGeneralQuestionsForNewAudit(),
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}

/** Add the not conveyed audit to the audit form whilst nullifying the rest of the audits */
function addNotConveyedFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: getNotConveyedQuestionsForNewAudit(),
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}

/** Add the Cardiac Arrest and Rosc audit to the audit form whilst nullifying the rest of the audits */
function addCardiacChestPainFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: getCardiacChestPainQuestionsForNewAudit(),
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}

/** Add the sepsis audit to the audit form whilst nullifying the rest of the audits */
function addSepsisFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: getSepsisQuestionsForNewAudit(),
        strokeAudit: undefined
    };
}

/** Add the stroke audit to the audit form whilst nullifying the rest of the audits */
function addStrokeFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: getStrokeQuestionsForNewAudit()
    };
}

/** Add the Cardiac Arrest and Rosc audit to the audit form whilst nullifying the rest of the audits */
function addCardiacArrestAndRoscFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: getCardiacArrestAndRoscQuestionsForNewAudit(),
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}

/** Add the ROLE audit to the audit form whilst nullifying the rest of the audits */
function addRecognitionOfLifeExtinctFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: undefined,
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: generateRecognitionOfLifeExtinctAudit(),
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}

/** Add the Mental health audit to the audit form whilst nullifying the rest of the audits */
function addMentalHealthAndRestraintFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        cardiacArrestAndROSCAudit: undefined,
        cardiacChestPainAudit: undefined,
        generalAudit: undefined,
        mentalHealthAndRestraintAudit: getMentalHealthAndRestraintQuestionsForNewAudit(),
        notConveyedAudit: undefined,
        recognitionOfLifeExtinctAudit: undefined,
        sepsisAudit: undefined,
        strokeAudit: undefined
    };
}
