import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {COMPLIANCE_REPORT_STORE} from "../actions/ComplianceReportActionTypes";
import {ReportResponse} from "../../../../api/prf";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ReportResponse>(null);

/** Infer type of the reducer for code completion and quality */
const complianceReportReducer = (
    state: StoreServiceData<ReportResponse> = defaultState,
    action: ServiceActionTypes<ReportResponse>
): StoreServiceData<ReportResponse> =>
    createReducer(state, action, COMPLIANCE_REPORT_STORE, () => showErrorToast(action.error));

export default complianceReportReducer;
