import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";
import {
    AuditTypeStats,
    AuditType,
    CalendarAuditStats,
    PrfStatsBase,
    ClinicianAuditStats,
    VenueAuditStats
} from "../../../api/prf";
import {ChartData} from "../../../utils/donutChartUtils";
import {green, yellow, red} from "../../../utils/colourUtils";
import {getPercentage, getRandomNumber} from "../../../utils/mathUtils";
import {capitalizeFirstLetter, splitStringByCapitalLetter} from "../../../utils/textUtils";
import {nanoid} from "nanoid";

/** Generates a list of stats objects from a list of venue audit stats. */
export function generateDonutStatForVenueAuditStatsList(
    auditStats: VenueAuditStats[]
): ChartData[] {
    const charts: ChartData[] = [];

    for (const stats of auditStats) {
        charts.push(generateDonutStatForVenueAuditStats(stats));
    }

    return charts;
}

/** Generates a list of stats objects from a list of calendar audit stats. */
export function generateDonutStatForCalendarAuditStatsList(
    auditStats: CalendarAuditStats[]
): ChartData[] {
    const charts: ChartData[] = [];

    for (const stats of auditStats) {
        charts.push(generateDonutStatForCalendarAuditStats(stats));
    }

    return charts;
}

/** Generate the overall compliance of the system */
export function generateOverallComplianceStats(auditStats: AuditTypeStats[]): ChartData {
    const prfStatBase: PrfStatsBase = {
        totalNumberOfAudits: 0,
        numberOfAudits100PercentCompliant: 0,
        numberOfAudits90to99PercentCompliant: 0,
        numberOfAuditsBelow90PercentCompliant: 0
    };

    for (const stat of auditStats) {
        prfStatBase.totalNumberOfAudits += stat.totalNumberOfAudits;
        prfStatBase.numberOfAudits100PercentCompliant += stat.numberOfAudits100PercentCompliant;
        prfStatBase.numberOfAudits90to99PercentCompliant +=
            stat.numberOfAudits90to99PercentCompliant;
        prfStatBase.numberOfAuditsBelow90PercentCompliant +=
            stat.numberOfAuditsBelow90PercentCompliant;
    }

    return createDonutStat(prfStatBase, "Overall Compliance / Total Audits Completed");
}

/** Converts the raw data coming from the service into a donut chart stat for the Calendar Audit Stats. */
export function generateDonutStatForCalendarAuditStats(auditStats: CalendarAuditStats): ChartData {
    return createDonutStat(auditStats, auditStats.calendarName);
}

/** Converts the raw data coming from the service into a donut chart stat for the Venue Audit Stats. */
export function generateDonutStatForVenueAuditStats(auditStats: VenueAuditStats): ChartData {
    return createDonutStat(auditStats, auditStats.venueName);
}

/** Converts the raw data coming from the service into a donut chart stat for the Audit Category Stats. */
export function generateDonutStatListForAuditCategoryListStats(
    auditStats: AuditTypeStats[]
): ChartData[] {
    const charts: ChartData[] = [];

    for (const stat of auditStats) {
        charts.push(generateDonutStatForAuditCategoryStats(stat));
    }
    return charts;
}

/** Converts the raw data coming from the service into a donut chart stat for the Audit Category Stats. */
export function generateDonutStatForAuditCategoryStats(auditStats: AuditTypeStats): ChartData {
    return createDonutStat(
        auditStats,
        capitalizeFirstLetter(splitStringByCapitalLetter(auditStats.auditType))
    );
}

/** Converts the raw data coming from the service into a donut chart stat for the Staff Member Stats. */
export function generateDonutStatForStaffAuditStats(auditStats: ClinicianAuditStats): ChartData {
    return createDonutStat(auditStats, auditStats.clinician.staffName);
}

/** Creates a single segment donut chart */
export function createSingleSegmentDonutChart(
    totalValue: number,
    colour: string,
    outerLabel: string
): ChartData {
    const entry: DataEntry = {
        title: outerLabel,
        value: totalValue,
        color: colour
    };

    return {
        percentageDataEntries: [entry],
        rawValueDataEntries: [entry],
        uids: [nanoid()],
        totalValue,
        outerLabel,
        innerLabel: totalValue.toString()
    };
}

/** Creates the donut stats with the outer label name being populated */
export function createDonutStat(auditStats: PrfStatsBase, outerLabel: string): ChartData {
    const percentageDataEntries: DataEntry[] = [];
    const rawValueDataEntries: DataEntry[] = [];
    const uids: string[] = [];

    const percentages = getPercentagesFromAuditStats(auditStats);
    const rawValues = getRawValuesFromPrfStatsBase(auditStats);

    // Cycle through the values and push to a data entry with the percentage
    // and the correct label and color.
    // There are 3 of each item, ordered correctly
    for (let i = 0; i < percentages.length; ++i) {
        const percentage = percentages[i];
        const rawValue = rawValues[i];

        percentageDataEntries.push({
            title: donutChartStatsLabels[i],
            value: percentage,
            color: donutChartStatsColours[i]
        });

        rawValueDataEntries.push({
            title: donutChartStatsLabels[i],
            value: rawValue,
            color: donutChartStatsColours[i]
        });

        uids.push(nanoid());
    }

    return {
        percentageDataEntries,
        rawValueDataEntries,
        uids,
        innerLabel: "",
        outerLabel,
        totalValue: auditStats.totalNumberOfAudits
    };
}

/** Calculates the percentages of the audit stats and will account for rounding issues. */
export function getPercentagesFromAuditStats(auditStats: PrfStatsBase): number[] {
    const rawValues = getStatPercentagesFromAuditStats(auditStats);

    // We get the raw values and get their percentages.
    const roundedValues = rawValues.map((value: number) => Math.floor(value));

    // Calculate the difference made from adding all the rounded values together and subtracting them from 100
    const diff = 100 - roundedValues.reduce((a: number, b: number) => a + b, 0);

    // Distribute the remaining difference
    return roundedValues.map((value: number, index) => {
        return index < diff ? Math.floor(value) + 1 : Math.floor(value);
    });
}

/** Gets the raw percentages needed for the generation of stats.
 * All stats inherit PrfBaseStats so all Stats can be passed into here*/
export function getStatPercentagesFromAuditStats({
    numberOfAudits90to99PercentCompliant,
    totalNumberOfAudits,
    numberOfAudits100PercentCompliant,
    numberOfAuditsBelow90PercentCompliant
}: PrfStatsBase): number[] {
    return [
        Math.round(getPercentage(numberOfAuditsBelow90PercentCompliant, totalNumberOfAudits)),
        Math.round(getPercentage(numberOfAudits90to99PercentCompliant, totalNumberOfAudits)),
        Math.round(getPercentage(numberOfAudits100PercentCompliant, totalNumberOfAudits))
    ];
}

/** Gets values from PrfStatsBase object */
export function getRawValuesFromPrfStatsBase({
    numberOfAudits90to99PercentCompliant,
    numberOfAudits100PercentCompliant,
    numberOfAuditsBelow90PercentCompliant
}: PrfStatsBase): number[] {
    return [
        numberOfAuditsBelow90PercentCompliant,
        numberOfAudits90to99PercentCompliant,
        numberOfAudits100PercentCompliant
    ];
}

// Labels for donut chart stats
export const donutChartStatsLabels: string[] = [
    "Number of Audits <90% Compliant",
    "Number of Audits 90%-99% Compliant",
    "Number of Audits 100% Compliant"
];

// Colours for donut chart stats.
export const donutChartStatsColours: string[] = [red, yellow, green];

/** Used for development purposes */

/** Generates Staff Audit stats with different values */
export function generateStaffAuditStats(): ClinicianAuditStats {
    return {
        ...generateRandomStats(),
        clinician: {
            staffId: "sssss",
            staffName: "Abs"
        }
    };
}

/** Generates Audit category stats with different values */
export function generateAuditCategoryStats(): AuditTypeStats[] {
    const categories = Object.values(AuditType);

    const auditCategoryListStats: AuditTypeStats[] = [];

    for (const category of categories) {
        auditCategoryListStats.push({
            ...generateRandomStats(),
            auditType: category
        });
    }

    return auditCategoryListStats;
}

/** Generates random stats to be able to append to other stats. */
function generateRandomStats(): PrfStatsBase {
    const numberOfAuditsBelow90PercentCompliant = getRandomNumber(10, 200);
    const numberOfAudits90to99PercentCompliant = getRandomNumber(20, 349);
    const numberOfAudits100PercentCompliant = getRandomNumber(50, 2452);
    const totalNumberOfAudits =
        numberOfAuditsBelow90PercentCompliant +
        numberOfAudits90to99PercentCompliant +
        numberOfAudits100PercentCompliant;

    return {
        totalNumberOfAudits,
        numberOfAuditsBelow90PercentCompliant,
        numberOfAudits90to99PercentCompliant,
        numberOfAudits100PercentCompliant
    };
}
