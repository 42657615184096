import React, {useEffect, useState} from "react";
import {
    AssignedStaffDropdownProps,
    DDProps,
    getSelectedDropdownOptionByValue,
    sortStaffLinkArrayToDropdownProps
} from "../Helpers/dropdownUtils";
import Select, {SingleValue} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {getCalendarEntry} from "../../../store/calendarEntry/actions/CalendarEntryActions";
import {getAssignedStaffMembersFromCalendarEntry} from "../../../store/calendarEntry/helpers/calendarEntryHelpers";
import {StaffLink} from "../../../api/prf";
import {Loading} from "store-fetch-wrappers";

const AssignedStaffDropdown = (props: AssignedStaffDropdownProps) => {
    const [staffOptions, setStaffOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>();
    const dispatch = useDispatch();

    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);

    /** Use the GrsLocation to get the entry */
    useEffect(() => {
        if (!props.location) {
            props.changeOption({staffId: "", staffName: ""});
            return;
        }

        dispatch(getCalendarEntry(props.location.entryId));
    }, [props.location]);

    /** Watch the entry store for changes*/
    useEffect(() => {
        if (calendarEntryStore.loading) return;
        if (calendarEntryStore.error) return;
        if (!calendarEntryStore.data) {
            props.changeOption({staffId: "", staffName: ""});
            return;
        }
        // Get the staff list
        const staffList = getAssignedStaffMembersFromCalendarEntry(calendarEntryStore.data);
        const options = sortStaffLinkArrayToDropdownProps(staffList);
        setStaffOptions(options);

        const option = getSelectedDropdownOptionByValue(props.clinicianId, options);
        setSelectedOption(option);

        props.changeOption(convertDropdownPropToStaffLink(option));
    }, [calendarEntryStore.data, calendarEntryStore.loading, calendarEntryStore.error]);

    /** Fired when a new option is selected */
    const handleStaffChange = (p: SingleValue<DDProps>) => {
        if (!p) {
            setSelectedOption(undefined);
            props.changeOption({staffId: "", staffName: ""});
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOptionByValue(p.value, staffOptions);
        setSelectedOption(option);
        props.changeOption(convertDropdownPropToStaffLink(option));
    };

    /** Converts Dropdown prop to staff link */
    const convertDropdownPropToStaffLink = (ddProp: DDProps): StaffLink => {
        return {
            staffId: ddProp.value.toString(),
            staffName: ddProp.label
        };
    };
    return (
        <React.Fragment>
            {props.location ? (
                <React.Fragment>
                    {calendarEntryStore.loading && (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    )}
                    <Select
                        className={`search-bar ${props.className}`}
                        classNamePrefix="select-options"
                        options={staffOptions}
                        onChange={handleStaffChange}
                        isSearchable={props.searchable}
                        value={selectedOption}
                        noOptionsMessage={() => "No Staff Members Found"}
                        placeholder="Select Staff"
                        isDisabled={props.disabled}
                        isClearable={props.clearable}
                        menuPortalTarget={document.body}
                    />
                </React.Fragment>
            ) : (
                <p className="section-header mt-2 pl-3">Location must be selected</p>
            )}
        </React.Fragment>
    );
};

export default AssignedStaffDropdown;
