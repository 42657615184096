import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const STAFF_AUDIT_STATS_STORE = createStoreState("staff_audit_stats");

interface StaffAuditStatsLoading extends StoreServiceData<ChartData> {
    type: typeof STAFF_AUDIT_STATS_STORE.LOADING;
}

interface StaffAuditStatsError extends StoreServiceData<ChartData> {
    type: typeof STAFF_AUDIT_STATS_STORE.ERROR;
}

interface StaffAuditStatsSuccess extends StoreServiceData<ChartData> {
    type: typeof STAFF_AUDIT_STATS_STORE.SUCCESS;
}

export type StaffAuditStatsDispatchTypes =
    | StaffAuditStatsLoading
    | StaffAuditStatsError
    | StaffAuditStatsSuccess;
