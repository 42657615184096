import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {FEEDBACK_STORE} from "../actions/FeedbackActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<string>("");

/** Infer type of the reducer for code completion and quality */
const feedbackReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, FEEDBACK_STORE, () => showErrorToast(action.error));

export default feedbackReducer;
