import React from "react";
import {SepsisAudit} from "../../../../../../../api/prf";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlySepsisAudit = ({questions}: ReadOnlyQuestions<SepsisAudit>) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer
                {...questions.historyOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.consentObtained} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.capacityAssessed} isOptionalQuestion={false} />
            {questions.assessmentDocumented && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.assessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.observationRecorded}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.gcsRecorded} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.bpRecorded} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.sp02Recorded} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer
                {...questions.respiratoryRateRecorded}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.news2Recorded} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.leadEcg} isOptionalQuestion={false} />
            {questions.managementCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.managementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {questions.interpretationCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.interpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.clinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.differentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.highFlowOxygenAdministered}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.sodiumChlorideAdministered}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.additionalMedicationGiven}
                isOptionalQuestion={false}
            />
            {questions.otherMedicationAdministered && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.otherMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctRouteOfAdministration && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctDosage && (
                <ReadOnlyQuestionAndAnswer {...questions.correctDosage} isOptionalQuestion={true} />
            )}
            {questions.documentedCorrectly && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.documentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctClinicianAdministering && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer {...questions.preAlertPlaced} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer
                {...questions.appropriateDestination}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.handoverTimes} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlySepsisAudit;
