import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AUDIT_HISTORIC_STORE} from "../actions/AuditHistoricActionTypes";
import {AuditHistoryOverview} from "../../../api/prf";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<AuditHistoryOverview>(null);

/** Infer type of the reducer for code completion and quality */
const auditHistoricReducer = (
    state: StoreServiceData<AuditHistoryOverview> = defaultState,
    action: ServiceActionTypes<AuditHistoryOverview>
): StoreServiceData<AuditHistoryOverview> =>
    createReducer(state, action, AUDIT_HISTORIC_STORE, () => showErrorToast(action.error));

export default auditHistoricReducer;
