import React from "react";
import {YesAnswerMonthlyReportResult} from "../../../../../../api/prf";
import OutcomeReportTable from "./OutcomeReportTable";
import OutcomeReportTableHeader from "./OutcomeReportTableHeader";

const OutcomeReportList = (props: YesAnswerMonthlyReportResult) => {
    return (
        <React.Fragment>
            {props.auditTypeResults.length > 0 ? (
                <React.Fragment>
                    <OutcomeReportTableHeader {...props} />
                    <div className="mb-5">
                        <OutcomeReportTable {...props} />
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There is no outcome data for these filters
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default OutcomeReportList;
