import React from "react";
import {Answer, BaseAudit, QuestionWithAnswer} from "../../../../../../api/prf";
import QuestionAndAnswer from "../Question/QuestionAndAnswer";
import {useDispatch} from "react-redux";
import {updateBaseQuestions} from "../../../../../../store/audit/actions/AuditActions";
import {
    generateAccompanyingAdultQuestion,
    generateNameOfSchoolQuestion
} from "../../../../../../store/audit/helpers/baseAuditHelpers";
import FormHeader from "../../../../../Form/FormHeader";

const BaseQuestions = (props: BaseAudit) => {
    const dispatch = useDispatch();

    /** Updates the location of call */
    const updateLocationOfCall = (answer: Answer) => {
        const locationOfCall: QuestionWithAnswer = {
            ...props.locationOfCall,
            answer
        };
        dispatch(updateBaseQuestions({...props, locationOfCall}));
    };

    /** Updates the activation and response times */
    const updateActivationAndResponseTime = (answer: Answer) => {
        const activationAndResponseTimes: QuestionWithAnswer = {
            ...props.activationAndResponseTimes,
            answer
        };
        dispatch(updateBaseQuestions({...props, activationAndResponseTimes}));
    };

    /** Updates the crew details */
    const updateCrewDetails = (answer: Answer) => {
        const crewDetails: QuestionWithAnswer = {
            ...props.crewDetails,
            answer
        };
        dispatch(updateBaseQuestions({...props, crewDetails}));
    };

    /** Updates the patients name */
    const updatePatientsName = (answer: Answer) => {
        const patientsName: QuestionWithAnswer = {
            ...props.patientsName,
            answer
        };
        dispatch(updateBaseQuestions({...props, patientsName}));
    };

    /** Updates the date of birth */
    const updateDateOfBirth = (answer: Answer) => {
        const dob: QuestionWithAnswer = {
            ...props.dob,
            answer
        };
        dispatch(updateBaseQuestions({...props, dob}));
    };

    /** Updates the patients Home Address */
    const updatePatientsHomeAddress = (answer: Answer) => {
        const patientsHomeAddress: QuestionWithAnswer = {
            ...props.patientsHomeAddress,
            answer
        };
        dispatch(updateBaseQuestions({...props, patientsHomeAddress}));
    };

    /** Updates the gp */
    const updateGp = (answer: Answer) => {
        const gp: QuestionWithAnswer = {
            ...props.gp,
            answer
        };
        dispatch(updateBaseQuestions({...props, gp}));
    };

    /** Updates the next of kin */
    const updateNextOfKin = (answer: Answer) => {
        const nextOfKin: QuestionWithAnswer = {
            ...props.nextOfKin,
            answer
        };
        dispatch(updateBaseQuestions({...props, nextOfKin}));
    };

    /** Updates the ethnicity */
    const updateEthnicity = (answer: Answer) => {
        const ethnicity: QuestionWithAnswer = {
            ...props.ethnicity,
            answer
        };
        dispatch(updateBaseQuestions({...props, ethnicity}));
    };

    /** Updates the nhs number */
    const updateNhsNumber = (answer: Answer) => {
        const nhsNumber: QuestionWithAnswer = {
            ...props.nhsNumber,
            answer
        };
        dispatch(updateBaseQuestions({...props, nhsNumber}));
    };

    /** Updates the paediatric patient */
    const updatePaediatricPatient = (answer: Answer) => {
        const paediatricPatient: QuestionWithAnswer = {
            ...props.paediatricPatient,
            answer
        };

        const baseAudit: BaseAudit = {
            ...props,
            paediatricPatient,
            accompanyingAdult:
                answer === Answer.Yes ? generateAccompanyingAdultQuestion() : undefined,
            nameOfSchool: answer === Answer.Yes ? generateNameOfSchoolQuestion() : undefined
        };
        dispatch(updateBaseQuestions(baseAudit));
    };

    /** Updates the name of school */
    const updateNameOfSchool = (answer: Answer) => {
        if (!props.nameOfSchool) return;
        const nameOfSchool: QuestionWithAnswer = {
            ...props.nameOfSchool,
            answer
        };
        dispatch(updateBaseQuestions({...props, nameOfSchool}));
    };

    /** Updates the accompanying adult */
    const updateAccompanyingAdult = (answer: Answer) => {
        if (!props.accompanyingAdult) return;
        const accompanyingAdult: QuestionWithAnswer = {
            ...props.accompanyingAdult,
            answer
        };
        dispatch(updateBaseQuestions({...props, accompanyingAdult}));
    };

    /** Updates the medical history */
    const updateMedicalHistory = (answer: Answer) => {
        const medicalHistory: QuestionWithAnswer = {
            ...props.medicalHistory,
            answer
        };
        dispatch(updateBaseQuestions({...props, medicalHistory}));
    };

    /** Updates the Drug history */
    const updateDrugHistory = (answer: Answer) => {
        const drugHistory: QuestionWithAnswer = {
            ...props.drugHistory,
            answer
        };
        dispatch(updateBaseQuestions({...props, drugHistory}));
    };

    /** Updates the allergies */
    const updateAllergies = (answer: Answer) => {
        const allergies: QuestionWithAnswer = {
            ...props.allergies,
            answer
        };
        dispatch(updateBaseQuestions({...props, allergies}));
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Essential Details"} />
            {/** Location Of Call */}
            <QuestionAndAnswer
                {...props.locationOfCall}
                onChange={updateLocationOfCall}
                isOptionalQuestion={false}
            />
            {/** Activation and response time */}
            <QuestionAndAnswer
                {...props.activationAndResponseTimes}
                onChange={updateActivationAndResponseTime}
                isOptionalQuestion={false}
            />
            {/** Crew Details */}
            <QuestionAndAnswer
                {...props.crewDetails}
                onChange={updateCrewDetails}
                isOptionalQuestion={false}
            />
            {/** Patient's name */}
            <QuestionAndAnswer
                {...props.patientsName}
                onChange={updatePatientsName}
                isOptionalQuestion={false}
            />
            {/** Date of birth */}
            <QuestionAndAnswer
                {...props.dob}
                onChange={updateDateOfBirth}
                isOptionalQuestion={false}
            />
            {/** Patient's home address */}
            <QuestionAndAnswer
                {...props.patientsHomeAddress}
                onChange={updatePatientsHomeAddress}
                isOptionalQuestion={false}
            />
            {/** GP */}
            <QuestionAndAnswer {...props.gp} onChange={updateGp} isOptionalQuestion={false} />
            {/** Next of kin */}
            <QuestionAndAnswer
                {...props.nextOfKin}
                onChange={updateNextOfKin}
                isOptionalQuestion={false}
            />
            {/** Ethnicity */}
            <QuestionAndAnswer
                {...props.ethnicity}
                onChange={updateEthnicity}
                isOptionalQuestion={false}
            />
            {/** Ethnicity */}
            <QuestionAndAnswer
                {...props.nhsNumber}
                onChange={updateNhsNumber}
                isOptionalQuestion={false}
            />
            {/** Paediatric patient */}
            <QuestionAndAnswer
                {...props.paediatricPatient}
                onChange={updatePaediatricPatient}
                isOptionalQuestion={false}
            />
            {props.paediatricPatient.answer === Answer.Yes && (
                <React.Fragment>
                    {props.nameOfSchool && (
                        <React.Fragment>
                            {/** Name of School */}
                            <QuestionAndAnswer
                                {...props.nameOfSchool}
                                onChange={updateNameOfSchool}
                                isOptionalQuestion={true}
                            />
                        </React.Fragment>
                    )}
                    {props.accompanyingAdult && (
                        <React.Fragment>
                            {/** Accompanying adult */}
                            <QuestionAndAnswer
                                {...props.accompanyingAdult}
                                onChange={updateAccompanyingAdult}
                                isOptionalQuestion={true}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {/** Medical history */}
            <QuestionAndAnswer
                {...props.medicalHistory}
                onChange={updateMedicalHistory}
                isOptionalQuestion={false}
            />
            {/** Drug history*/}
            <QuestionAndAnswer
                {...props.drugHistory}
                onChange={updateDrugHistory}
                isOptionalQuestion={false}
            />
            {/** Allergies */}
            <QuestionAndAnswer
                {...props.allergies}
                onChange={updateAllergies}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};
export default BaseQuestions;
