import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AuditTypeStats} from "../../../../api/prf";
import {AUDIT_TYPE_STATS_STORE} from "../actions/AuditTypeStatsActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<AuditTypeStats>(null);

/** Infer type of the reducer for code completion and quality */
const auditTypeStatsReducer = (
    state: StoreServiceData<AuditTypeStats> = defaultState,
    action: ServiceActionTypes<AuditTypeStats>
): StoreServiceData<AuditTypeStats> =>
    createReducer(state, action, AUDIT_TYPE_STATS_STORE, () => showErrorToast(action.error));

export default auditTypeStatsReducer;
