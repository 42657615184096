import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarSummary} from "../../../api/grs";

export const CALENDAR_SUMMARY_LIST_STORE = createStoreState("calendar_summary_list");

interface CalendarSummaryListLoading extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE.LOADING;
}

interface CalendarSummaryListError extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE.LOADING;
}

interface CalendarSummaryListSuccess extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE.LOADING;
}

export type CalendarSummaryListDispatchType =
    | CalendarSummaryListLoading
    | CalendarSummaryListError
    | CalendarSummaryListSuccess;
