import {Answer, CardiacArrestAndROSCAudit, Conveyance, QuestionType} from "../../../api/prf";
import {newQuestionWithAnswer, newQuestionWithConveyance} from "./auditHelpers";
import {generateAppropriateDestination} from "./sharedQuestions";

export function getCardiacArrestAndRoscQuestionsForNewAudit(): CardiacArrestAndROSCAudit {
    return {
        ...generateCardiacArrestAndRoscAudit(),
        endTidalCo2Recorded: undefined,
        endTidalCo2Printed: undefined,
        adrenalineGivenAppropriately: undefined,
        sodiumChlorideGivenAppropriately: undefined,
        amidaroneGivenAppropriately: undefined,
        otherMedicinesGivenAppropriately: undefined,
        leadEcgAchieved: undefined,
        bmRecorded: undefined,
        endTidalCO2Recorded: undefined,
        oxygenDeliveredAndTirated: undefined,
        systolicBpRecorded: undefined,
        bolusScpGiven: undefined,
        appropriateDestination: undefined,
        handoverTimes: undefined,
        timeVerified: undefined,
        guidelinesFollowed: undefined
    };
}

/** Cardiac Arrest and ROSC audit */
export function generateCardiacArrestAndRoscAudit(): CardiacArrestAndROSCAudit {
    return {
        historyOfPresentingComplaintOrCollapse: newQuestionWithAnswer(
            1,
            "History of presenting complaint/collapse?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        documentIfWitnessed: newQuestionWithAnswer(
            1,
            "Documented if witnessed?",
            QuestionType.Normal
        ),
        timeOfBystanderCpr: newQuestionWithAnswer(1, "Time of bystander CPR?", QuestionType.Normal),
        bystanderDefibUsed: newQuestionWithAnswer(
            1,
            "Bystander defibrillator used?",
            QuestionType.Normal
        ),
        timeOfMedicareCrewCpr: newQuestionWithAnswer(
            1,
            "Time of Medicare crew CPR?",
            QuestionType.Normal
        ),
        medicareDefibUsed: newQuestionWithAnswer(
            1,
            "Medicare defibrillator used?",
            QuestionType.Normal
        ),
        initialRhythmRecorded: newQuestionWithAnswer(
            1,
            "Initial rhythm recorded?",
            QuestionType.Normal
        ),
        numberOfShockDelivered: newQuestionWithAnswer(
            1,
            "Number of shocks delivered?",
            QuestionType.Normal
        ),
        airwayCorrectlyManaged: newQuestionWithAnswer(
            2,
            "Airway correctly managed?",
            QuestionType.Normal
        ),
        advancedAirwayPlaced: newQuestionWithAnswer(
            0,
            "Advanced airway placed?",
            QuestionType.Normal
        ),
        endTidalCo2Recorded: generateEndTidalCo2Recorded(),
        endTidalCo2Printed: generateEndTidalCo2Printed(),
        vascularAccessGained: newQuestionWithAnswer(
            1,
            "Vascular access gained?",
            QuestionType.Normal
        ),
        oxygenGivenAppropriately: newQuestionWithAnswer(
            2,
            "Oxygen given appropriately?",
            QuestionType.Normal
        ),
        medicationGiven: newQuestionWithAnswer(1, "Medication given?", QuestionType.Normal),
        adrenalineGivenAppropriately: generateAdrenalineGivenAppropriately(),
        amidaroneGivenAppropriately: generateAmiodaroneGivenAppropriately(),
        sodiumChlorideGivenAppropriately: generateSodiumChlorideGivenAppropriately(),
        otherMedicinesGivenAppropriately: generateOtherMedicinesGivenAppropriately(),
        roscAchieved: newQuestionWithAnswer(0, "ROSC achieved?", QuestionType.Normal),
        leadEcgAchieved: generate12LeadEcgRecorded(),
        bmRecorded: generateBmRecorded(),
        endTidalCO2Recorded: generateEndTidalCO2Recorded(),
        oxygenDeliveredAndTirated: generateOxygenDeliveredAndTitrated(),
        systolicBpRecorded: generateSystolicBpRecorded(),
        bolusScpGiven: generate250mlBolusScpGiven(),
        destination: newQuestionWithConveyance(0, "Destination?"),
        appropriateDestination: generateAppropriateDestination(),
        handoverTimes: generateHandoverTimes(),
        timeVerified: generateTimeVerified(),
        guidelinesFollowed: generateGuidelinesFollowed(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateAdvancedAirwayQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacArrestAndROSCAudit
): CardiacArrestAndROSCAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                endTidalCo2Printed: generateEndTidalCo2Printed(),
                endTidalCo2Recorded: generateEndTidalCo2Recorded()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                endTidalCo2Recorded: undefined,
                endTidalCo2Printed: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacArrestAndROSCAudit
): CardiacArrestAndROSCAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                adrenalineGivenAppropriately: generateAdrenalineGivenAppropriately(),
                amidaroneGivenAppropriately: generateAmiodaroneGivenAppropriately(),
                sodiumChlorideGivenAppropriately: generateSodiumChlorideGivenAppropriately(),
                otherMedicinesGivenAppropriately: generateOtherMedicinesGivenAppropriately()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                adrenalineGivenAppropriately: undefined,
                amidaroneGivenAppropriately: undefined,
                sodiumChlorideGivenAppropriately: undefined,
                otherMedicinesGivenAppropriately: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateRoscAchievedQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacArrestAndROSCAudit
): CardiacArrestAndROSCAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                leadEcgAchieved: generate12LeadEcgRecorded(),
                bmRecorded: generateBmRecorded(),
                endTidalCO2Recorded: generateEndTidalCO2Recorded(),
                oxygenDeliveredAndTirated: generateOxygenDeliveredAndTitrated(),
                systolicBpRecorded: generateSystolicBpRecorded(),
                bolusScpGiven: generate250mlBolusScpGiven()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                leadEcgAchieved: undefined,
                bmRecorded: undefined,
                endTidalCO2Recorded: undefined,
                oxygenDeliveredAndTirated: undefined,
                systolicBpRecorded: undefined,
                bolusScpGiven: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on conveyance */
export function updateCardiacArrestAndRoscAuditBasedOnConveyance(
    conveyance: Conveyance,
    audit: CardiacArrestAndROSCAudit
): CardiacArrestAndROSCAudit {
    switch (conveyance) {
        case Conveyance.Conveyed:
            return {
                ...audit,
                appropriateDestination: generateAppropriateDestination(),
                handoverTimes: generateHandoverTimes(),
                timeVerified: undefined,
                guidelinesFollowed: undefined
            };
        case Conveyance.RecognitionOfLifeExtinct:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                timeVerified: generateTimeVerified(),
                guidelinesFollowed: generateGuidelinesFollowed()
            };
        case Conveyance.NotConveyed:
        case Conveyance.Refused:
        case Conveyance.Referred:
        case Conveyance.NotApplicable:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                timeVerified: undefined,
                guidelinesFollowed: undefined
            };
        default: {
            return audit;
        }
    }
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

function generateEndTidalCo2Recorded() {
    return newQuestionWithAnswer(4, "End tidal C02 recorded?", QuestionType.Normal);
}

function generateEndTidalCo2Printed() {
    return newQuestionWithAnswer(4, "End tidal C02 printed?", QuestionType.Normal);
}

function generateAdrenalineGivenAppropriately() {
    return newQuestionWithAnswer(2, "Adrenaline given appropriately?", QuestionType.Normal);
}

function generateAmiodaroneGivenAppropriately() {
    return newQuestionWithAnswer(2, "Amiodarone given correctly?", QuestionType.Normal);
}

function generateSodiumChlorideGivenAppropriately() {
    return newQuestionWithAnswer(1, "Sodium chloride given appropriately?", QuestionType.Normal);
}

function generateOtherMedicinesGivenAppropriately() {
    return newQuestionWithAnswer(2, "Other medicines given appropriately?", QuestionType.Normal);
}

function generate12LeadEcgRecorded() {
    return newQuestionWithAnswer(4, "12 lead ECG recorded?", QuestionType.Quality);
}

function generateBmRecorded() {
    return newQuestionWithAnswer(4, "BM recorded?", QuestionType.Quality);
}

function generateEndTidalCO2Recorded() {
    return newQuestionWithAnswer(4, "End tidal C02 recorded?", QuestionType.Quality);
}

function generateOxygenDeliveredAndTitrated() {
    return newQuestionWithAnswer(4, "Oxygen delivered and titrated?", QuestionType.Quality);
}

function generateSystolicBpRecorded() {
    return newQuestionWithAnswer(4, "Systolic BP recorded?", QuestionType.Quality);
}

export function generate250mlBolusScpGiven() {
    return newQuestionWithAnswer(4, "250ml bolus SCP given?", QuestionType.Quality);
}

function generateHandoverTimes() {
    return newQuestionWithAnswer(1, "Handover times?", QuestionType.Normal);
}

function generateTimeVerified() {
    return newQuestionWithAnswer(2, "Time verified?", QuestionType.Normal);
}

function generateGuidelinesFollowed() {
    return newQuestionWithAnswer(2, "Guidelines followed?", QuestionType.Normal);
}
