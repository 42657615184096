import React from "react";
import {GrsLocation, PrfAudit, StaffLink} from "../../../../../../api/prf";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarEntryDropdown from "../../../../../Dropdown/Components/CalendarEntryDropdown";
import AssignedStaffDropdown from "../../../../../Dropdown/Components/AssignedStaffDropdown";
import {useDispatch} from "react-redux";
import {setAudit} from "../../../../../../store/audit/actions/AuditActions";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";
import FormRow from "../../../../../Form/FormRow";
import FormHeader from "../../../../../Form/FormHeader";
import {DebounceInput} from "react-debounce-input";

const AuditFormParentDetails = (props: PrfAudit) => {
    const isHistoric = useIsHistoricAudit();
    const dispatch = useDispatch();

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        dispatch(
            setAudit({
                ...props,
                auditDate: moment(date).startOf("day").unix()
            })
        );
    };
    /** Fired when the location details are changed */
    const onLocationChanged = (location: GrsLocation | undefined) => {
        if (!location) {
            // Reset the clinician selected if there is no location
            dispatch(
                setAudit({
                    ...props,
                    grsLocation: undefined,
                    eventName: undefined,
                    cadNumber: undefined
                })
            );
            return;
        }
        dispatch(
            setAudit({
                ...props,
                grsLocation: location,
                eventName: location.entryName
            })
        );
    };
    //
    /** Fired when the clinician details are changed */
    const onClinicianChanged = (staff: StaffLink) => {
        // No location, we  want to nuke the clinician
        if (!props.grsLocation) {
            dispatch(
                setAudit({
                    ...props,
                    clinician: {
                        staffId: "",
                        staffName: ""
                    }
                })
            );
            return;
        }
        dispatch(
            setAudit({
                ...props,
                clinician: staff
            })
        );
    };

    /** Fired when event name is updated */
    const onEventNameChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setAudit({
                ...props,
                eventName: evt.target.value
            })
        );
    };

    /** Fired when CAD number is updated */
    const onCadNameChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setAudit({
                ...props,
                cadNumber: evt.target.value
            })
        );
    };

    return (
        <React.Fragment>
            <div className="mt-4">
                <FormHeader headerName={"Audit Details"} />
                <FormRow rowName={"Date"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={moment.unix(props.auditDate).toDate()}
                        onChange={onDateChanged}
                        dateFormat="dd/MM/yyyy"
                        className={`input-fields audit-table-inputs text-right pr-2 mr-5 ${
                            isHistoric ? "not-allowed" : "interactive"
                        }`}
                        disabled={isHistoric}
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </FormRow>
                <FormRow rowName={"Location"} columnDetailClassName={"pl-0 pr-0"}>
                    <CalendarEntryDropdown
                        startDate={moment.unix(props.auditDate).unix()}
                        changeOption={onLocationChanged}
                        searchable={false}
                        location={props.grsLocation}
                        disabled={isHistoric}
                        className="audit-table-inputs"
                    />
                </FormRow>
                <FormRow rowName={"Clinician"} columnDetailClassName={"pl-0 pr-0"}>
                    <AssignedStaffDropdown
                        clinicianId={props.clinician.staffId}
                        searchable={false}
                        clearable={false}
                        disabled={isHistoric}
                        location={props.grsLocation}
                        changeOption={onClinicianChanged}
                        className="audit-table-inputs"
                    />
                </FormRow>
                <FormRow rowName={"Event"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        value={props.eventName ? props.eventName : ""}
                        onChange={onEventNameChanged}
                        className={`input-fields audit-table-inputs ${
                            isHistoric ? "not-allowed" : ""
                        }`}
                        placeholder="Enter event name here..."
                        disabled={isHistoric}
                    />
                </FormRow>
                <FormRow rowName={"CAD"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        value={props.cadNumber ? props.cadNumber : ""}
                        onChange={onCadNameChanged}
                        className={`input-fields ${isHistoric ? "not-allowed" : ""}`}
                        placeholder="Enter CAD number here...."
                        disabled={isHistoric}
                    />
                </FormRow>
            </div>
        </React.Fragment>
    );
};

export default AuditFormParentDetails;
