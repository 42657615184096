import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CalendarSummary} from "../../../api/grs";
import {CALENDAR_SUMMARY_LIST_STORE} from "../actions/CalendarSummaryListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<CalendarSummary[]>([]);

/** Infer type of the reducer for code completion and quality */
const calendarSummaryListReducer = (
    state: StoreServiceData<CalendarSummary[]> = defaultState,
    action: ServiceActionTypes<CalendarSummary[]>
): StoreServiceData<CalendarSummary[]> =>
    createReducer(state, action, CALENDAR_SUMMARY_LIST_STORE, () => showErrorToast(action.error));

export default calendarSummaryListReducer;
