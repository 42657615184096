import React from "react";
import {Conveyance, QuestionWithConveyance} from "../../../../../../api/prf";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import {Enum} from "../../../../../../utils/enumUtils";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import FormRowQuestion from "../../../../../Form/FormRowQuestion";

const QuestionAndConveyance = (props: QuestionWithConveyanceWithCallback) => {
    const isHistoric = useIsHistoricAudit();
    /** Gets the value of the Conveyance enum from a string  */
    const getConveyanceFromString = (value: string): Conveyance => {
        return Conveyance[value as keyof typeof Conveyance];
    };
    return (
        <React.Fragment>
            <FormRowQuestion
                isOptionalQuestion={false}
                rowName={props.question}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <GenericTypeDropdown
                    enumOptions={props.dropdownOptions}
                    splitByCapitalLetter={true}
                    clearable={false}
                    searchable={false}
                    changeOption={(value: number | string) => {
                        props.onChange(getConveyanceFromString(value.toString()));
                    }}
                    id={props.conveyance}
                    disabled={isHistoric}
                />
            </FormRowQuestion>
        </React.Fragment>
    );
};

export default QuestionAndConveyance;

interface QuestionWithConveyanceWithCallback extends QuestionWithConveyance {
    onChange: (newConveyance: Conveyance) => void;
    dropdownOptions: Enum<any>;
}
