import React from "react";
import {ComponentChildrenProps} from "../../../../utils/componentUtils";

/** Wrapper Component which contains the boxes the stats sit in */
const StatsContainer = ({children}: ComponentChildrenProps) => {
    return (
        <React.Fragment>
            <div className="stats-container">{children}</div>
        </React.Fragment>
    );
};

export default StatsContainer;
