import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const VENUE_AUDIT_STATS_LIST_STORE = createStoreState("venue_audit_stats_list");

interface VenueAuditStatsListLoading extends StoreServiceData<ChartData[]> {
    type: typeof VENUE_AUDIT_STATS_LIST_STORE.LOADING;
}

interface VenueAuditStatsListError extends StoreServiceData<ChartData[]> {
    type: typeof VENUE_AUDIT_STATS_LIST_STORE.ERROR;
}

interface VenueAuditStatsListSuccess extends StoreServiceData<ChartData[]> {
    type: typeof VENUE_AUDIT_STATS_LIST_STORE.SUCCESS;
}

export type VenueAuditStatsDispatchTypes =
    | VenueAuditStatsListLoading
    | VenueAuditStatsListError
    | VenueAuditStatsListSuccess;
