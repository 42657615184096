import moment from "moment";
import {convertStringToBool} from "../../../../../../utils/boolUtils";
import {
    formatUnixToMMMM,
    formatUnixToMMMMYYYY,
    formatUnixToYYYY
} from "../../../../../../utils/momentUtils";

/** Gets generates list of months */
export function getMonthsForYear(): string[] {
    const now = moment();
    const startOfYear = now.clone().startOf("year");
    const endOfYear = now.clone().endOf("year");

    const date = startOfYear.clone().subtract(1, "month");
    const dates: number[] = [];

    while (date.isBefore(endOfYear, "month")) {
        dates.push(
            ...Array(12)
                .fill(0)
                .map(() => date.add(1, "month").clone().unix())
        );
    }

    return dates.map((d: number) => formatUnixToMMMM(d));
}

/** Anytime the request is updated, we want to get this data for the context */
export function getReportDateViewForContext(query: URLSearchParams): ReportDateView {
    const showMonth = query.get("showMonth");
    const dateRangeStart = query.get("dateRangeStart");
    const parsedShowMonth = convertStringToBool(showMonth ? showMonth : "true");
    return {
        showMonth: parsedShowMonth,
        dateStart: dateRangeStart ? +dateRangeStart : 0,
        dateView: parsedShowMonth ? DateView.Month : DateView.Year
    };
}

export interface ReportDateView {
    showMonth: boolean;
    dateView: DateView;
    dateStart: number;
}

// eslint-disable-next-line no-shadow
export enum DateView {
    Month = "Month",
    Year = "Year"
}

/** if we are looking at a year view. we want to ignore this
 * otherwise, we want to get the number of the month to be used as an index */
export function getMonthNumber(reportDateView: ReportDateView): number {
    const {showMonth, dateStart} = reportDateView;

    if (!showMonth) return -1;
    return moment.unix(dateStart).get("month");
}

/** Returns the correct format of the date */
export function getYearOrMonthTextForReportHeader(reportDateView: ReportDateView): string {
    const {dateStart, showMonth} = reportDateView;

    if (showMonth) {
        return formatUnixToMMMMYYYY(dateStart);
    }
    return formatUnixToYYYY(dateStart);
}
