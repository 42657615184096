import React, {useCallback, useEffect, useState} from "react";
import StatsContainer from "./StatsContainer";
import StaffDropdown from "../../../Dropdown/Components/StaffDropdown";
import {BasicStatsRequest, StaffLink} from "../../../../api/prf";
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import {getStaffAuditStatsByStaffId} from "../../../../store/statistics/staff/actions/StaffAuditStatsActions";
import {StatsOptionsContext} from "../../../Statistics/Context/StatsOptionsContext";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import FormRow from "../../../Form/FormRow";
import FormHeader from "../../../Form/FormHeader";
import HorizontalBarChartContainer from "../../../Statistics/Components/HorizontalBarChartContainer";

const BarChartWrapper = WithServiceState(HorizontalBarChartContainer);

/** Staff Compliance Stats */
const StaffComplianceStats = () => {
    const dispatch = useDispatch();
    const staffStatsStore = useSelector((state: RootStore) => state.staffAuditStats);

    const [selectedStaffMember, setSelectedStaffMember] = useState<StaffLink>({
        staffId: "",
        staffName: ""
    });
    const [startDate, setStartDate] = useState<Moment>(moment());

    /** Called when the staff dropdown option has been changed */
    const onStaffMemberChanged = useCallback(
        (staffMember: StaffLink) => {
            setSelectedStaffMember(staffMember);
        },
        [selectedStaffMember]
    );

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Watch for Staff member and date change. If the staff Id length === 0, we want to ignore it */
    useEffect(() => {
        if (selectedStaffMember.staffId.length === 0) return;

        const startOfMonth = startDate.clone().startOf("month");
        const endOfMonth = startDate.clone().endOf("month");

        const request: BasicStatsRequest = {
            creationDate: {
                startDate: startOfMonth.unix(),
                endDate: endOfMonth.unix()
            }
        };

        dispatch(getStaffAuditStatsByStaffId(selectedStaffMember.staffId, request));
    }, [selectedStaffMember, startDate]);

    return (
        <StatsContainer>
            <FormHeader headerName={"Staff Compliance"} />
            <FormRow rowName={"Staff Member"} columnDetailClassName={"pl-0 pr-0"}>
                <StaffDropdown
                    searchable={true}
                    changeOption={onStaffMemberChanged}
                    clearable={false}
                    id={""}
                    disabled={false}
                />
            </FormRow>
            <FormRow rowName={"Month"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={startDate.toDate()}
                    onChange={onDateChanged}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    portalId="root-portal"
                    customInput={<DatePickerInputButton />}
                />
            </FormRow>
            <StatsOptionsContext.Provider value={{showPercentages: true}}>
                <BarChartWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"normal"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...staffStatsStore}
                />
            </StatsOptionsContext.Provider>
        </StatsContainer>
    );
};

export default StaffComplianceStats;
