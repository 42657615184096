import {STAFF_LIST_STORE_STATE, StaffListDispatchTypes} from "./StaffListActionTypes";
import {Dispatch} from "redux";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {RootStore} from "../../Store";

/** Clears the state of the store. */
export const nullifyStaffListStore = () => async (dispatch: Dispatch<StaffListDispatchTypes>) => {
    dispatch({
        type: STAFF_LIST_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    });
};

/** Gets all staff members in the MC database */
export const getAllMedicareStaffMembers = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>, getState: () => RootStore) => {
        try {
            const success = await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );

            if (success) {
                const staffList = getState().staffList.data;

                //Shouldn't be null if we are successful. Needed null check because of typescript "Quirks"
                if (!staffList) return [];

                return staffList;
            }
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets all staff members in the MC database and then returns staff members who match the filter */
export const getStaffMembersByAccessLevel = (accessLevel: StaffAccessLevel) => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>, getState: () => RootStore) => {
        try {
            const success = await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );

            if (success) {
                const staffList = getState().staffList.data;

                //Shouldn't be null if we are successful. Needed null check because of typescript "Quirks"
                if (!staffList) return [];

                // Return filtered staff list.
                return staffList.filter((el: UserData) => el.accessLevel === accessLevel);
            }
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
