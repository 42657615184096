import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {PrfAudit} from "../../../api/prf";

export const AUDIT_STORE = createStoreState("audit_store");

interface AuditStoreLoading extends StoreServiceData<PrfAudit> {
    type: typeof AUDIT_STORE.LOADING;
}
interface AuditStoreError extends StoreServiceData<PrfAudit> {
    type: typeof AUDIT_STORE.LOADING;
}
interface AuditStoreSuccess extends StoreServiceData<PrfAudit> {
    type: typeof AUDIT_STORE.LOADING;
}

export type AuditDispatchTypes = AuditStoreLoading | AuditStoreError | AuditStoreSuccess;
