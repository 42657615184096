import moment from "moment-timezone";
import store from "../store/Store";

/** Formats unix time into readable format (December 2021) etc */
export function formatUnixToMMMMYYYY(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("MMMM YYYY");
}

/** Formats unix time into readable format (Tuesday, 2nd November 2021) etc */
export function formatUnixToDateTime(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("dddd, Do MMMM YYYY");
}

/** Formats unix time into readable format (2021) etc */
export function formatUnixToYYYY(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("YYYY");
}

/** Formats unix time into readable format (Q4 2021) etc */
export function formatUnixToQYYYY(time: number) {
    const timezone = getTimezone();
    return `Q${moment.unix(time).tz(timezone).format("Q YYYY")}`;
}

/** Formats unix time into readable format (08:13 (Fri)) etc */
export function formatUnixToHHmmddd(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("HH:mm (ddd)");
}

/** Formats unix time into readable format (08:13) etc */
export function formatUnixToHHmm(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("HH:mm");
}

/** Formats unix time into readable format (08:13 (Fri)) etc */
export function formatUnixToCustomFormat(time: number, format: string) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format(format);
}

/** Formats unix time into readable format (01/12/2021) etc */
export function formatUnixToDDMMYYYY(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("DD/MM/YYYY");
}

/** Formats unix time into readable format (Wednesday 27th April 2022) etc */
export function formatUnixToddddDoMMMMYYYY(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("dddd Do MMMM YYYY");
}

/** Formats unix time into readable format (April 15, 2022 8:35 PM) etc */
export function formatUnixToLLL(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("LLL");
}

/** Formats unix time into readable format (01/12) etc */
export function formatUnixToDDMM(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("DD/MM");
}

/** Formats unix time into readable format (24th April 2022) etc */
export function formatUnixToDoMMMMYYYY(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("Do MMMM YYYY");
}

export function formatUnixToDDMMYYYYHHmm(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("DD/MM/YYYY HH:mm");
}

/** Formats unix time into readable format (March) etc */
export function formatUnixToMMMM(time: number) {
    const timezone = getTimezone();
    return moment.unix(time).tz(timezone).format("MMMM");
}

function getTimezone() {
    return store.getState().mcConfig.data?.timezone || "Europe/London";
}
