import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const AUDIT_TYPE_STATS_LIST_STORE = createStoreState("audit_type_stats_list");

interface AuditTypeStatsListLoading extends StoreServiceData<ChartData[]> {
    type: typeof AUDIT_TYPE_STATS_LIST_STORE.LOADING;
}

interface AuditTypeStatsListError extends StoreServiceData<ChartData[]> {
    type: typeof AUDIT_TYPE_STATS_LIST_STORE.ERROR;
}

interface AuditTypeStatsListSuccess extends StoreServiceData<ChartData[]> {
    type: typeof AUDIT_TYPE_STATS_LIST_STORE.SUCCESS;
}

export type AuditTypeStatsListDispatchTypes =
    | AuditTypeStatsListLoading
    | AuditTypeStatsListError
    | AuditTypeStatsListSuccess;
