import React from "react";
import {Answer, NotConveyedAudit, QuestionWithAnswer} from "../../../../../../../api/prf";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import {updateNotConveyedQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {useDispatch} from "react-redux";
import {
    update12LeadEcgQuestionBasedOnAnswer,
    updateCapacityAssessedQuestionBasedOnAnswer,
    updateMedicationGivenQuestionBasedOnAnswer
} from "../../../../../../../store/audit/helpers/notConveyedAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const NotConveyedQuestions = (props: NotConveyedAudit) => {
    const dispatch = useDispatch();
    /** Updates history Of Presenting Complaint */
    const updateHistoryOfPresentingComplaint = (answer: Answer) => {
        const historyOfPresentingComplaint: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaint,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                historyOfPresentingComplaint
            })
        );
    };

    /** Updates primary Survey */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates consent Obtained */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates capacity Assessed */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };
        dispatch(
            updateNotConveyedQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates assessment Documented */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates assessment Documented */
    const updateAtLeast2Observations = (answer: Answer) => {
        const atLeast2Observations: QuestionWithAnswer = {
            ...props.atLeast2Observations,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                atLeast2Observations
            })
        );
    };

    /** Updates lead Ecg */
    const updateLeadEcg = (answer: Answer) => {
        const leadEcg: QuestionWithAnswer = {
            ...props.leadEcg,
            answer
        };
        dispatch(
            updateNotConveyedQuestions(
                update12LeadEcgQuestionBasedOnAnswer(answer, {...props, leadEcg})
            )
        );
    };

    /** Updates interpretation Correct */
    const updateInterpretationCorrect = (answer: Answer) => {
        if (!props.interpretationCorrect) return;
        const interpretationCorrect: QuestionWithAnswer = {
            ...props.interpretationCorrect,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                interpretationCorrect
            })
        );
    };

    /** Updates management Correct */
    const updateManagementCorrect = (answer: Answer) => {
        if (!props.managementCorrect) return;
        const managementCorrect: QuestionWithAnswer = {
            ...props.managementCorrect,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                managementCorrect
            })
        );
    };

    /** Updates clinic Assessment Appropriate */
    const updateClinicAssessmentAppropriate = (answer: Answer) => {
        const clinicAssessmentAppropriate: QuestionWithAnswer = {
            ...props.clinicAssessmentAppropriate,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                clinicAssessmentAppropriate
            })
        );
    };

    /** Updates differential Diagnostic Reasonable */
    const updateDifferentialDiagnosticReasonable = (answer: Answer) => {
        const differentialDiagnosticReasonable: QuestionWithAnswer = {
            ...props.differentialDiagnosticReasonable,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                differentialDiagnosticReasonable
            })
        );
    };

    /** Updates medication Given */
    const updateMedicationGiven = (answer: Answer) => {
        const medicationGiven: QuestionWithAnswer = {
            ...props.medicationGiven,
            answer
        };
        dispatch(
            updateNotConveyedQuestions(
                updateMedicationGivenQuestionBasedOnAnswer(answer, {...props, medicationGiven})
            )
        );
    };

    /** Updates appropriate Medication Administered */
    const updateAppropriateMedicationAdministered = (answer: Answer) => {
        if (!props.appropriateMedicationAdministered) return;
        const appropriateMedicationAdministered: QuestionWithAnswer = {
            ...props.appropriateMedicationAdministered,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                appropriateMedicationAdministered
            })
        );
    };

    /** Updates correct Route Of Administration */
    const updateCorrectRouteOfAdministration = (answer: Answer) => {
        if (!props.correctRouteOfAdministration) return;
        const correctRouteOfAdministration: QuestionWithAnswer = {
            ...props.correctRouteOfAdministration,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                correctRouteOfAdministration
            })
        );
    };

    /** Updates correct Dosage */
    const updateCorrectDosage = (answer: Answer) => {
        if (!props.correctDosage) return;
        const correctDosage: QuestionWithAnswer = {
            ...props.correctDosage,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                correctDosage
            })
        );
    };

    /** Updates documented Correctly */
    const updateDocumentedCorrectly = (answer: Answer) => {
        if (!props.documentedCorrectly) return;
        const documentedCorrectly: QuestionWithAnswer = {
            ...props.documentedCorrectly,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                documentedCorrectly
            })
        );
    };

    /** Updates correct Clinician Administering */
    const updateCorrectClinicianAdministering = (answer: Answer) => {
        if (!props.correctClinicianAdministering) return;
        const correctClinicianAdministering: QuestionWithAnswer = {
            ...props.correctClinicianAdministering,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                correctClinicianAdministering
            })
        );
    };

    /** Updates Patient Outcome correctly */
    const updatePatientOutcomeReasonable = (answer: Answer) => {
        const patientOutcomeReasonable: QuestionWithAnswer = {
            ...props.patientOutcomeReasonable,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                patientOutcomeReasonable
            })
        );
    };

    /** Updates Pathfinder Outcome correctly */
    const updatePathfinderOutcome = (answer: Answer) => {
        const pathfinderOutcome: QuestionWithAnswer = {
            ...props.pathfinderOutcome,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                pathfinderOutcome
            })
        );
    };

    /** Updates CAL Contacted correctly */
    const updateCalContacted = (answer: Answer) => {
        const calContacted: QuestionWithAnswer = {
            ...props.calContacted,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                calContacted
            })
        );
    };

    /** Updates News2 Score Recorded correctly */
    const updateNews2ScoreRecorded = (answer: Answer) => {
        const news2ScoreRecorded: QuestionWithAnswer = {
            ...props.news2ScoreRecorded,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                news2ScoreRecorded
            })
        );
    };

    /** Updates Worsening Care Advice Documented */
    const updateWorseningCareAdviceGiven = (answer: Answer) => {
        const worseningCareAdviceGiven: QuestionWithAnswer = {
            ...props.worseningCareAdviceGiven,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                worseningCareAdviceGiven
            })
        );
    };

    /** Updates call Closed Correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateNotConveyedQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Not Conveyed Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaint}
                onChange={updateHistoryOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.atLeast2Observations}
                onChange={updateAtLeast2Observations}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.leadEcg}
                onChange={updateLeadEcg}
                isOptionalQuestion={false}
            />
            {props.managementCorrect && (
                <QuestionAndAnswer
                    {...props.managementCorrect}
                    onChange={updateManagementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {props.interpretationCorrect && (
                <QuestionAndAnswer
                    {...props.interpretationCorrect}
                    onChange={updateInterpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.clinicAssessmentAppropriate}
                onChange={updateClinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.differentialDiagnosticReasonable}
                onChange={updateDifferentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.medicationGiven}
                onChange={updateMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.appropriateMedicationAdministered && (
                <QuestionAndAnswer
                    {...props.appropriateMedicationAdministered}
                    onChange={updateAppropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctRouteOfAdministration && (
                <QuestionAndAnswer
                    {...props.correctRouteOfAdministration}
                    onChange={updateCorrectRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctDosage && (
                <QuestionAndAnswer
                    {...props.correctDosage}
                    onChange={updateCorrectDosage}
                    isOptionalQuestion={true}
                />
            )}
            {props.documentedCorrectly && (
                <QuestionAndAnswer
                    {...props.documentedCorrectly}
                    onChange={updateDocumentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctClinicianAdministering && (
                <QuestionAndAnswer
                    {...props.correctClinicianAdministering}
                    onChange={updateCorrectClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.patientOutcomeReasonable}
                onChange={updatePatientOutcomeReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.pathfinderOutcome}
                onChange={updatePathfinderOutcome}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.calContacted}
                onChange={updateCalContacted}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.news2ScoreRecorded}
                onChange={updateNews2ScoreRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.worseningCareAdviceGiven}
                onChange={updateWorseningCareAdviceGiven}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default NotConveyedQuestions;
