import React, {useEffect, useState} from "react";
import ReportContainer from "../../Components/ReportContainer";
import FilterContainer from "../../../../Filters/FilterContainer";
import {routeNames} from "../../../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {
    fetchComplianceReport,
    nullifyComplianceReportStore
} from "../../../../../store/reports/compliance/actions/ComplianceReportActions";
import {ReportRequest} from "../../../../../api/prf";
import ComplianceReportList from "./Components/ComplianceReportList";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../../../store/Store";
import ReportFooter from "../../Components/ReportFooter";
import ReportFilters from "../../Components/Filters/Components/ReportFilters";

const ComplianceReportListWrapper = WithServiceState(ComplianceReportList);

const ComplianceReport = () => {
    const dispatch = useDispatch();
    const [reportRequest, setReportRequest] = useState<ReportRequest>();
    const complianceReportStore = useSelector((state: RootStore) => state.complianceReport);

    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            },
            {
                name: routeNames.auditComplianceReport.name,
                path: routeNames.auditComplianceReport.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));

        return function () {
            dispatch(nullifyComplianceReportStore());
        };
    }, []);

    /** Fires when compliance report request has changed */
    const onComplianceReportRequestChanged = (request: ReportRequest) => {
        if (!reportRequest) {
            setReportRequest(request);
            fetchPagedReports(request);
            return;
        }
        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;
        setReportRequest(request);
        fetchPagedReports(request);
    };
    // Fetches Audits
    const fetchPagedReports = (request: ReportRequest) => {
        dispatch(fetchComplianceReport(request));
    };
    return (
        <ReportContainer>
            <FilterContainer closed={false}>
                <ReportFilters
                    onRequestChanged={onComplianceReportRequestChanged}
                    showCalendarFilter={true}
                    showStaffFilter={false}
                    showVenueFilter={false}
                    reduceAuditTypeFilters={false}
                />
            </FilterContainer>
            <ComplianceReportListWrapper
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={false}
                placeholderBlockCount={1}
                {...complianceReportStore}
            />
            <ReportFooter />
        </ReportContainer>
    );
};

export default ComplianceReport;
