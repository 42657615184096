import React, {useEffect, useState} from "react";
import StatsContainer from "./StatsContainer";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import {BasicStatsRequest} from "../../../../api/prf";
import {getNumberOfCompletedAudits} from "../../../../store/statistics/numberOfCompletedAudits/actions/NumberOfCompletedAuditsActions";
import {getOverallStatsList} from "../../../../store/statistics/overallCompliance/actions/OverallComplianceActions";
import {StatsOptionsContext} from "../../../Statistics/Context/StatsOptionsContext";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import HorizontalBarChartContainer from "../../../Statistics/Components/HorizontalBarChartContainer";

const OverallComplianceWrapper = WithServiceState(HorizontalBarChartContainer);

/** Overall Compliance Stats. */
const OverallComplianceStats = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>(moment());
    const overallCompliance = useSelector((state: RootStore) => state.overallCompliance);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    // Watch for the date being changed
    useEffect(() => {
        const startOfYear = startDate.clone().startOf("year");
        const endOfYear = startDate.clone().endOf("year");

        const request: BasicStatsRequest = {
            creationDate: {
                startDate: startOfYear.unix(),
                endDate: endOfYear.unix()
            }
        };

        dispatch(getOverallStatsList(request));
        dispatch(getNumberOfCompletedAudits(request.creationDate));
    }, [startDate]);
    return (
        <StatsContainer>
            <FormHeader headerName={"Overall Compliance"} />
            <FormRow rowName={"Year"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={startDate.toDate()}
                    onChange={onDateChanged}
                    showYearPicker
                    dateFormat="yyyy"
                    portalId="root-portal"
                    customInput={<DatePickerInputButton />}
                />
            </FormRow>
            <StatsOptionsContext.Provider value={{showPercentages: true}}>
                <OverallComplianceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...overallCompliance}
                />
            </StatsOptionsContext.Provider>
        </StatsContainer>
    );
};

export default OverallComplianceStats;
