import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const FEEDBACK_STORE = createStoreState("feedback_store");

interface FeedbackStoreLoading extends StoreServiceData<string> {
    type: typeof FEEDBACK_STORE.LOADING;
}
interface FeedbackStoreError extends StoreServiceData<string> {
    type: typeof FEEDBACK_STORE.ERROR;
}
interface FeedbackStoreSuccess extends StoreServiceData<string> {
    type: typeof FEEDBACK_STORE.SUCCESS;
}

export type FeedbackDispatchTypes =
    | FeedbackStoreSuccess
    | FeedbackStoreError
    | FeedbackStoreLoading;
