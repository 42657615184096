import React, {useEffect} from "react";
import {PrfAudit} from "../../../../../../api/prf";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import {CalendarTypeEnum} from "../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {nullifyAuditStore} from "../../../../../../store/audit/actions/AuditActions";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import {getDotClassName} from "../../../../../../store/reports/helpers/reportHelpers";

const ReviewAuditFormParentDetails = (props: PrfAudit) => {
    const dispatch = useDispatch();
    /** Gets the correct location for the audit. */
    const getCorrectLocationForAudit = () => {
        if (!props.grsLocation) return;
        if (!props.grsLocation.calendarType) return;

        const {calendarType, entryName, venueName} = props.grsLocation;
        return calendarType === CalendarTypeEnum.Frontline ? entryName : venueName;
    };

    useEffect(() => {
        return function () {
            dispatch(nullifyAuditStore());
        };
    }, []);
    return (
        <React.Fragment>
            <div className="mt-4">
                <FormHeader headerName={"Audit Details"} />
                <FormRow rowName={"Date"}>
                    <p className="mb-0">{formatUnixToDDMMYYYY(props.auditDate)}</p>
                </FormRow>
                <FormRow rowName={"Location"}>
                    <p className="mb-0">{getCorrectLocationForAudit()}</p>
                </FormRow>
                <FormRow rowName={"Clinician"}>
                    <p className="mb-0">{props.clinician.staffName}</p>
                </FormRow>
                <FormRow rowName={"Event"}>
                    <p className="mb-0">{props.eventName ? props.eventName : `N/A`}</p>
                </FormRow>
                <FormRow rowName={"CAD"}>
                    <p className="mb-0">{props.cadNumber ? props.cadNumber : `N/A`}</p>
                </FormRow>
                <FormRow rowName={"Score"}>
                    <p className="mb-0">
                        {props.score}
                        <span className={`mb-0 ml-2 ${getDotClassName(props)}`} />
                    </p>
                </FormRow>
            </div>
            {/*<table className="audit-table mb-5">*/}
            {/*    <thead>*/}
            {/*        <tr>*/}
            {/*            <th className="read-only-audit-table-header">Date</th>*/}
            {/*            <th className="read-only-audit-table-header">Location</th>*/}
            {/*            <th className="read-only-audit-table-header">Clinician</th>*/}
            {/*            <th className="read-only-audit-table-header">Event</th>*/}
            {/*            <th className="read-only-audit-table-header">CAD</th>*/}
            {/*            <th className="read-only-audit-table-header">Score</th>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {formatUnixToDDMMYYYY(props.auditDate)}*/}
            {/*            </td>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {getCorrectLocationForAudit()}*/}
            {/*            </td>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {props.clinician.staffName}*/}
            {/*            </td>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {props.eventName ? props.eventName : `N/A`}*/}
            {/*            </td>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {props.cadNumber ? props.cadNumber : `N/A`}*/}
            {/*            </td>*/}
            {/*            <td className="read-only-audit-table-header">*/}
            {/*                {props.score}*/}
            {/*                <span className={`mb-0 ml-2 ${getDotClassName(props)}`} />*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*    </thead>*/}
            {/*</table>*/}
        </React.Fragment>
    );
};

export default ReviewAuditFormParentDetails;
