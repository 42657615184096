import {FailQueryResponse} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const FAILED_QUESTIONS_STORE = createStoreState("failed_questions");

interface FailedQuestionsReportLoading extends StoreServiceData<FailQueryResponse> {
    type: typeof FAILED_QUESTIONS_STORE.LOADING;
}
interface FailedQuestionsReportError extends StoreServiceData<FailQueryResponse> {
    type: typeof FAILED_QUESTIONS_STORE.ERROR;
}
interface FailedQuestionsReportSuccess extends StoreServiceData<FailQueryResponse> {
    type: typeof FAILED_QUESTIONS_STORE.SUCCESS;
}

export type FailedQuestionsReportDispatchTypes =
    | FailedQuestionsReportLoading
    | FailedQuestionsReportError
    | FailedQuestionsReportSuccess;
