import {Dispatch} from "redux";
import PrfApiModel from "../../apiModel/PrfApiModel";
import {AuditListRequest} from "../../../api/prf";
import {AUDIT_TRAIL_LOGS_STORE, AuditTrailLogsDispatchTypes} from "./AuditTrailLogsActionTypes";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {createBlankAuditListResponse} from "../reducer/AuditTrailLogsReducer";

/** Nukes store of stale data */
export const nullifyPagedAuditStore = () => {
    return async (dispatch: Dispatch<AuditTrailLogsDispatchTypes>) => {
        dispatch({
            type: AUDIT_TRAIL_LOGS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankAuditListResponse()
        });
    };
};

/** Gets the paged list of audits */
export const getPagedAudits = (request: AuditListRequest) => {
    return async (dispatch: Dispatch<AuditTrailLogsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                AUDIT_TRAIL_LOGS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAuditsListPaged(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_TRAIL_LOGS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
