import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const NUMBER_OF_COMPLETED_AUDITS_STORE = createStoreState("number_of_completed_audits");

interface NumberOfCompletedAuditsLoading extends StoreServiceData<ChartData> {
    type: typeof NUMBER_OF_COMPLETED_AUDITS_STORE.LOADING;
}
interface NumberOfCompletedAuditsError extends StoreServiceData<ChartData> {
    type: typeof NUMBER_OF_COMPLETED_AUDITS_STORE.ERROR;
}
interface NumberOfCompletedAuditsSuccess extends StoreServiceData<ChartData> {
    type: typeof NUMBER_OF_COMPLETED_AUDITS_STORE.SUCCESS;
}

export type NumberOfCompletedAuditsDispatchTypes =
    | NumberOfCompletedAuditsLoading
    | NumberOfCompletedAuditsError
    | NumberOfCompletedAuditsSuccess;
