import React from "react";
import {useReportDateView} from "../../../../../../Hooks/useReportDateView";
import QualityReportMonthViewTable from "./QualityReportMonthViewTable";
import {YesAnswerMonthlyAuditTypeResult} from "../../../../../../../api/prf";
import QualityReportTableHeader from "../QualityReportTableHeader";
import QualityReportYearViewTable from "../YearView/QualityReportYearViewTable";

const QualityReportView = (props: YesAnswerMonthlyAuditTypeResult) => {
    const {showMonth} = useReportDateView();
    return (
        <React.Fragment>
            {props.questions.length > 0 && (
                <React.Fragment>
                    <div className="mt-5 mb-5">
                        <QualityReportTableHeader {...props} />
                        {showMonth ? (
                            <QualityReportMonthViewTable {...props} />
                        ) : (
                            <QualityReportYearViewTable {...props} />
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default QualityReportView;
