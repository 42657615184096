import PrfApiModel from "../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {
    AUDIT_TYPE_STATS_LIST_STORE,
    AuditTypeStatsListDispatchTypes
} from "./AuditTypeStatsListActionTypes";
import {
    generateAuditCategoryStats,
    generateDonutStatListForAuditCategoryListStats
} from "../../helpers/prfStatsHelpers";
import {BasicStatsRequest} from "../../../../api/prf";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyAuditTypeStatsListStore = () => {
    return async (dispatch: Dispatch<AuditTypeStatsListDispatchTypes>) => {
        dispatch({
            type: AUDIT_TYPE_STATS_LIST_STORE.SUCCESS,
            data: [],
            loading: false,
            error: null
        });
    };
};

/** Gets all the stats for the venue audit stats list. */
export const getAuditTypeStatsList = (request: BasicStatsRequest) => {
    return async (dispatch: Dispatch<AuditTypeStatsListDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                AUDIT_TYPE_STATS_LIST_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAllAuditTypeStats(request),
                statusCodeCallback
            );

            const categoryStats = stats
                ? generateDonutStatListForAuditCategoryListStats(stats)
                : [];

            dispatch({
                type: AUDIT_TYPE_STATS_LIST_STORE.SUCCESS,
                error: null,
                loading: false,
                data: categoryStats
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_TYPE_STATS_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** For Development purposes only */
/** Mocks up stats  */
export const generateMockAuditCategoryStats = () => {
    return async (dispatch: Dispatch<AuditTypeStatsListDispatchTypes>) => {
        dispatch({
            type: AUDIT_TYPE_STATS_LIST_STORE.LOADING,
            error: null,
            loading: true
        });

        dispatch({
            type: AUDIT_TYPE_STATS_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: generateDonutStatListForAuditCategoryListStats(generateAuditCategoryStats())
        });
    };
};
