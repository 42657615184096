import React from "react";
import {AuditTrailLogsTableRow} from "../AuditTrailLogsTable";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {useDispatch, useSelector} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {WithServiceState} from "store-fetch-wrappers";
import AuditTrailLogsHistoryTable from "./AuditTrailLogsHistoryTable";
import {RootStore} from "../../../../../store/Store";
import {getAuditHistoricDataByAuditId} from "../../../../../store/auditHistoric/actions/AuditHistoricActions";

const ServiceWrapper = WithServiceState(AuditTrailLogsHistoryTable);

const AuditTrailLogsHistory = (props: AuditTrailLogsTableRow) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    const historicAuditStore = useSelector((state: RootStore) => state.auditHistoric);

    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-3">
                <Tooltip tooltipText={"View Audit History"} size={"md"} place={"left"}>
                    <Icon
                        onClick={async () => {
                            toggle();
                            dispatch(getAuditHistoricDataByAuditId(props.actions));
                        }}
                        rootElement={"Span"}
                        icon={IconType.History}
                        size={"Medium"}
                        className="cursor-pointer"
                    />
                </Tooltip>
            </div>
            <Modal
                modalSize={"lg"}
                title={"Audit History"}
                bodyChildren={
                    <ServiceWrapper
                        loaderType={"normal"}
                        loaderWheelType={"three-ring"}
                        showLoadingText={true}
                        placeholderBlockCount={1}
                        {...historicAuditStore}
                    />
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default AuditTrailLogsHistory;
