import {Answer, QuestionType, SepsisAudit} from "../../../api/prf";
import {newQuestionWithAnswer} from "./auditHelpers";
import {
    generateAssessmentDocumented,
    generateCorrectClinicianAdministering,
    generateCorrectDosage,
    generateCorrectRouteOfAdministration,
    generateDocumentedCorrectly,
    generateHandoverTimes,
    generateInterpretationCorrect,
    generateManagementCorrect
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getSepsisQuestionsForNewAudit(): SepsisAudit {
    return {
        ...generateSepsisAudit(),
        assessmentDocumented: undefined,
        interpretationCorrect: undefined,
        managementCorrect: undefined,
        otherMedicationAdministered: undefined,
        correctRouteOfAdministration: undefined,
        correctDosage: undefined,
        documentedCorrectly: undefined,
        correctClinicianAdministering: undefined
    };
}

/** Sepsis Audit */
export function generateSepsisAudit(): SepsisAudit {
    return {
        historyOfPresentingComplaint: newQuestionWithAnswer(
            1,
            "History of presenting complaint?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        observationRecorded: newQuestionWithAnswer(1, "Observation recorded?", QuestionType.Normal),
        gcsRecorded: newQuestionWithAnswer(4, "GCS recorded?", QuestionType.Quality),
        bpRecorded: newQuestionWithAnswer(4, "BP recorded?", QuestionType.Quality),
        sp02Recorded: newQuestionWithAnswer(4, "Sp02 recorded?", QuestionType.Quality),
        respiratoryRateRecorded: newQuestionWithAnswer(
            4,
            "Respiratory rate recorded?",
            QuestionType.Quality
        ),
        news2Recorded: newQuestionWithAnswer(4, "NEWS2 recorded?", QuestionType.Quality),
        leadEcg: newQuestionWithAnswer(2, "12 lead ECG?", QuestionType.Normal),
        interpretationCorrect: generateInterpretationCorrect(),
        managementCorrect: generateManagementCorrect(),
        clinicAssessmentAppropriate: newQuestionWithAnswer(
            5,
            "Clinical assessment appropriate?",
            QuestionType.Normal
        ),
        differentialDiagnosticReasonable: newQuestionWithAnswer(
            2,
            "Differential diagnosis reasonable?",
            QuestionType.Normal
        ),
        highFlowOxygenAdministered: newQuestionWithAnswer(
            4,
            "High flow oxygen administered?",
            QuestionType.Quality
        ),
        sodiumChlorideAdministered: newQuestionWithAnswer(
            4,
            "Sodium chloride administered?",
            QuestionType.Quality
        ),
        additionalMedicationGiven: newQuestionWithAnswer(
            1,
            "Additional medication given?",
            QuestionType.Normal
        ),
        otherMedicationAdministered: generateOtherMedicationsAdministered(),
        correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
        correctDosage: generateCorrectDosage(),
        documentedCorrectly: generateDocumentedCorrectly(),
        correctClinicianAdministering: generateCorrectClinicianAdministering(),
        preAlertPlaced: newQuestionWithAnswer(4, "Pre-alert placed?", QuestionType.Quality),
        appropriateDestination: newQuestionWithAnswer(
            1,
            "Appropriate destination",
            QuestionType.Normal
        ),
        handoverTimes: generateHandoverTimes(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: SepsisAudit
): SepsisAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function update12LeadEcgQuestionBasedOnAnswer(
    answer: Answer,
    audit: SepsisAudit
): SepsisAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                interpretationCorrect: generateInterpretationCorrect(),
                managementCorrect: generateManagementCorrect()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                interpretationCorrect: undefined,
                managementCorrect: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateAdditionalMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: SepsisAudit
): SepsisAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                otherMedicationAdministered: generateOtherMedicationsAdministered(),
                correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
                correctDosage: generateCorrectDosage(),
                documentedCorrectly: generateDocumentedCorrectly(),
                correctClinicianAdministering: generateCorrectClinicianAdministering()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                otherMedicationAdministered: undefined,
                correctRouteOfAdministration: undefined,
                correctDosage: undefined,
                documentedCorrectly: undefined,
                correctClinicianAdministering: undefined
            };
        default:
            return audit;
    }
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */
function generateOtherMedicationsAdministered() {
    return newQuestionWithAnswer(2, "Other medication administered?", QuestionType.Normal);
}
