import {Dispatch} from "redux";
import PrfApiModel from "../../../apiModel/PrfApiModel";
import {
    NUMBER_OF_COMPLETED_AUDITS_STORE,
    NumberOfCompletedAuditsDispatchTypes
} from "./NumberOfCompletedAuditsActionTypes";
import {DateRange} from "../../../../api/prf";
import {createSingleSegmentDonutChart} from "../../helpers/prfStatsHelpers";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";
import {green} from "../../../../utils/colourUtils";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyNumberOfCompletedAuditsStore = () => {
    return async (dispatch: Dispatch<NumberOfCompletedAuditsDispatchTypes>) => {
        dispatch({
            type: NUMBER_OF_COMPLETED_AUDITS_STORE.SUCCESS,
            data: null,
            loading: false,
            error: null
        });
    };
};

/** Gets the number of completed audits*/
export const getNumberOfCompletedAudits = (request: DateRange) => {
    return async (dispatch: Dispatch<NumberOfCompletedAuditsDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                NUMBER_OF_COMPLETED_AUDITS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getTotalNumberOfCompletedAudits(request),
                statusCodeCallback
            );

            const numberOfCompletedAuditStat = stats
                ? createSingleSegmentDonutChart(stats.count, green, "Number of audits completed")
                : null;

            dispatch({
                type: NUMBER_OF_COMPLETED_AUDITS_STORE.SUCCESS,
                error: null,
                loading: false,
                data: numberOfCompletedAuditStat
            });
        } catch (e: any) {
            dispatch({
                type: NUMBER_OF_COMPLETED_AUDITS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
