import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {CALENDAR_AUDIT_STATS_STORE} from "../actions/CalendarAuditStatsActionTypes";
import {ChartData} from "../../../../utils/donutChartUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ChartData>(null);

/** Infer type of the reducer for code completion and quality */
const calendarAuditStatsReducer = (
    state: StoreServiceData<ChartData> = defaultState,
    action: ServiceActionTypes<ChartData>
): StoreServiceData<ChartData> =>
    createReducer(state, action, CALENDAR_AUDIT_STATS_STORE, () => showErrorToast(action.error));

export default calendarAuditStatsReducer;
