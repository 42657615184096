import React from "react";
import {Answer, OutcomeQuestions, QuestionWithAnswer} from "../../../../../../../api/prf";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import {setAuditFormOutcomeQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {useDispatch} from "react-redux";

const NotConveyedOutcome = (props: OutcomeQuestions) => {
    const dispatch = useDispatch();
    /** Updates discharged */
    const updateDischarged = (answer: Answer) => {
        const discharged: QuestionWithAnswer = {
            ...props.discharged,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, discharged}));
    };

    /** Updates referred To Gp */
    const updateReferredToGp = (answer: Answer) => {
        const referredToGp: QuestionWithAnswer = {
            ...props.referredToGp,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, referredToGp}));
    };

    /** Updates referred To Eolc */
    const updateReferredToEolc = (answer: Answer) => {
        const referredToEolc: QuestionWithAnswer = {
            ...props.referredToEolc,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, referredToEolc}));
    };

    /** Updates referred To Mh */
    const updateReferredToMh = (answer: Answer) => {
        const referredToMh: QuestionWithAnswer = {
            ...props.referredToMh,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, referredToMh}));
    };

    /** Updates referred To Hcp */
    const updateReferredToHcp = (answer: Answer) => {
        const referredToHcp: QuestionWithAnswer = {
            ...props.referredToHcp,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, referredToHcp}));
    };

    /** Updates made Own Way */
    const updateMadeOwnWay = (answer: Answer) => {
        const madeOwnWay: QuestionWithAnswer = {
            ...props.madeOwnWay,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, madeOwnWay}));
    };

    /** Updates taxi Conveyance */
    const updateTaxiConveyance = (answer: Answer) => {
        const taxiConveyance: QuestionWithAnswer = {
            ...props.taxiConveyance,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, taxiConveyance}));
    };

    /** Updates refused */
    const updateRefused = (answer: Answer) => {
        const refused: QuestionWithAnswer = {
            ...props.refused,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, refused}));
    };

    /** Updates made Own Way */
    const updateRole = (answer: Answer) => {
        const role: QuestionWithAnswer = {
            ...props.role,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, role}));
    };

    /** Updates taxi Conveyance */
    const updateNoTrace = (answer: Answer) => {
        const noTrace: QuestionWithAnswer = {
            ...props.noTrace,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, noTrace}));
    };

    /** Updates taxi Conveyance */
    const updateConveyedByNhs = (answer: Answer) => {
        const conveyedByNhs: QuestionWithAnswer = {
            ...props.conveyedByNhs,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, conveyedByNhs}));
    };

    return (
        <React.Fragment>
            <QuestionAndAnswer
                {...props.discharged}
                isOptionalQuestion={false}
                onChange={updateDischarged}
            />
            <QuestionAndAnswer
                {...props.referredToGp}
                isOptionalQuestion={false}
                onChange={updateReferredToGp}
            />
            <QuestionAndAnswer
                {...props.referredToEolc}
                isOptionalQuestion={false}
                onChange={updateReferredToEolc}
            />
            <QuestionAndAnswer
                {...props.referredToMh}
                isOptionalQuestion={false}
                onChange={updateReferredToMh}
            />
            <QuestionAndAnswer
                {...props.referredToHcp}
                isOptionalQuestion={false}
                onChange={updateReferredToHcp}
            />
            <QuestionAndAnswer
                {...props.madeOwnWay}
                isOptionalQuestion={false}
                onChange={updateMadeOwnWay}
            />
            <QuestionAndAnswer
                {...props.taxiConveyance}
                isOptionalQuestion={false}
                onChange={updateTaxiConveyance}
            />
            <QuestionAndAnswer
                {...props.refused}
                isOptionalQuestion={false}
                onChange={updateRefused}
            />
            <QuestionAndAnswer
                {...props.conveyedByNhs}
                isOptionalQuestion={false}
                onChange={updateConveyedByNhs}
            />
            <QuestionAndAnswer {...props.role} isOptionalQuestion={false} onChange={updateRole} />
            <QuestionAndAnswer
                {...props.noTrace}
                isOptionalQuestion={false}
                onChange={updateNoTrace}
            />
        </React.Fragment>
    );
};

export default NotConveyedOutcome;
