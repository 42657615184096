import {useQuery} from "./useQuery";
import {convertStringToBool} from "../../utils/boolUtils";
import {DateView, ReportDateView} from "../Pages/Reports/Components/Filters/Helpers/filterHelpers";

export const useReportDateView = (): ReportDateView => {
    const query = useQuery();

    const showMonth = query.get("showMonth");
    const dateRangeStart = query.get("dateRangeStart");
    const parsedShowMonth = convertStringToBool(showMonth ? showMonth : "true");
    return {
        showMonth: parsedShowMonth,
        dateStart: dateRangeStart ? +dateRangeStart : 0,
        dateView: parsedShowMonth ? DateView.Month : DateView.Year
    };
};
