import React, {useEffect, useState} from "react";
import {ReportResponse, ReportResult} from "../../../../../../api/prf";
import {
    SortedReportResults,
    sortReportResultResponseToSortedResults
} from "../../../../../../store/reports/helpers/reportHelpers";
import VenueReportListComplianceBlock from "./VenueReportListComplianceBlock";

const VenueReportList = (props: ReportResponse) => {
    const [reportResults, setReportResults] = useState<SortedReportResults<ReportResult>[]>(
        sortReportResultResponseToSortedResults("Venues", props.results)
    );

    useEffect(() => {
        setReportResults(sortReportResultResponseToSortedResults("Venues", props.results));
    }, [props]);
    return (
        <React.Fragment>
            {reportResults.length > 0 ? (
                reportResults.map((result, key) => (
                    <VenueReportListComplianceBlock {...result} key={key} />
                ))
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There are no reports that match the filters
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default VenueReportList;
