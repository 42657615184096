import {ReportResponse} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const VENUE_REPORT_STORE = createStoreState("venue_report");

interface VenueReportLoading extends StoreServiceData<ReportResponse> {
    type: typeof VENUE_REPORT_STORE.LOADING;
}
interface VenueReportError extends StoreServiceData<ReportResponse> {
    type: typeof VENUE_REPORT_STORE.ERROR;
}
interface VenueReportSuccess extends StoreServiceData<ReportResponse> {
    type: typeof VENUE_REPORT_STORE.SUCCESS;
}

export type VenueReportDispatchTypes = VenueReportLoading | VenueReportError | VenueReportSuccess;
