import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AuditForm, OutcomeType} from "../../../../../../api/prf";
import {capitalizeFirstLetter, getUiFriendlyText} from "../../../../../../utils/textUtils";
import {setAuditFormOutcome} from "../../../../../../store/audit/actions/AuditActions";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import FormHeader from "../../../../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {RootStore} from "../../../../../../store/Store";

const Outcome = (props: AuditForm) => {
    const prfAudit = useSelector((state: RootStore) => state.audit.data);
    const dispatch = useDispatch();
    const isHistoric = useIsHistoricAudit();

    /** Gets the value of the audit type from the string */
    const getOutcomeTypeFromString = (value: string): OutcomeType => {
        return OutcomeType[value as keyof typeof OutcomeType];
    };

    /** Will select the audit and will be used to generate all the selected report*/
    const onOutcomeSelected = (outcome: string) => {
        const selectedOutcome = getOutcomeTypeFromString(outcome);

        // If the user clicks the same audit when they have already completed the audit, this will prevent them from deleting all their progress
        if (selectedOutcome === props.outcome) return;
        dispatch(setAuditFormOutcome(selectedOutcome));
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Outcome"} />
            <div className="row ml-0 mr-0 mt-4">
                {Object.keys(OutcomeType).map((outcome: string, index: number) => {
                    if (outcome != capitalizeFirstLetter(OutcomeType.None) && prfAudit) {
                        return (
                            <React.Fragment key={index}>
                                <div className="col-sm-4 mb-3 outcome-button">
                                    <MCButton
                                        disabled={isHistoric}
                                        size={ButtonSize.ExtraLarge}
                                        innerValue={getUiFriendlyText(outcome)}
                                        onClick={() => onOutcomeSelected(outcome)}
                                        colour={
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            props.outcome === OutcomeType[outcome]
                                                ? ButtonColourOptions.Yellow
                                                : ButtonColourOptions.DarkBlue
                                        }
                                        className={"w-100"}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }
                })}
            </div>
        </React.Fragment>
    );
};

export default Outcome;
