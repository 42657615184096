import React from "react";
import {AuditForm, OutcomeType} from "../../../../../../api/prf";
import ReadOnlyPatientReferralOutcome from "./Outcomes/ReadOnlyPatientReferralOutcome";
import ReadOnlyConveyedOutcome from "./Outcomes/ReadOnlyConveyedOutcome";
import ReadOnlyNotConveyedOutcome from "./Outcomes/ReadOnlyNotConveyedOutcome";

const ReadOnlyOutcomeData = (props: AuditForm) => {
    return (
        <React.Fragment>
            {props.outcome === OutcomeType.Conveyed && (
                <ReadOnlyConveyedOutcome {...props.outcomeData} />
            )}
            {props.outcome === OutcomeType.NotConveyed && (
                <ReadOnlyNotConveyedOutcome {...props.outcomeData} />
            )}
            {props.outcome === OutcomeType.PatientReferral && (
                <ReadOnlyPatientReferralOutcome {...props.outcomeData} />
            )}
        </React.Fragment>
    );
};

export default ReadOnlyOutcomeData;
