import React, {useEffect, useState} from "react";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import DatePicker from "react-datepicker";
import {AuditType, FailQueryRequest, StaffLink} from "../../../../../../api/prf";
import moment, {Moment} from "moment";
import {capitalizeFirstLetter} from "../../../../../../utils/textUtils";
import {convertStringToBool} from "../../../../../../utils/boolUtils";
import {useQuery} from "../../../../../Hooks/useQuery";
import {RequestFilterProps} from "../../../../../Filters/helpers/filterHelpers";
import {useHistory} from "react-router-dom";
import {
    AllAuditTypeFilters,
    getAuditTypeFromString
} from "../../../Components/Filters/Components/ReportFilters";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";
import DateSwitcher from "../../../Components/Filters/Components/DateSwitcher";
import StaffDropdown from "../../../../../Dropdown/Components/StaffDropdown";

const FailedQuestionsFilters = (props: RequestFilterProps<FailQueryRequest>) => {
    const [auditType, setAuditType] = useState<AuditType>(AuditType.All);
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [showMonth, setShowMonth] = useState<boolean>();
    const [staffMember, setStaffMember] = useState<StaffLink>({staffId: "", staffName: ""});
    const query = useQuery();
    const history = useHistory();

    /** Upon mounting, check for the query strings*/
    useEffect(() => {
        const auditFilter = query.get("auditType");
        const staffFilter = query.get("staffId");
        const startDateFilter = query.get("dateRangeStart");
        const isMonthShown = query.get("showMonth");

        if (auditFilter) {
            setAuditType(getAuditTypeFromString(capitalizeFirstLetter(auditFilter)));
        }

        if (staffFilter) {
            setStaffMember({
                ...staffMember,
                staffId: staffFilter
            });
        }
        if (startDateFilter) {
            setStartDate(moment.unix(+startDateFilter));
        }

        setShowMonth(isMonthShown ? convertStringToBool(isMonthShown) : true);
    }, []);

    /** Updates the audit type when it is changed in the dropdown */
    const onAuditTypeChanged = (value: string | number) => {
        setAuditType(getAuditTypeFromString(value.toString()));
    };

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Triggered when date has been switched */
    const onDateSwitched = (value: boolean) => {
        setShowMonth(value);
    };

    /** Updates the staff member when it is changed in the dropdown */
    const onStaffMemberChanged = (staff: StaffLink) => {
        setStaffMember(staff);
    };

    useEffect(() => {
        if (showMonth === undefined) return;
        const request = buildRequest(auditType, startDate, staffMember.staffId, showMonth);

        buildUrlHistory(request);
        props.onRequestChanged(request);
    }, [auditType, startDate, staffMember, showMonth]);

    const buildRequest = (
        incomingAuditType: AuditType,
        incomingStartDate: Moment,
        staffId: string,
        shouldShowMonth: boolean
    ): FailQueryRequest => {
        const unitOfTime = shouldShowMonth ? "month" : "year";
        return {
            minDate: incomingStartDate.clone().startOf(unitOfTime).unix(),
            maxDate: incomingStartDate.clone().endOf(unitOfTime).unix(),
            auditType: incomingAuditType === AuditType.All ? undefined : incomingAuditType,
            clinicianId: staffId.length > 0 ? staffId : undefined
        };
    };

    const buildUrlHistory = (request: FailQueryRequest) => {
        const queryStrings: string[] = [];

        if (request.auditType) {
            queryStrings.push(`auditType=${request.auditType}`);
        }

        if (request.clinicianId) {
            queryStrings.push(`staffId=${request.clinicianId}`);
        }

        if (showMonth !== undefined) {
            queryStrings.push(`showMonth=${showMonth}`);
        }

        queryStrings.push(`dateRangeStart=${request.minDate}`);
        queryStrings.push(`dateRangeEnd=${request.maxDate}`);

        history.push({search: `?${queryStrings.join("&")}`});
    };
    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Audit Type</th>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <GenericTypeDropdown
                                enumOptions={AllAuditTypeFilters}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={false}
                                changeOption={onAuditTypeChanged}
                                id={auditType}
                                disabled={false}
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker={showMonth}
                                showYearPicker={!showMonth}
                                dateFormat={!showMonth ? "yyyy" : "MMMM yyyy"}
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {showMonth !== undefined && (
                                <DateSwitcher showMonth={showMonth} onChange={onDateSwitched} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Staff Member</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                searchable={true}
                                changeOption={onStaffMemberChanged}
                                clearable={true}
                                id={staffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Audit Type</th>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <GenericTypeDropdown
                                enumOptions={AllAuditTypeFilters}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={false}
                                changeOption={onAuditTypeChanged}
                                id={auditType}
                                disabled={false}
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker={showMonth}
                                showYearPicker={!showMonth}
                                dateFormat={!showMonth ? "yyyy" : "MMMM yyyy"}
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {showMonth !== undefined && (
                                <DateSwitcher showMonth={showMonth} onChange={onDateSwitched} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Staff Member</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                searchable={true}
                                changeOption={onStaffMemberChanged}
                                clearable={true}
                                id={staffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Audit Type</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            <GenericTypeDropdown
                                enumOptions={AllAuditTypeFilters}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={false}
                                changeOption={onAuditTypeChanged}
                                id={auditType}
                                disabled={false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Date</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker={showMonth}
                                showYearPicker={!showMonth}
                                dateFormat={!showMonth ? "yyyy" : "MMMM yyyy"}
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </td>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            {showMonth !== undefined && (
                                <DateSwitcher showMonth={showMonth} onChange={onDateSwitched} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Staff Member</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                searchable={true}
                                changeOption={onStaffMemberChanged}
                                clearable={true}
                                id={staffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default FailedQuestionsFilters;
