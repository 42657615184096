import React, {useEffect, useState} from "react";
import {AuditForm} from "../../../../../../api/prf";
import {useDispatch} from "react-redux";
import {
    setAuditFormComments,
    setAuditFormSendFeedbackToggle
} from "../../../../../../store/audit/actions/AuditActions";
import Toggle, {ToggleSize} from "../../../../../Toggle/Toggle";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import FormHeader from "../../../../../Form/FormHeader";
import {DebounceInput} from "react-debounce-input";

const AuditComments = (props: AuditForm) => {
    const [toggled, setToggled] = useState<boolean>(props.sendFeedback);
    const dispatch = useDispatch();
    const isHistoric = useIsHistoricAudit();

    /** Updates the QA value on the administration */
    const onSendFeedbackChanged = (value: boolean) => {
        setToggled((value = !value));
    };

    useEffect(() => {
        dispatch(setAuditFormSendFeedbackToggle(toggled));
    }, [toggled]);

    return (
        <React.Fragment>
            <FormHeader headerName={"Comments"} />
            <div className="row ml-0 mr-0 mb-2 mt-4">
                <div className="col pl-0 pr-0">
                    <DebounceInput
                        className={`input-fields ${isHistoric ? "not-allowed" : ""}`}
                        element={"textarea"}
                        style={{minHeight: `150px`}}
                        value={props.comments}
                        onChange={(event) => {
                            dispatch(setAuditFormComments(event.target.value));
                        }}
                        placeholder="Enter comments here..."
                        disabled={isHistoric}
                    />
                </div>
            </div>
            <div className="row ml-0 mr-0">
                <div className={"col pt-2 pb-2"}>
                    <p className="mb-0">Send Feedback?</p>
                </div>
            </div>
            <div className="row ml-0 mr-0">
                <div className={"col pt-2 pb-2"}>
                    <Toggle
                        checked={toggled}
                        onChange={() => onSendFeedbackChanged(toggled)}
                        size={ToggleSize.Large}
                        disabled={isHistoric}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuditComments;
