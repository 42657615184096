import React from "react";
import {AuditForm} from "../../../../../../api/prf";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import FormHeader from "../../../../../Form/FormHeader";

const ReadOnlyOutcome = (props: AuditForm) => {
    return (
        <React.Fragment>
            <FormHeader headerName={`Outcome - ${getUiFriendlyText(props.outcome)}`} />
        </React.Fragment>
    );
};

export default ReadOnlyOutcome;
