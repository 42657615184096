import React from "react";
import {Answer, GeneralAudit, QuestionWithAnswer} from "../../../../../../../api/prf";
import {useDispatch} from "react-redux";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import {updateGeneralQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {
    update12LeadEcgQuestionBasedOnAnswer,
    updateCapacityAssessedQuestionBasedOnAnswer,
    updateDestinationQuestionBasedOnAnswer,
    updateMedicationGivenQuestionBasedOnAnswer
} from "../../../../../../../store/audit/helpers/generalAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const GeneralQuestions = (props: GeneralAudit) => {
    const dispatch = useDispatch();

    /** Updates history Of Presenting Complaint */
    const updateHistoryOfPresentingComplaint = (answer: Answer) => {
        const historyOfPresentingComplaint: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaint,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                historyOfPresentingComplaint
            })
        );
    };

    /** Updates primary Survey */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates consent Obtained */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates capacity Assessed */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };
        dispatch(
            updateGeneralQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates assessment Documented */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates observation Recorded */
    const updateObservationRecorded = (answer: Answer) => {
        const observationRecorded: QuestionWithAnswer = {
            ...props.observationRecorded,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                observationRecorded
            })
        );
    };

    /** Updates lead Ecg */
    const updateLeadEcg = (answer: Answer) => {
        const leadEcg: QuestionWithAnswer = {
            ...props.leadEcg,
            answer
        };
        dispatch(
            updateGeneralQuestions(
                update12LeadEcgQuestionBasedOnAnswer(answer, {...props, leadEcg})
            )
        );
    };

    /** Updates interpretation Correct */
    const updateInterpretationCorrect = (answer: Answer) => {
        if (!props.interpretationCorrect) return;
        const interpretationCorrect: QuestionWithAnswer = {
            ...props.interpretationCorrect,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                interpretationCorrect
            })
        );
    };

    /** Updates management Correct */
    const updateManagementCorrect = (answer: Answer) => {
        if (!props.managementCorrect) return;
        const managementCorrect: QuestionWithAnswer = {
            ...props.managementCorrect,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                managementCorrect
            })
        );
    };

    /** Updates clinic Assessment Appropriate */
    const updateClinicAssessmentAppropriate = (answer: Answer) => {
        const clinicAssessmentAppropriate: QuestionWithAnswer = {
            ...props.clinicAssessmentAppropriate,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                clinicAssessmentAppropriate
            })
        );
    };

    /** Updates differential Diagnostic Reasonable */
    const updateDifferentialDiagnosticReasonable = (answer: Answer) => {
        const differentialDiagnosticReasonable: QuestionWithAnswer = {
            ...props.differentialDiagnosticReasonable,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                differentialDiagnosticReasonable
            })
        );
    };

    /** Updates medication Given */
    const updateMedicationGiven = (answer: Answer) => {
        const medicationGiven: QuestionWithAnswer = {
            ...props.medicationGiven,
            answer
        };
        dispatch(
            updateGeneralQuestions(
                updateMedicationGivenQuestionBasedOnAnswer(answer, {...props, medicationGiven})
            )
        );
    };

    /** Updates appropriate Medication Administered */
    const updateAppropriateMedicationAdministered = (answer: Answer) => {
        if (!props.appropriateMedicationAdministered) return;
        const appropriateMedicationAdministered: QuestionWithAnswer = {
            ...props.appropriateMedicationAdministered,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                appropriateMedicationAdministered
            })
        );
    };

    /** Updates correct Route Of Administration */
    const updateCorrectRouteOfAdministration = (answer: Answer) => {
        if (!props.correctRouteOfAdministration) return;
        const correctRouteOfAdministration: QuestionWithAnswer = {
            ...props.correctRouteOfAdministration,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                correctRouteOfAdministration
            })
        );
    };

    /** Updates correct Dosage */
    const updateCorrectDosage = (answer: Answer) => {
        if (!props.correctDosage) return;
        const correctDosage: QuestionWithAnswer = {
            ...props.correctDosage,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                correctDosage
            })
        );
    };

    /** Updates documented Correctly */
    const updateDocumentedCorrectly = (answer: Answer) => {
        if (!props.documentedCorrectly) return;
        const documentedCorrectly: QuestionWithAnswer = {
            ...props.documentedCorrectly,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                documentedCorrectly
            })
        );
    };

    /** Updates correct Clinician Administering */
    const updateCorrectClinicianAdministering = (answer: Answer) => {
        if (!props.correctClinicianAdministering) return;
        const correctClinicianAdministering: QuestionWithAnswer = {
            ...props.correctClinicianAdministering,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                correctClinicianAdministering
            })
        );
    };

    /** Updates patient Outcome Reasonable */
    const updatePatientOutcomeReasonable = (answer: Answer) => {
        const patientOutcomeReasonable: QuestionWithAnswer = {
            ...props.patientOutcomeReasonable,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                patientOutcomeReasonable
            })
        );
    };

    /** Updates destination */
    const updateDestination = (answer: Answer) => {
        const destination: QuestionWithAnswer = {
            ...props.destination,
            answer
        };
        dispatch(
            updateGeneralQuestions(
                updateDestinationQuestionBasedOnAnswer(answer, {...props, destination})
            )
        );
    };

    /** Updates name Of Hospital */
    const updateNameOfHospital = (answer: Answer) => {
        if (!props.nameOfHospital) return;
        const nameOfHospital: QuestionWithAnswer = {
            ...props.nameOfHospital,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                nameOfHospital
            })
        );
    };

    /** Updates handover times */
    const updateHandoverTimes = (answer: Answer) => {
        if (!props.handoverTimes) return;
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    /** Updates call Closed Correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateGeneralQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"General Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaint}
                onChange={updateHistoryOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.observationRecorded}
                onChange={updateObservationRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.leadEcg}
                onChange={updateLeadEcg}
                isOptionalQuestion={false}
            />
            {props.managementCorrect && (
                <QuestionAndAnswer
                    {...props.managementCorrect}
                    onChange={updateManagementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {props.interpretationCorrect && (
                <QuestionAndAnswer
                    {...props.interpretationCorrect}
                    onChange={updateInterpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.clinicAssessmentAppropriate}
                onChange={updateClinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.differentialDiagnosticReasonable}
                onChange={updateDifferentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.medicationGiven}
                onChange={updateMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.appropriateMedicationAdministered && (
                <QuestionAndAnswer
                    {...props.appropriateMedicationAdministered}
                    onChange={updateAppropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctRouteOfAdministration && (
                <QuestionAndAnswer
                    {...props.correctRouteOfAdministration}
                    onChange={updateCorrectRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctDosage && (
                <QuestionAndAnswer
                    {...props.correctDosage}
                    onChange={updateCorrectDosage}
                    isOptionalQuestion={true}
                />
            )}
            {props.documentedCorrectly && (
                <QuestionAndAnswer
                    {...props.documentedCorrectly}
                    onChange={updateDocumentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctClinicianAdministering && (
                <QuestionAndAnswer
                    {...props.correctClinicianAdministering}
                    onChange={updateCorrectClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.patientOutcomeReasonable}
                onChange={updatePatientOutcomeReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.destination}
                onChange={updateDestination}
                isOptionalQuestion={false}
            />
            {props.nameOfHospital && (
                <QuestionAndAnswer
                    {...props.nameOfHospital}
                    onChange={updateNameOfHospital}
                    isOptionalQuestion={true}
                />
            )}
            {props.handoverTimes && (
                <QuestionAndAnswer
                    {...props.handoverTimes}
                    onChange={updateHandoverTimes}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default GeneralQuestions;
