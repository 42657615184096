import React from "react";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import FormHeader from "../../../../../Form/FormHeader";

const ReadOnlyPresentingComplaint = () => {
    const prfAudit = useSelector((state: RootStore) => state.audit.data);
    return (
        <React.Fragment>
            <FormHeader
                headerName={`Presenting Complaint - ${getUiFriendlyText(
                    prfAudit ? prfAudit.auditType : ""
                )}`}
            />
        </React.Fragment>
    );
};

export default ReadOnlyPresentingComplaint;
