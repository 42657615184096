import React, {useEffect, useRef, useState} from "react";
import {Venue} from "../../../api/grs";
import {useDispatch} from "react-redux";
import {DDProps, sortVenuesToDropdownProps, VenueDropdownProps} from "../Helpers/dropdownUtils";
import {
    fetchAllVenues,
    getVenuesForCalendarId,
    nullifyVenueListStore
} from "../../../store/venueList/actions/VenueListActions";
import Select, {SingleValue} from "react-select";

/** Dropdown for Venues */
const VenueDropdown = (props: VenueDropdownProps) => {
    const dispatch = useDispatch();
    const [venues, setVenues] = useState<Venue[]>([]);
    const [venueOptions, setVenueOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);
    const previousCalendarId = useRef(props.calendarId);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        setSelectedOption(undefined);
        props.changeOption(emptyVenue());
        (async function fetchVenues() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const venueList: Venue[] = await getCorrectVenueListRequest();
            setVenues(venueList);
            processVenuesIncoming(venueList);
        })();

        return async function () {
            dispatch(nullifyVenueListStore());
        };
    }, [props.calendarId]);

    /** Will update the component once the venue list has been populated*/
    const processVenuesIncoming = (venueList: Venue[]) => {
        const options = sortVenuesToDropdownProps(venueList);
        setVenueOptions(options);
        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(emptyVenue());
            return;
        }

        const venue = getVenueFromList(+props.initialVenue.id, venueList);
        //If we can't find the venue in the list. We shove the first venue in the list as the default one
        if (!venue) {
            props.changeOption(venueList[0]);
            setSelectedOption({label: venueList[0].name, value: venueList[0].id});
            return;
        }

        setSelectedOption({label: venue.name, value: venue.id});
        props.changeOption(venue);
    };

    /** Gets the correct request for the venue List */
    const getCorrectVenueListRequest = async () => {
        if (props.calendarId) {
            previousCalendarId.current = props.calendarId;
            return dispatch(getVenuesForCalendarId(props.calendarId));
        }

        return dispatch(fetchAllVenues());
    };

    /** Gets Venue from list based on id. */
    const getVenueFromList = (venueId: number, venueList: Venue[]) => {
        const index = venueList.findIndex((el: Venue) => el.id === venueId);
        if (index < 0) return;

        return venues[index];
    };

    /** Triggered when venue in dropdown has been changed. */
    const handleVenueChange = (p: SingleValue<DDProps>) => {
        if (!p) {
            setSelectedOption(undefined);
            props.changeOption(emptyVenue());
            return;
        }
        const venue = getVenueFromList(+p.value, venues);
        if (!venue) {
            setSelectedOption(undefined);
            props.changeOption(emptyVenue());
            return;
        }
        setSelectedOption({label: venue.name, value: venue.id});
        props.changeOption(venue);
    };

    /** Returns empty venue */
    const emptyVenue = (): Venue => {
        return {
            id: 0,
            name: "",
            address: "",
            calendarId: props.calendarId ? props.calendarId : 0,
            postcode: "",
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state: "Active"
        };
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={venueOptions}
                onChange={handleVenueChange}
                value={selectedOption}
                noOptionsMessage={() => "No Venues Found"}
                placeholder="Select Venue"
                isDisabled={false}
                isClearable={props.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default VenueDropdown;
