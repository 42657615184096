import React from "react";
import {getStaffLinkFromUserDataForStaffMember} from "../../../../../../utils/userDataUtils";
import {getYearOrMonthTextForReportHeader} from "../../../Components/Filters/Helpers/filterHelpers";
import {useReportDateView} from "../../../../../Hooks/useReportDateView";
import {YesAnswerMonthlyReportResult} from "../../../../../../api/prf";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import FormHeader from "../../../../../Form/FormHeader";

const OutcomeReportTableHeader = (props: YesAnswerMonthlyReportResult) => {
    const reportDateView = useReportDateView();

    const getStaffNameForHeader = () => {
        if (props.staffId)
            return ` for ${getStaffLinkFromUserDataForStaffMember(props.staffId).staffName} `;
        return "";
    };

    const getDateRangeForHeader = () => {
        return ` for ${getYearOrMonthTextForReportHeader(reportDateView)}`;
    };

    const getAuditTypeForHeader = () => {
        const auditType =
            props.auditTypes.length > 0 ? getUiFriendlyText(props.auditTypes[0]) : "All";

        return ` for ${auditType} audits`;
    };

    const getHeaderText = () => {
        return `Outcomes Report ${getStaffNameForHeader()} ${getAuditTypeForHeader()} ${getDateRangeForHeader()}`;
    };
    return (
        <React.Fragment>
            <FormHeader headerName={getHeaderText()} />
        </React.Fragment>
    );
};

export default OutcomeReportTableHeader;
