import React from "react";
import {ChartData} from "../../../utils/donutChartUtils";
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";
import ReactTooltip from "react-tooltip";

/** Vertical Bar Chart */
const VerticalBarChart = (props: ChartData) => {
    return (
        <React.Fragment>
            <div className="col-sm-3 d-flex justify-content-center">
                <div className="row ml-0 mr-0 bar-chart-wrapper mr-0">
                    {props.percentageDataEntries.map((entry: DataEntry, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="bar-wrapper">
                                    <div className="bar">
                                        <div
                                            data-tip
                                            data-for={props.uids[index]}
                                            className="slideup interactive"
                                            style={{
                                                backgroundColor: `${entry.color}`,
                                                height: `${entry.value}px`
                                            }}
                                        >
                                            <span className="header-font">{entry.value}%</span>
                                        </div>
                                        <ReactTooltip
                                            place="top"
                                            id={props.uids[index]}
                                            type="dark"
                                            effect="solid"
                                            className="fadeIn tooltip-override-lg"
                                        >
                                            <React.Fragment>
                                                <p className="mb-0">{entry.title}</p>
                                                <p className="mb-0">
                                                    {props.rawValueDataEntries[index].value} /{" "}
                                                    {props.totalValue} ({entry.value}%)
                                                </p>
                                            </React.Fragment>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <p className="text-center bar-chart-labels header-font">
                        {props.outerLabel} ({props.totalValue})
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VerticalBarChart;
