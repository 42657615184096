import React from "react";
import {
    Answer,
    CardiacChestPainAudit,
    Conveyance,
    QuestionWithAnswer,
    QuestionWithConveyance
} from "../../../../../../../api/prf";
import {useDispatch} from "react-redux";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import QuestionAndConveyance from "../../Question/QuestionAndConveyance";
import {updateCardiacChestPainQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {
    update12LeadEcgQuestionBasedOnAnswer,
    updateAdditionalChecksRequiredQuestionBasedOnAnswer,
    updateAdditionalMedicationGivenQuestionBasedOnAnswer,
    updateCapacityAssessedQuestionBasedOnAnswer,
    updateStrokeAuditBasedOnConveyance
} from "../../../../../../../store/audit/helpers/cardiacChestPainAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const CardiacChestPainQuestions = (props: CardiacChestPainAudit) => {
    const dispatch = useDispatch();

    /** Updates history Of Presenting Complaint */
    const updateHistoryOfPresentingComplaint = (answer: Answer) => {
        const historyOfPresentingComplaint: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaint,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                historyOfPresentingComplaint
            })
        );
    };

    /** Updates primary Survey */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates consent Obtained */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates capacity Assessed */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates assessment Documented */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates observation Recorded */
    const updateObservationRecorded = (answer: Answer) => {
        const observationRecorded: QuestionWithAnswer = {
            ...props.observationRecorded,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                observationRecorded
            })
        );
    };

    /** Updates Two Pain Score Documented */
    const updateTwoPainScoreDocumented = (answer: Answer) => {
        if (!props.twoPainScoreDocumented) return; //ToDo: Update API as this cannot be null
        const twoPainScoreDocumented: QuestionWithAnswer = {
            ...props.twoPainScoreDocumented,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                twoPainScoreDocumented
            })
        );
    };

    /** Updates lead Ecg */
    const updateLeadEcg = (answer: Answer) => {
        const leadEcg: QuestionWithAnswer = {
            ...props.leadEcg,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions(
                update12LeadEcgQuestionBasedOnAnswer(answer, {...props, leadEcg})
            )
        );
    };

    /** Updates interpretation Correct */
    const updateInterpretationCorrect = (answer: Answer) => {
        if (!props.interpretationCorrect) return;
        const interpretationCorrect: QuestionWithAnswer = {
            ...props.interpretationCorrect,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                interpretationCorrect
            })
        );
    };

    /** Updates management Correct */
    const updateManagementCorrect = (answer: Answer) => {
        if (!props.managementCorrect) return;
        const managementCorrect: QuestionWithAnswer = {
            ...props.managementCorrect,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                managementCorrect
            })
        );
    };

    /** Updates lead Ecg */
    const updateAdditionalChecksRequired = (answer: Answer) => {
        const additionalChecksRequired: QuestionWithAnswer = {
            ...props.additionalChecksRequired,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions(
                updateAdditionalChecksRequiredQuestionBasedOnAnswer(answer, {
                    ...props,
                    additionalChecksRequired
                })
            )
        );
    };

    /** Updates v4r Recorded */
    const updateV4rRecorded = (answer: Answer) => {
        if (!props.v4rRecorded) return;
        const v4rRecorded: QuestionWithAnswer = {
            ...props.v4rRecorded,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                v4rRecorded
            })
        );
    };

    /** Updates posterior Check Recorded */
    const updatePosteriorCheckRecorded = (answer: Answer) => {
        if (!props.posteriorCheckRecorded) return;
        const posteriorCheckRecorded: QuestionWithAnswer = {
            ...props.posteriorCheckRecorded,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                posteriorCheckRecorded
            })
        );
    };

    /** Updates gtn Administered Correctly */
    const updateGtnAdministeredCorrectly = (answer: Answer) => {
        const gtnAdministeredCorrectly: QuestionWithAnswer = {
            ...props.gtnAdministeredCorrectly,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                gtnAdministeredCorrectly
            })
        );
    };

    /** Updates aspirin Administered Correctly */
    const updateAspirinAdministeredCorrectly = (answer: Answer) => {
        const aspirinAdministeredCorrectly: QuestionWithAnswer = {
            ...props.aspirinAdministeredCorrectly,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                aspirinAdministeredCorrectly
            })
        );
    };

    /** Updates appropriate Pain Relief Given Correctly */
    const updateAppropriatePainReliefGivenCorrectly = (answer: Answer) => {
        const appropriatePainReliefGivenCorrectly: QuestionWithAnswer = {
            ...props.appropriatePainReliefGivenCorrectly,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                appropriatePainReliefGivenCorrectly
            })
        );
    };

    /** Updates additional Medication Given */
    const updateAdditionalMedicationGiven = (answer: Answer) => {
        const additionalMedicationGiven: QuestionWithAnswer = {
            ...props.additionalMedicationGiven,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions(
                updateAdditionalMedicationGivenQuestionBasedOnAnswer(answer, {
                    ...props,
                    additionalMedicationGiven
                })
            )
        );
    };

    /** Updates appropriate Medication Administered */
    const updateAppropriateMedicationAdministered = (answer: Answer) => {
        if (!props.appropriateMedicationAdministered) return;
        const appropriateMedicationAdministered: QuestionWithAnswer = {
            ...props.appropriateMedicationAdministered,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                appropriateMedicationAdministered
            })
        );
    };

    /** Updates correct Route Of Administration */
    const updateCorrectRouteOfAdministration = (answer: Answer) => {
        if (!props.correctRouteOfAdministration) return;
        const correctRouteOfAdministration: QuestionWithAnswer = {
            ...props.correctRouteOfAdministration,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                correctRouteOfAdministration
            })
        );
    };

    /** Updates correct Dosage */
    const updateCorrectDosage = (answer: Answer) => {
        if (!props.correctDosage) return;
        const correctDosage: QuestionWithAnswer = {
            ...props.correctDosage,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                correctDosage
            })
        );
    };

    /** Updates documented Correctly */
    const updateDocumentedCorrectly = (answer: Answer) => {
        if (!props.documentedCorrectly) return;
        const documentedCorrectly: QuestionWithAnswer = {
            ...props.documentedCorrectly,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                documentedCorrectly
            })
        );
    };

    /** Updates correct Clinician Administering */
    const updateCorrectClinicianAdministering = (answer: Answer) => {
        if (!props.correctClinicianAdministering) return;
        const correctClinicianAdministering: QuestionWithAnswer = {
            ...props.correctClinicianAdministering,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                correctClinicianAdministering
            })
        );
    };

    /** Updates clinic Assessment Appropriate */
    const updateClinicAssessmentAppropriate = (answer: Answer) => {
        const clinicAssessmentAppropriate: QuestionWithAnswer = {
            ...props.clinicAssessmentAppropriate,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                clinicAssessmentAppropriate
            })
        );
    };

    /** Updates differential Diagnostic Reasonable */
    const updateDifferentialDiagnosticReasonable = (answer: Answer) => {
        const differentialDiagnosticReasonable: QuestionWithAnswer = {
            ...props.differentialDiagnosticReasonable,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                differentialDiagnosticReasonable
            })
        );
    };

    /** Updates destination correctly */
    const updateDestination = (conveyance: Conveyance) => {
        const destination: QuestionWithConveyance = {
            ...props.destination,
            conveyance
        };
        dispatch(
            updateCardiacChestPainQuestions(
                updateStrokeAuditBasedOnConveyance(conveyance, {...props, destination})
            )
        );
    };

    /** Updates Appropriate Destination */
    const updateAppropriateDestination = (answer: Answer) => {
        if (!props.appropriateDestination) return;
        const appropriateDestination: QuestionWithAnswer = {
            ...props.appropriateDestination,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                appropriateDestination
            })
        );
    };

    /** Updates Handover times correctly */
    const updateHandoverTimes = (answer: Answer) => {
        if (!props.handoverTimes) return;
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    /** Updates Patient Outcome correctly */
    const updatePatientOutcomeReasonable = (answer: Answer) => {
        if (!props.patientOutcomeReasonable) return;
        const patientOutcomeReasonable: QuestionWithAnswer = {
            ...props.patientOutcomeReasonable,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                patientOutcomeReasonable
            })
        );
    };

    /** Updates conveyance Refused correctly */
    const updateConveyanceRefused = (answer: Answer) => {
        if (!props.conveyanceRefused) return;
        const conveyanceRefused: QuestionWithAnswer = {
            ...props.conveyanceRefused,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                conveyanceRefused
            })
        );
    };

    /** Updates Pathfinder Outcome correctly */
    const updatePathfinderOutcome = (answer: Answer) => {
        if (!props.pathfinderOutcome) return;
        const pathfinderOutcome: QuestionWithAnswer = {
            ...props.pathfinderOutcome,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                pathfinderOutcome
            })
        );
    };

    /** Updates CAL Contacted correctly */
    const updateCalContacted = (answer: Answer) => {
        if (!props.calContacted) return;
        const calContacted: QuestionWithAnswer = {
            ...props.calContacted,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                calContacted
            })
        );
    };

    /** Updates News2 Score Recorded correctly */
    const updateNews2ScoreRecorded = (answer: Answer) => {
        if (!props.news2ScoreRecorded) return;
        const news2ScoreRecorded: QuestionWithAnswer = {
            ...props.news2ScoreRecorded,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                news2ScoreRecorded
            })
        );
    };

    /** Updates Worsening Care Advice Documented */
    const updateWorseningCareAdviceGiven = (answer: Answer) => {
        if (!props.worseningCareAdviceGiven) return;
        const worseningCareAdviceGiven: QuestionWithAnswer = {
            ...props.worseningCareAdviceGiven,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                worseningCareAdviceGiven
            })
        );
    };

    /** Updates Call closed correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateCardiacChestPainQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Cardiac Chest Pain Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaint}
                onChange={updateHistoryOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.observationRecorded}
                onChange={updateObservationRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.twoPainScoreDocumented}
                onChange={updateTwoPainScoreDocumented}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.leadEcg}
                onChange={updateLeadEcg}
                isOptionalQuestion={false}
            />
            {props.managementCorrect && (
                <QuestionAndAnswer
                    {...props.managementCorrect}
                    onChange={updateManagementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {props.interpretationCorrect && (
                <QuestionAndAnswer
                    {...props.interpretationCorrect}
                    onChange={updateInterpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.additionalChecksRequired}
                onChange={updateAdditionalChecksRequired}
                isOptionalQuestion={false}
            />
            {props.v4rRecorded && (
                <QuestionAndAnswer
                    {...props.v4rRecorded}
                    onChange={updateV4rRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.posteriorCheckRecorded && (
                <QuestionAndAnswer
                    {...props.posteriorCheckRecorded}
                    onChange={updatePosteriorCheckRecorded}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.gtnAdministeredCorrectly}
                onChange={updateGtnAdministeredCorrectly}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.aspirinAdministeredCorrectly}
                onChange={updateAspirinAdministeredCorrectly}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.appropriatePainReliefGivenCorrectly}
                onChange={updateAppropriatePainReliefGivenCorrectly}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.additionalMedicationGiven}
                onChange={updateAdditionalMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.appropriateMedicationAdministered && (
                <QuestionAndAnswer
                    {...props.appropriateMedicationAdministered}
                    onChange={updateAppropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctRouteOfAdministration && (
                <QuestionAndAnswer
                    {...props.correctRouteOfAdministration}
                    onChange={updateCorrectRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctDosage && (
                <QuestionAndAnswer
                    {...props.correctDosage}
                    onChange={updateCorrectDosage}
                    isOptionalQuestion={true}
                />
            )}
            {props.documentedCorrectly && (
                <QuestionAndAnswer
                    {...props.documentedCorrectly}
                    onChange={updateDocumentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctClinicianAdministering && (
                <QuestionAndAnswer
                    {...props.correctClinicianAdministering}
                    onChange={updateCorrectClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.clinicAssessmentAppropriate}
                onChange={updateClinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.differentialDiagnosticReasonable}
                onChange={updateDifferentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndConveyance
                dropdownOptions={CardiacChestPainDestinations}
                {...props.destination}
                onChange={updateDestination}
            />
            {props.appropriateDestination && (
                <QuestionAndAnswer
                    {...props.appropriateDestination}
                    onChange={updateAppropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {props.handoverTimes && (
                <QuestionAndAnswer
                    {...props.handoverTimes}
                    onChange={updateHandoverTimes}
                    isOptionalQuestion={true}
                />
            )}
            {props.patientOutcomeReasonable && (
                <QuestionAndAnswer
                    {...props.patientOutcomeReasonable}
                    onChange={updatePatientOutcomeReasonable}
                    isOptionalQuestion={true}
                />
            )}
            {props.conveyanceRefused && (
                <QuestionAndAnswer
                    {...props.conveyanceRefused}
                    onChange={updateConveyanceRefused}
                    isOptionalQuestion={true}
                />
            )}
            {props.pathfinderOutcome && (
                <QuestionAndAnswer
                    {...props.pathfinderOutcome}
                    onChange={updatePathfinderOutcome}
                    isOptionalQuestion={true}
                />
            )}
            {/** CAL contacted */}
            {props.calContacted && (
                <QuestionAndAnswer
                    {...props.calContacted}
                    onChange={updateCalContacted}
                    isOptionalQuestion={true}
                />
            )}
            {/** NEWS2 score recorded */}
            {props.news2ScoreRecorded && (
                <QuestionAndAnswer
                    {...props.news2ScoreRecorded}
                    onChange={updateNews2ScoreRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {/** Worsening care advice documented */}
            {props.worseningCareAdviceGiven && (
                <QuestionAndAnswer
                    {...props.worseningCareAdviceGiven}
                    onChange={updateWorseningCareAdviceGiven}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default CardiacChestPainQuestions;

// eslint-disable-next-line no-shadow
enum CardiacChestPainDestinations {
    Conveyed = "Conveyed",
    NotConveyed = "Not Conveyed",
    Refused = "Refused",
    Referred = "Referred",
    NotApplicable = "Not Applicable"
}
