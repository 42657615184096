import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AUDIT_TYPE_STATS_LIST_STORE} from "../actions/AuditTypeStatsListActionTypes";
import {ChartData} from "../../../../utils/donutChartUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ChartData[]>([]);

/** Infer type of the reducer for code completion and quality */
const auditTypeStatsListReducer = (
    state: StoreServiceData<ChartData[]> = defaultState,
    action: ServiceActionTypes<ChartData[]>
): StoreServiceData<ChartData[]> =>
    createReducer(state, action, AUDIT_TYPE_STATS_LIST_STORE, () => showErrorToast(action.error));

export default auditTypeStatsListReducer;
