import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {routeNames} from "../../../Navigation/routeNames";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";
import {McConfig} from "../../../../McConfigPlugin";

/** Gets nav items for each access level for navItems */
export function getNavItemsForAuditTrailLogsRoute(
    user: UserData,
    config: McConfig
): NavigationItem[] {
    const systemAccessLevel = getUserAccessLevel(user, config);
    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                }
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.overview.name,
                    path: routeNames.overview.path
                },
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                },
                {
                    name: routeNames.reports.name,
                    path: routeNames.reports.path
                }
            ];
    }
}
