import PrfApiModel from "../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {AUDIT_TYPE_STATS_STORE, AuditTypeStatsDispatchTypes} from "./AuditTypeStatsActionTypes";
import {AuditType, AuditTypeStats, AuditTypeStatsRequest} from "../../../../api/prf";
import {getDataFromServiceWithData, getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyAuditTypeStatsStore = () => {
    return async (dispatch: Dispatch<AuditTypeStatsDispatchTypes>) => {
        dispatch({
            type: AUDIT_TYPE_STATS_STORE.SUCCESS,
            data: null,
            loading: false,
            error: null
        });
    };
};

/** Gets all the stats for the audit type stats */
export const getAuditTypeStats = (auditType: AuditType, request: AuditTypeStatsRequest) => {
    return async (dispatch: Dispatch<AuditTypeStatsDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AUDIT_TYPE_STATS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAuditTypeStats(auditType, request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_TYPE_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets all the stats for the audit type stats */
export const getAllAuditTypeStats = (request: AuditTypeStatsRequest) => {
    return async (dispatch: Dispatch<AuditTypeStatsDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                AUDIT_TYPE_STATS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAllAuditTypeStats(request),
                statusCodeCallback
            );
            const combinedStats = addAllStatsValuesFromArray(stats);

            dispatch({
                type: AUDIT_TYPE_STATS_STORE.SUCCESS,
                error: null,
                loading: false,
                data: combinedStats
            });
            return combinedStats;
        } catch (e: any) {
            dispatch({
                type: AUDIT_TYPE_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Adds all the stats up and puts them into one object */
function addAllStatsValuesFromArray(stats: AuditTypeStats[] | undefined | null): AuditTypeStats {
    const statBase: AuditTypeStats = getBlankBaseStatsObject();

    if (!stats) return statBase;

    for (const stat of stats) {
        statBase.totalNumberOfAudits += stat.totalNumberOfAudits;
        statBase.numberOfAuditsBelow90PercentCompliant +=
            stat.numberOfAuditsBelow90PercentCompliant;
        statBase.numberOfAudits90to99PercentCompliant += stat.numberOfAudits90to99PercentCompliant;
        statBase.numberOfAudits100PercentCompliant += stat.numberOfAudits100PercentCompliant;
    }

    return {
        ...statBase,
        auditType: AuditType.All
    };
}

/** Creates blank AuditTypeStats object */
function getBlankBaseStatsObject(): AuditTypeStats {
    return {
        auditType: AuditType.None,
        totalNumberOfAudits: 0,
        numberOfAuditsBelow90PercentCompliant: 0,
        numberOfAudits90to99PercentCompliant: 0,
        numberOfAudits100PercentCompliant: 0
    };
}
