import React from "react";
import {
    Answer,
    Conveyance,
    QuestionWithAnswer,
    QuestionWithConveyance,
    StrokeAudit
} from "../../../../../../../api/prf";
import {useDispatch} from "react-redux";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import QuestionAndConveyance from "../../Question/QuestionAndConveyance";
import {updateStrokeQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {
    update12LeadEcgQuestionBasedOnAnswer,
    updateCapacityAssessedQuestionBasedOnAnswer,
    updateMedicationGivenQuestionBasedOnAnswer,
    updateStrokeAuditBasedOnConveyance
} from "../../../../../../../store/audit/helpers/strokeAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const StrokeQuestions = (props: StrokeAudit) => {
    const dispatch = useDispatch();
    /** Updates history Of Presenting Complaint */
    const updateHistoryOfPresentingComplaint = (answer: Answer) => {
        const historyOfPresentingComplaint: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaint,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                historyOfPresentingComplaint
            })
        );
    };

    /** Updates primary Survey */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates consent Obtained */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates capacity Assessed */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };
        dispatch(
            updateStrokeQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates assessment Documented */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates observation Recorded */
    const updateObservationRecorded = (answer: Answer) => {
        const observationRecorded: QuestionWithAnswer = {
            ...props.observationRecorded,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                observationRecorded
            })
        );
    };

    /** Updates lead Ecg */
    const updateLeadEcg = (answer: Answer) => {
        const leadEcg: QuestionWithAnswer = {
            ...props.leadEcg,
            answer
        };
        dispatch(
            updateStrokeQuestions(update12LeadEcgQuestionBasedOnAnswer(answer, {...props, leadEcg}))
        );
    };

    /** Updates interpretation Correct */
    const updateInterpretationCorrect = (answer: Answer) => {
        if (!props.interpretationCorrect) return;
        const interpretationCorrect: QuestionWithAnswer = {
            ...props.interpretationCorrect,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                interpretationCorrect
            })
        );
    };

    /** Updates management Correct */
    const updateManagementCorrect = (answer: Answer) => {
        if (!props.managementCorrect) return;
        const managementCorrect: QuestionWithAnswer = {
            ...props.managementCorrect,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                managementCorrect
            })
        );
    };

    /** Updates fast Recorded Correctly */
    const updateFastRecordedCorrectly = (answer: Answer) => {
        const fastRecordedCorrectly: QuestionWithAnswer = {
            ...props.fastRecordedCorrectly,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                fastRecordedCorrectly
            })
        );
    };

    /** Updates bm Recorded */
    const updateBmRecorded = (answer: Answer) => {
        const bmRecorded: QuestionWithAnswer = {
            ...props.bmRecorded,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                bmRecorded
            })
        );
    };

    /** Updates bp Recorded */
    const updateBpRecorded = (answer: Answer) => {
        const bpRecorded: QuestionWithAnswer = {
            ...props.bpRecorded,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                bpRecorded
            })
        );
    };

    /** Updates clinic Assessment Appropriate */
    const updateClinicAssessmentAppropriate = (answer: Answer) => {
        const clinicAssessmentAppropriate: QuestionWithAnswer = {
            ...props.clinicAssessmentAppropriate,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                clinicAssessmentAppropriate
            })
        );
    };

    /** Updates differential Diagnostic Reasonable */
    const updateDifferentialDiagnosticReasonable = (answer: Answer) => {
        const differentialDiagnosticReasonable: QuestionWithAnswer = {
            ...props.differentialDiagnosticReasonable,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                differentialDiagnosticReasonable
            })
        );
    };

    /** Updates medication Given */
    const updateMedicationGiven = (answer: Answer) => {
        const medicationGiven: QuestionWithAnswer = {
            ...props.medicationGiven,
            answer
        };
        dispatch(
            updateStrokeQuestions(
                updateMedicationGivenQuestionBasedOnAnswer(answer, {...props, medicationGiven})
            )
        );
    };

    /** Updates appropriate Medication Administered */
    const updateAppropriateMedicationAdministered = (answer: Answer) => {
        if (!props.appropriateMedicationAdministered) return;
        const appropriateMedicationAdministered: QuestionWithAnswer = {
            ...props.appropriateMedicationAdministered,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                appropriateMedicationAdministered
            })
        );
    };

    /** Updates correct Route Of Administration */
    const updateCorrectRouteOfAdministration = (answer: Answer) => {
        if (!props.correctRouteOfAdministration) return;
        const correctRouteOfAdministration: QuestionWithAnswer = {
            ...props.correctRouteOfAdministration,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                correctRouteOfAdministration
            })
        );
    };

    /** Updates correct Dosage */
    const updateCorrectDosage = (answer: Answer) => {
        if (!props.correctDosage) return;
        const correctDosage: QuestionWithAnswer = {
            ...props.correctDosage,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                correctDosage
            })
        );
    };

    /** Updates documented Correctly */
    const updateDocumentedCorrectly = (answer: Answer) => {
        if (!props.documentedCorrectly) return;
        const documentedCorrectly: QuestionWithAnswer = {
            ...props.documentedCorrectly,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                documentedCorrectly
            })
        );
    };

    /** Updates correct Clinician Administering */
    const updateCorrectClinicianAdministering = (answer: Answer) => {
        if (!props.correctClinicianAdministering) return;
        const correctClinicianAdministering: QuestionWithAnswer = {
            ...props.correctClinicianAdministering,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                correctClinicianAdministering
            })
        );
    };

    /** Updates destination correctly */
    const updateDestination = (conveyance: Conveyance) => {
        const destination: QuestionWithConveyance = {
            ...props.destination,
            conveyance
        };
        dispatch(
            updateStrokeQuestions(
                updateStrokeAuditBasedOnConveyance(conveyance, {
                    ...props,
                    destination
                })
            )
        );
    };

    /** Updates Hasu */
    const updateHasu = (answer: Answer) => {
        if (!props.hasu) return;
        const hasu: QuestionWithAnswer = {
            ...props.hasu,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                hasu
            })
        );
    };

    /** Updates Stroke 60 Achieved */
    const updateStroke60Achieved = (answer: Answer) => {
        if (!props.stoke60Achieved) return;
        const stoke60Achieved: QuestionWithAnswer = {
            ...props.stoke60Achieved,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                stoke60Achieved
            })
        );
    };

    /** Updates Handover times correctly */
    const updateHandoverTimes = (answer: Answer) => {
        if (!props.handoverTimes) return;
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    /** Updates Patient Outcome correctly */
    const updatePatientOutcomeReasonable = (answer: Answer) => {
        if (!props.patientOutcomeReasonable) return;
        const patientOutcomeReasonable: QuestionWithAnswer = {
            ...props.patientOutcomeReasonable,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                patientOutcomeReasonable
            })
        );
    };

    /** Updates Pathfinder Outcome correctly */
    const updatePathfinderOutcome = (answer: Answer) => {
        if (!props.pathfinderOutcome) return;
        const pathfinderOutcome: QuestionWithAnswer = {
            ...props.pathfinderOutcome,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                pathfinderOutcome
            })
        );
    };

    /** Updates CAL Contacted correctly */
    const updateCalContacted = (answer: Answer) => {
        if (!props.calContacted) return;
        const calContacted: QuestionWithAnswer = {
            ...props.calContacted,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                calContacted
            })
        );
    };

    /** Updates News2 Score Recorded correctly */
    const updateNews2ScoreRecorded = (answer: Answer) => {
        if (!props.news2ScoreRecorded) return;
        const news2ScoreRecorded: QuestionWithAnswer = {
            ...props.news2ScoreRecorded,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                news2ScoreRecorded
            })
        );
    };

    /** Updates Worsening Care Advice Documented */
    const updateWorseningCareAdviceGiven = (answer: Answer) => {
        if (!props.worseningCareAdviceGiven) return;
        const worseningCareAdviceGiven: QuestionWithAnswer = {
            ...props.worseningCareAdviceGiven,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                worseningCareAdviceGiven
            })
        );
    };

    /** Updates Call closed correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateStrokeQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Stroke Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaint}
                onChange={updateHistoryOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.observationRecorded}
                onChange={updateObservationRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.leadEcg}
                onChange={updateLeadEcg}
                isOptionalQuestion={false}
            />
            {props.managementCorrect && (
                <QuestionAndAnswer
                    {...props.managementCorrect}
                    onChange={updateManagementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {props.interpretationCorrect && (
                <QuestionAndAnswer
                    {...props.interpretationCorrect}
                    onChange={updateInterpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.fastRecordedCorrectly}
                onChange={updateFastRecordedCorrectly}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.bmRecorded}
                onChange={updateBmRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.bpRecorded}
                onChange={updateBpRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.clinicAssessmentAppropriate}
                onChange={updateClinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.differentialDiagnosticReasonable}
                onChange={updateDifferentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.medicationGiven}
                onChange={updateMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.appropriateMedicationAdministered && (
                <QuestionAndAnswer
                    {...props.appropriateMedicationAdministered}
                    onChange={updateAppropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctRouteOfAdministration && (
                <QuestionAndAnswer
                    {...props.correctRouteOfAdministration}
                    onChange={updateCorrectRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctDosage && (
                <QuestionAndAnswer
                    {...props.correctDosage}
                    onChange={updateCorrectDosage}
                    isOptionalQuestion={true}
                />
            )}
            {props.documentedCorrectly && (
                <QuestionAndAnswer
                    {...props.documentedCorrectly}
                    onChange={updateDocumentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctClinicianAdministering && (
                <QuestionAndAnswer
                    {...props.correctClinicianAdministering}
                    onChange={updateCorrectClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndConveyance
                dropdownOptions={StrokeDestinations}
                {...props.destination}
                onChange={updateDestination}
            />
            {props.hasu && (
                <QuestionAndAnswer
                    {...props.hasu}
                    onChange={updateHasu}
                    isOptionalQuestion={true}
                />
            )}
            {props.stoke60Achieved && (
                <QuestionAndAnswer
                    {...props.stoke60Achieved}
                    onChange={updateStroke60Achieved}
                    isOptionalQuestion={true}
                />
            )}
            {props.handoverTimes && (
                <QuestionAndAnswer
                    {...props.handoverTimes}
                    onChange={updateHandoverTimes}
                    isOptionalQuestion={true}
                />
            )}
            {props.patientOutcomeReasonable && (
                <QuestionAndAnswer
                    {...props.patientOutcomeReasonable}
                    onChange={updatePatientOutcomeReasonable}
                    isOptionalQuestion={true}
                />
            )}
            {props.pathfinderOutcome && (
                <QuestionAndAnswer
                    {...props.pathfinderOutcome}
                    onChange={updatePathfinderOutcome}
                    isOptionalQuestion={true}
                />
            )}
            {props.calContacted && (
                <QuestionAndAnswer
                    {...props.calContacted}
                    onChange={updateCalContacted}
                    isOptionalQuestion={true}
                />
            )}
            {props.news2ScoreRecorded && (
                <QuestionAndAnswer
                    {...props.news2ScoreRecorded}
                    onChange={updateNews2ScoreRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.worseningCareAdviceGiven && (
                <QuestionAndAnswer
                    {...props.worseningCareAdviceGiven}
                    onChange={updateWorseningCareAdviceGiven}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default StrokeQuestions;

// eslint-disable-next-line no-shadow
enum StrokeDestinations {
    Conveyed = "conveyed",
    NotConveyed = "notConveyed",
    Refused = "refused",
    Referred = "referred",
    NotApplicable = "notApplicable"
}
