import React from "react";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {Answer} from "../../../../../../api/prf";
import FormRowQuestion from "../../../../../Form/FormRowQuestion";
import {IconType} from "../../../../../Icon/Icon";

const ReadOnlyQuestionAndAnswer = (props: any) => (
    <React.Fragment>
        <FormRowQuestion
            rowName={props.question}
            columnDetailClassName={"pl-0 pr-0"}
            isOptionalQuestion={props.isOptionalQuestion}
            showIcon={props.answer && props.questionType && props.answer === Answer.No}
            iconType={IconType.Error}
            iconClassName="error-text"
        >
            <p
                className={`mt-2 mb-0 readonly-question ${getClassNameForWrongAnswer(
                    props.answer
                )}`}
            >
                {props.conveyance && <span>{getUiFriendlyText(props.conveyance)}</span>}
                {props.answer && <span>{getUiFriendlyText(props.answer)}</span>}
            </p>
        </FormRowQuestion>
        {props.comments && (
            <React.Fragment>
                <FormRowQuestion
                    rowName={"Comments"}
                    columnDetailClassName={"pl-0 pr-0"}
                    isOptionalQuestion={props.isOptionalQuestion}
                >
                    <p style={{minHeight: `150px`}} className="mt-2 mb-0 readonly-question">
                        {props.comments}
                    </p>
                </FormRowQuestion>
            </React.Fragment>
        )}
    </React.Fragment>
);

export default ReadOnlyQuestionAndAnswer;

function getClassNameForWrongAnswer(answer: Answer | undefined) {
    if (!answer) return "";

    switch (answer) {
        case Answer.No:
            return "actioned-question";
        default:
            return "";
    }
}
