import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {NUMBER_OF_COMPLETED_AUDITS_STORE} from "../actions/NumberOfCompletedAuditsActionTypes";
import {ChartData} from "../../../../utils/donutChartUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ChartData>(null);

/** Infer type of the reducer for code completion and quality */
const numberOfCompletedAuditsReducer = (
    state: StoreServiceData<ChartData> = defaultState,
    action: ServiceActionTypes<ChartData>
): StoreServiceData<ChartData> =>
    createReducer(state, action, NUMBER_OF_COMPLETED_AUDITS_STORE, () =>
        showErrorToast(action.error)
    );

export default numberOfCompletedAuditsReducer;
