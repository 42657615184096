import {YesAnswerMonthlyAuditTypeResult} from "../../../../../../api/prf";
import {getMonthNumber, ReportDateView} from "../../../Components/Filters/Helpers/filterHelpers";

/** Will get the results for the month being looked at. */
export function getFilteredResultsForMonthView(
    results: YesAnswerMonthlyAuditTypeResult,
    reportDateView: ReportDateView
): FilteredQualityReportResult[] {
    const monthNumber = getMonthNumber(reportDateView);
    const filteredResults: FilteredQualityReportResult[] = [];
    if (monthNumber === -1) return filteredResults;

    for (const result of results.questions) {
        filteredResults.push({
            question: result.question,
            value: result.monthCounts[monthNumber]
        });
    }

    return filteredResults;
}

export interface FilteredQualityReportResult {
    question: string;
    value: number;
}
