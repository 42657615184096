import React from "react";
import ReadOnlyQuestionAndAnswer from "../Question/ReadOnlyQuestionAndAnswer";
import {BaseAudit} from "../../../../../../api/prf";
import FormHeader from "../../../../../Form/FormHeader";

const ReadOnlyBaseQuestions = ({questions, headerText}: ReadOnlyQuestions<BaseAudit>) => {
    return (
        <React.Fragment>
            <FormHeader headerName={headerText} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.locationOfCall} />
            <ReadOnlyQuestionAndAnswer
                isOptionalQuestion={false}
                {...questions.activationAndResponseTimes}
            />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.crewDetails} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.patientsName} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.dob} />
            <ReadOnlyQuestionAndAnswer
                isOptionalQuestion={false}
                {...questions.patientsHomeAddress}
            />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.gp} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.nextOfKin} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.ethnicity} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.nhsNumber} />
            <ReadOnlyQuestionAndAnswer
                isOptionalQuestion={false}
                {...questions.paediatricPatient}
            />
            {questions.nameOfSchool && (
                <ReadOnlyQuestionAndAnswer isOptionalQuestion={true} {...questions.nameOfSchool} />
            )}
            {questions.accompanyingAdult && (
                <ReadOnlyQuestionAndAnswer
                    isOptionalQuestion={true}
                    {...questions.accompanyingAdult}
                />
            )}
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.medicalHistory} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.drugHistory} />
            <ReadOnlyQuestionAndAnswer isOptionalQuestion={false} {...questions.allergies} />
        </React.Fragment>
    );
};
export default ReadOnlyBaseQuestions;

export interface ReadOnlyQuestions<T> {
    questions: T;
    headerText: string;
}
