import React from "react";
import {AuditForm} from "../../../../../../api/prf";
import ReadOnlyBaseQuestions from "./ReadOnlyBaseQuestions";
import ReadOnlyPresentingComplaint from "./ReadOnlyPresentingComplaint";
import ReadOnlyGeneralAudit from "./Audits/ReadOnlyGeneralAudit";
import ReadOnlyStrokeAudit from "./Audits/ReadOnlyStrokeAudit";
import ReadOnlyNotConveyedAudit from "./Audits/ReadOnlyNotConveyedAudit";
import ReadOnlyCardiacChestPainAudit from "./Audits/ReadOnlyCardiacChestPainAudit";
import ReadOnlySepsisAudit from "./Audits/ReadOnlySepsisAudit";
import ReadOnlyCardiacArrestAndRoscAudit from "./Audits/ReadOnlyCardiacArrestAndRoscAudit";
import ReadOnlyRecognitionOfLifeExtinctAudit from "./Audits/ReadOnlyRecognitionOfLifeExtinctAudit";
import ReadOnlyMentalHealthAndOrRestraintAudit from "./Audits/ReadOnlyMentalHealthAndOrRestraintAudit";
import ReadOnlyOutcome from "./ReadOnlyOutcome";
import ReadOnlyOutcomeData from "./ReadOnlyOutcomeData";
import ReadOnlyAuditComments from "./ReadOnlyAuditComments";

const ReviewAuditForm = (props: AuditForm) => {
    return (
        <React.Fragment>
            <div className="section-borders">
                <ReadOnlyBaseQuestions
                    questions={props.baseQuestions}
                    headerText="Essential Details"
                />
                <ReadOnlyPresentingComplaint />
                {props.generalAudit && (
                    <ReadOnlyGeneralAudit
                        questions={props.generalAudit}
                        headerText="General Audit"
                    />
                )}
                {props.notConveyedAudit && (
                    <ReadOnlyNotConveyedAudit
                        questions={props.notConveyedAudit}
                        headerText="Not Conveyed Audit"
                    />
                )}
                {props.cardiacChestPainAudit && (
                    <ReadOnlyCardiacChestPainAudit
                        questions={props.cardiacChestPainAudit}
                        headerText="Cardiac Chest Pain Audit"
                    />
                )}
                {props.sepsisAudit && (
                    <ReadOnlySepsisAudit questions={props.sepsisAudit} headerText="Sepsis Audit" />
                )}
                {props.strokeAudit && (
                    <ReadOnlyStrokeAudit questions={props.strokeAudit} headerText="Stroke Audit" />
                )}
                {props.cardiacArrestAndROSCAudit && (
                    <ReadOnlyCardiacArrestAndRoscAudit
                        questions={props.cardiacArrestAndROSCAudit}
                        headerText="Cardiac Arrest and Rosc Audit"
                    />
                )}
                {props.recognitionOfLifeExtinctAudit && (
                    <ReadOnlyRecognitionOfLifeExtinctAudit
                        questions={props.recognitionOfLifeExtinctAudit}
                        headerText="Recognition Of Life Extinct Audit"
                    />
                )}
                {props.mentalHealthAndRestraintAudit && (
                    <ReadOnlyMentalHealthAndOrRestraintAudit
                        questions={props.mentalHealthAndRestraintAudit}
                        headerText="Mental Health And Or Restraint Audit"
                    />
                )}
                <ReadOnlyOutcome {...props} />
                <ReadOnlyOutcomeData {...props} />
                <ReadOnlyAuditComments {...props} />
            </div>
        </React.Fragment>
    );
};

export default ReviewAuditForm;
