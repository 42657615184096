import React from "react";
import {AuditType} from "../../../../../../api/prf";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {useDispatch, useSelector} from "react-redux";
import {setAuditType} from "../../../../../../store/audit/actions/AuditActions";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import {RootStore} from "../../../../../../store/Store";
import {getAuditTypeFromString} from "../../../../Reports/Components/Filters/Components/ReportFilters";
import FormHeader from "../../../../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";

const PresentingComplaint = () => {
    const prfAudit = useSelector((state: RootStore) => state.audit.data);
    const dispatch = useDispatch();
    const isHistoric = useIsHistoricAudit();

    /** Will select the audit and will be used to generate all the selected report*/
    const onAuditSelected = (auditType: string) => {
        if (!prfAudit) return;
        const selectedAudit = getAuditTypeFromString(auditType);

        // If the user clicks the same audit when they have already completed the audit, this will prevent them from deleting all their progress
        if (selectedAudit === prfAudit.auditType) return;
        dispatch(setAuditType(selectedAudit));
    };

    /** Will hide the Audit Options that are None and All */
    const isAuditOptionNotAllOrNone = (audit: string) => {
        const correctEnum = getAuditTypeFromString(audit);
        switch (correctEnum) {
            case AuditType.None:
            case AuditType.All:
                return false;
            case AuditType.General:
            case AuditType.NotConveyed:
            case AuditType.CardiacChestPain:
            case AuditType.Sepsis:
            case AuditType.Stroke:
            case AuditType.CardiacArrestAndRosc:
            case AuditType.RecognitionOfLifeExtinct:
            case AuditType.MentalHealthAndOrRestraint:
                return true;
        }
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Presenting Complaint"} />
            <div className="row ml-0 mr-0 mt-4">
                {Object.keys(AuditType).map((audit: string, index: number) => {
                    if (isAuditOptionNotAllOrNone(audit)) {
                        return (
                            <React.Fragment key={index}>
                                <div className="col-sm-3 mb-3 presenting-complaint-button">
                                    <MCButton
                                        disabled={isHistoric}
                                        size={ButtonSize.ExtraLarge}
                                        innerValue={getUiFriendlyText(audit)}
                                        onClick={() => onAuditSelected(audit)}
                                        colour={
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            prfAudit && prfAudit.auditType === AuditType[audit]
                                                ? ButtonColourOptions.Yellow
                                                : ButtonColourOptions.DarkBlue
                                        }
                                        className={"w-100"}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }
                })}
            </div>
        </React.Fragment>
    );
};

export default PresentingComplaint;
