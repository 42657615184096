import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {FailQueryResponse} from "../../../../api/prf";
import {FAILED_QUESTIONS_STORE} from "../actions/FailedQuestionsReportActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";
/** Default state of the store. */
const defaultState: StoreServiceData<FailQueryResponse> =
    createDefaultStoreState<FailQueryResponse>(null);

/** Infer type of the reducer for code completion and quality */
const failedQuestionsReportReducer = (
    state: StoreServiceData<FailQueryResponse> = defaultState,
    action: ServiceActionTypes<FailQueryResponse>
): StoreServiceData<FailQueryResponse> =>
    createReducer(state, action, FAILED_QUESTIONS_STORE, () => showErrorToast(action.error));

export default failedQuestionsReportReducer;
