import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {PrfAudit} from "../../../api/prf";
import {AUDIT_STORE} from "../actions/AuditActionTypes";

import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState: StoreServiceData<PrfAudit> = createDefaultStoreState<PrfAudit>(null);

/** Infer type of the reducer for code completion and quality */
const auditReducer = (
    state: StoreServiceData<PrfAudit> = defaultState,
    action: ServiceActionTypes<PrfAudit>
): StoreServiceData<PrfAudit> =>
    createReducer(state, action, AUDIT_STORE, () => showErrorToast(action.error));

export default auditReducer;
