import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {OUTCOME_REPORT_STORE} from "../actions/OutcomeReportActionTypes";
import {YesAnswerMonthlyReportResult} from "../../../../api/prf";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<YesAnswerMonthlyReportResult>(null);

/** Infer type of the reducer for code completion and quality */
const outcomeReportReducer = (
    state: StoreServiceData<YesAnswerMonthlyReportResult> = defaultState,
    action: ServiceActionTypes<YesAnswerMonthlyReportResult>
): StoreServiceData<YesAnswerMonthlyReportResult> =>
    createReducer(state, action, OUTCOME_REPORT_STORE, () => showErrorToast(action.error));

export default outcomeReportReducer;
