import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getAuditTypeStatsList} from "../../../../store/statistics/auditTypeList/actions/AuditTypeStatsListActions";
import {WithServiceState} from "store-fetch-wrappers";
import VerticalBarChartListContainer from "../../../Statistics/Components/VerticalBarChartListContainer";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import {BasicStatsRequest} from "../../../../api/prf";
import StatsContainer from "./StatsContainer";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";

const WrappedAuditCategoryStats = WithServiceState(VerticalBarChartListContainer);

/** Audit Category Stats */
const AuditTypeComplianceStats = () => {
    const auditCategoryStore = useSelector((state: RootStore) => state.auditTypeStatsList);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<Moment>(moment());

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Watch the start date and check to see if anything has changed */
    useEffect(() => {
        const startOfYear = startDate.clone().startOf("year");
        const endOfYear = startDate.clone().endOf("year");

        const request: BasicStatsRequest = {
            creationDate: {
                startDate: startOfYear.unix(),
                endDate: endOfYear.unix()
            }
        };

        dispatch(getAuditTypeStatsList(request));
    }, [startDate]);

    return (
        <React.Fragment>
            <StatsContainer>
                <FormHeader headerName={"Audit Type Compliance"} />
                <FormRow rowName={"Year"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={startDate.toDate()}
                        onChange={onDateChanged}
                        showYearPicker
                        dateFormat="yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </FormRow>
                <WrappedAuditCategoryStats
                    loaderWheelType={"three-ring"}
                    loaderType={"normal"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...auditCategoryStore}
                />
            </StatsContainer>
        </React.Fragment>
    );
};
export default AuditTypeComplianceStats;
