import React from "react";
import {
    getDotClassName,
    SortedReportResults
} from "../../../../../../store/reports/helpers/reportHelpers";
import {ReportResult} from "../../../../../../api/prf";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import FormHeader from "../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {formatUnixToDDMMYYYYHHmm} from "../../../../../../utils/momentUtils";
import {PulseTableWithLocalPagination} from "pulse_table";
import Tooltip from "../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../Icon/Icon";
import {pagedRequestConfig} from "../../../../../Filters/helpers/filterHelpers";

const AuditTypeReportListBlock = (props: SortedReportResults<ReportResult>) => {
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    /** Allows for most recent version of an audit to be edited */
    const editAudit = (auditId: number) => {
        history.push({
            pathname: `${routeNames.editAudit.path}/${auditId}`,
            search: searchQueries
        });
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={props.headerText} />
                <PulseTableWithLocalPagination
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                    items={sortAuditTypeReportToTableRow(props.results)}
                    headers={{
                        staffDetails: "Staff Details",
                        venue: "Venue",
                        calendar: "Calendar",
                        auditType: "Audit Type",
                        date: "Date",
                        score: "Score",
                        auditId: "Actions"
                    }}
                    customRenderers={{
                        auditId: (item: TableRow) => (
                            <Tooltip tooltipText={"Edit this audit"} size={"md"} place={"left"}>
                                <Icon
                                    onClick={() => editAudit(item.auditId)}
                                    rootElement={"Span"}
                                    icon={IconType.Edit}
                                    size={"Medium"}
                                    className="cursor-pointer"
                                />
                            </Tooltip>
                        ),
                        score: (item: TableRow) => (
                            <p className="mb-0">
                                {item.score}
                                <span className={`mb-0 ml-2 ${getDotClassName(item)}`} />
                            </p>
                        )
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no audits with ${props.headerText}
                                </h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default AuditTypeReportListBlock;

interface TableRow {
    staffDetails?: string;
    venue: string;
    calendar: string;
    auditType: string;
    date: string;
    score: string;
    auditId: number;
}

function sortAuditTypeReportToTableRow(reportResults: ReportResult[]): TableRow[] {
    return reportResults.map((item) => {
        return {
            staffDetails: item.staffMember?.staffName,
            venue: item.grsLocation.venueName,
            calendar: item.grsLocation.calendarName,
            auditType: getUiFriendlyText(item.auditType),
            date: formatUnixToDDMMYYYYHHmm(item.date),
            score: item.score.toString(),
            auditId: item.auditId
        };
    });
}
