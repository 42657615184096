import React from "react";
import {Answer, QuestionWithAnswer} from "../../../../../../api/prf";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import FormRowQuestion from "../../../../../Form/FormRowQuestion";

const QuestionAndAnswer = (props: QuestionWithAnswerWithCallback) => {
    const isHistoric = useIsHistoricAudit();
    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };
    return (
        <React.Fragment>
            <FormRowQuestion
                isOptionalQuestion={props.isOptionalQuestion}
                rowName={props.question}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <GenericTypeDropdown
                    enumOptions={Answer}
                    splitByCapitalLetter={true}
                    clearable={false}
                    searchable={false}
                    changeOption={(value: number | string) => {
                        props.onChange(getAnswerFromString(value.toString()));
                    }}
                    id={props.answer}
                    disabled={isHistoric}
                />
            </FormRowQuestion>
            {/*<div*/}
            {/*    className={`question-row ${*/}
            {/*        props.isOptionalQuestion ? "question-row-optional" : ""*/}
            {/*    }`}*/}
            {/*>*/}
            {/*    <div className="row ml-0 mr-0">*/}
            {/*        <div className={`col pt-2 pb-2`}>*/}
            {/*            <p className="mt-2 mb-0">{props.question}</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="row ml-0 mr-0 mb-4">*/}
            {/*        <div className="col">*/}
            {/*            <GenericTypeDropdown*/}
            {/*                enumOptions={Answer}*/}
            {/*                splitByCapitalLetter={true}*/}
            {/*                clearable={false}*/}
            {/*                searchable={false}*/}
            {/*                changeOption={(value: number | string) => {*/}
            {/*                    props.onChange(getAnswerFromString(value.toString()));*/}
            {/*                }}*/}
            {/*                id={props.answer}*/}
            {/*                disabled={isHistoric}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

export default QuestionAndAnswer;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerWithCallback extends QuestionWithAnswer {
    onChange: (newAnswer: Answer) => void;
    isOptionalQuestion: boolean;
}
