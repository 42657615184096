import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const CALENDAR_AUDIT_STATS_STORE = createStoreState("calendar_audit_stats");

interface CalendarAuditStatsLoading extends StoreServiceData<ChartData> {
    type: typeof CALENDAR_AUDIT_STATS_STORE.LOADING;
}

interface CalendarAuditStatsError extends StoreServiceData<ChartData> {
    type: typeof CALENDAR_AUDIT_STATS_STORE.ERROR;
}

interface CalendarAuditStatsSuccess extends StoreServiceData<ChartData> {
    type: typeof CALENDAR_AUDIT_STATS_STORE.SUCCESS;
}

export type CalendarAuditStatsDispatchTypes =
    | CalendarAuditStatsLoading
    | CalendarAuditStatsError
    | CalendarAuditStatsSuccess;
