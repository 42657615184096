import React, {useEffect, useState} from "react";
import StatsContainer from "./StatsContainer";
import {BasicStatsRequest} from "../../../../api/prf";
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import {Venue} from "../../../../api/grs";
import VenueDropdown from "../../../Dropdown/Components/VenueDropdown";
import {getVenueAuditStatsByVenueId} from "../../../../store/statistics/venue/actions/VenueAuditStatsActions";
import {StatsOptionsContext} from "../../../Statistics/Context/StatsOptionsContext";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import HorizontalBarChartContainer from "../../../Statistics/Components/HorizontalBarChartContainer";

const BarChartWrapper = WithServiceState(HorizontalBarChartContainer);

/** Venue Compliance Stats */
const VenueComplianceStats = () => {
    const dispatch = useDispatch();
    const venueStatsStore = useSelector((state: RootStore) => state.venueAuditStats);

    const [venue, setVenue] = useState<Venue>({
        id: 0,
        name: "",
        calendarId: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: "Active",
        address: "",
        postcode: ""
    });

    const [startDate, setStartDate] = useState<Moment>(moment());

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Triggered when the venue is changed */
    const onVenueChange = (newVenue: Venue) => {
        setVenue(newVenue);
    };

    /** Watch for date and venue changes, if the venue id === 0, we want to ignore */
    useEffect(() => {
        if (!venue) return;
        if (venue.id === 0) return;
        const startOfMonth = startDate.clone().startOf("month");
        const endOfMonth = startDate.clone().endOf("month");

        const request: BasicStatsRequest = {
            creationDate: {
                startDate: startOfMonth.unix(),
                endDate: endOfMonth.unix()
            }
        };

        dispatch(getVenueAuditStatsByVenueId(venue.id, request));
    }, [venue, startDate]);

    return (
        <StatsContainer>
            <FormHeader headerName={"Venue Compliance"} />
            <FormRow rowName={"Venue"} columnDetailClassName={"pl-0 pr-0"}>
                <VenueDropdown
                    initialVenue={venue}
                    changeOption={onVenueChange}
                    clearable={false}
                />
            </FormRow>
            <FormRow rowName={"Month"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={startDate.toDate()}
                    onChange={onDateChanged}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    portalId="root-portal"
                    customInput={<DatePickerInputButton />}
                />
            </FormRow>
            <StatsOptionsContext.Provider value={{showPercentages: true}}>
                <BarChartWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"normal"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...venueStatsStore}
                />
            </StatsOptionsContext.Provider>
        </StatsContainer>
    );
};

export default VenueComplianceStats;
