import React from "react";
import {AuditType, AuditTypeStats} from "../../../../../../../api/prf";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {PulseTable} from "pulse_table";
import QualityReportStatsHeader from "./QualityReportStatsHeader";

const QualityReportStats = (props: AuditTypeStats) => {
    return (
        <React.Fragment>
            {props.totalNumberOfAudits > 0 && (
                <React.Fragment>
                    <QualityReportStatsHeader {...props} />
                    <PulseTable
                        items={toTableRow(props)}
                        headers={{
                            stat: "",
                            statValue: ""
                        }}
                        noItemsSection={
                            <div className="row ml-0 mr-0 fadeIn">
                                <div className="col">
                                    <h5 className="text-center p-3">
                                        No data available for Quality Report statistics
                                    </h5>
                                </div>
                            </div>
                        }
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default QualityReportStats;

interface TableRow {
    stat: string;
    statValue: number;
}

function toTableRow(stat: AuditTypeStats): TableRow[] {
    return [
        {
            stat: `Number of ${getCorrectUiNameForHeading(stat)} audits 100% compliant`,
            statValue: stat.numberOfAudits100PercentCompliant
        },
        {
            stat: `Number of ${getCorrectUiNameForHeading(stat)} audits 90%-99% compliant`,
            statValue: stat.numberOfAudits90to99PercentCompliant
        },
        {
            stat: `Number of ${getCorrectUiNameForHeading(stat)} audits <90% compliant`,
            statValue: stat.numberOfAuditsBelow90PercentCompliant
        },
        {
            stat: `Total number of ${getCorrectUiNameForHeading(stat)} audits`,
            statValue: stat.totalNumberOfAudits
        }
    ];
}

function getCorrectUiNameForHeading(stats: AuditTypeStats) {
    if (stats.auditType === AuditType.All) return "";

    return getUiFriendlyText(stats.auditType);
}
