import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {useUserData} from "../../Hooks/useUserData";
import {getNavItemsForAuditTrailLogsRoute} from "./Helpers/auditTrailLogsHelpers";
import AuditTrailLogsTable from "./Components/AuditTrailLogsTable";
import {WithServiceState} from "store-fetch-wrappers";
import useMcConfig from "../../Hooks/useMcConfig";

const ServiceWrapper = WithServiceState(AuditTrailLogsTable);

const AuditTrailLogs = () => {
    const dispatch = useDispatch();
    const auditTrailLogsStore = useSelector((state: RootStore) => state.auditTrailLogs);
    const user = useUserData();
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        dispatch(setNavigationItems(getNavItemsForAuditTrailLogsRoute(user, config)));
    }, [user, config]);

    return (
        <React.Fragment>
            <div className="page-container">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...auditTrailLogsStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AuditTrailLogs;
