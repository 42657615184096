import {Dispatch} from "redux";
import {
    COMPLIANCE_REPORT_STORE,
    ComplianceReportDispatchTypes
} from "./ComplianceReportActionTypes";
import {ReportRequest} from "../../../../api/prf";
import PrfApiModel from "../../../apiModel/PrfApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyComplianceReportStore = () => {
    return async (dispatch: Dispatch<ComplianceReportDispatchTypes>) => {
        dispatch({
            type: COMPLIANCE_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets compliance reports */
export const fetchComplianceReport = (request: ReportRequest) => {
    return async (dispatch: Dispatch<ComplianceReportDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                COMPLIANCE_REPORT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getComplianceReport(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: COMPLIANCE_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
