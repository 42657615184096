import {Answer, NotConveyedAudit, QuestionType} from "../../../api/prf";
import {newQuestionWithAnswer} from "./auditHelpers";
import {
    generateAppropriateMedicationGiven,
    generateAssessmentDocumented,
    generateCalContacted,
    generateCorrectClinicianAdministering,
    generateCorrectDosage,
    generateCorrectRouteOfAdministration,
    generateDocumentedCorrectly,
    generateInterpretationCorrect,
    generateManagementCorrect,
    generateNews2ScoreRecorded,
    generatePathfinderOutcome,
    generatePatientOutcomeReasonable,
    generateWorseningCareAdviceDocumented
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getNotConveyedQuestionsForNewAudit(): NotConveyedAudit {
    return {
        ...generateNotConveyedAudit(),
        assessmentDocumented: undefined,
        interpretationCorrect: undefined,
        managementCorrect: undefined,
        appropriateMedicationAdministered: undefined,
        correctRouteOfAdministration: undefined,
        correctDosage: undefined,
        documentedCorrectly: undefined,
        correctClinicianAdministering: undefined
    };
}

/** Not Conveyed audit */
export function generateNotConveyedAudit(): NotConveyedAudit {
    return {
        historyOfPresentingComplaint: newQuestionWithAnswer(
            1,
            "History of presenting complaint?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary Survey?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        atLeast2Observations: newQuestionWithAnswer(
            2,
            "At least two sets of observations recorded?",
            QuestionType.Normal
        ),
        leadEcg: newQuestionWithAnswer(2, "12 lead ECG?", QuestionType.Normal),
        interpretationCorrect: generateInterpretationCorrect(),
        managementCorrect: generateManagementCorrect(),
        clinicAssessmentAppropriate: newQuestionWithAnswer(
            5,
            "Clinical assessment appropriate?",
            QuestionType.Normal
        ),
        differentialDiagnosticReasonable: newQuestionWithAnswer(
            2,
            "Differential diagnosis reasonable?",
            QuestionType.Normal
        ),
        medicationGiven: newQuestionWithAnswer(2, "Medication given?", QuestionType.Normal),
        appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
        correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
        correctDosage: generateCorrectDosage(),
        documentedCorrectly: generateDocumentedCorrectly(),
        correctClinicianAdministering: generateCorrectClinicianAdministering(),
        patientOutcomeReasonable: generatePatientOutcomeReasonable(),
        pathfinderOutcome: generatePathfinderOutcome(),
        calContacted: generateCalContacted(),
        news2ScoreRecorded: generateNews2ScoreRecorded(),
        worseningCareAdviceGiven: generateWorseningCareAdviceDocumented(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: NotConveyedAudit
): NotConveyedAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function update12LeadEcgQuestionBasedOnAnswer(
    answer: Answer,
    audit: NotConveyedAudit
): NotConveyedAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                interpretationCorrect: generateInterpretationCorrect(),
                managementCorrect: generateManagementCorrect()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                interpretationCorrect: undefined,
                managementCorrect: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: NotConveyedAudit
): NotConveyedAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
                correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
                correctDosage: generateCorrectDosage(),
                documentedCorrectly: generateDocumentedCorrectly(),
                correctClinicianAdministering: generateCorrectClinicianAdministering()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                appropriateMedicationAdministered: undefined,
                correctRouteOfAdministration: undefined,
                correctDosage: undefined,
                documentedCorrectly: undefined,
                correctClinicianAdministering: undefined
            };
        default:
            return audit;
    }
}
