import React, {useEffect, useState} from "react";
import {AuditListRequest, AuditType, StaffLink} from "../../../../api/prf";
import {CalendarSummary, CalendarTypeEnum} from "../../../../api/grs";
import {useHistory} from "react-router-dom";
import moment, {Moment} from "moment";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {useQuery} from "../../../Hooks/useQuery";
import {capitalizeFirstLetter} from "../../../../utils/textUtils";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../api/staff";
import {
    AllAuditTypeFilters,
    getAuditTypeFromString
} from "../../Reports/Components/Filters/Components/ReportFilters";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import StaffDropdown from "../../../Dropdown/Components/StaffDropdown";
import CalendarDropdown from "../../../Dropdown/Components/CalendarDropdown";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import GenericTypeDropdown from "../../../Dropdown/Components/GenericTypeDropdown";

const AuditTrailLogsFilters = (props: RequestFilterProps<AuditListRequest>) => {
    const history = useHistory();
    const query = useQuery();
    const user = useUserData();
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [clinician, setClinician] = useState<StaffLink>({staffId: "", staffName: ""});
    const [calendar, setCalendar] = useState<CalendarSummary>({
        version: 0,
        id: 0,
        name: "",
        calendarType: CalendarTypeEnum.Event,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        billingType: "Daily",
        bgImageUrl: ""
    });
    const [auditType, setAuditType] = useState<AuditType>(AuditType.All);

    /** Upon mounting, check for the query strings*/
    useEffect(() => {
        const auditFilter = query.get("auditType");
        const clinicianFilter = query.get("clinicianId");
        const calendarFilter = query.get("calendarId");
        const startDateFilter = query.get("dateRangeStart");

        if (auditFilter) {
            setAuditType(getAuditTypeFromString(capitalizeFirstLetter(auditFilter)));
        }
        if (clinicianFilter) {
            setClinician({
                ...clinician,
                staffId: clinicianFilter
            });
        }
        if (calendarFilter) {
            setCalendar({
                ...calendar,
                id: +calendarFilter
            });
        }

        if (startDateFilter) {
            setStartDate(moment.unix(+startDateFilter));
        }
    }, []);

    /** When a Staff member or DM accesses page, set the clinician to be their username */
    useEffect(() => {
        if (user.username.length === 0) return;
        switch (user.accessLevel) {
            case StaffAccessLevel.Staff:
            case StaffAccessLevel.DutyManager:
                setClinician({
                    ...clinician,
                    staffId: user.username
                });
        }
    }, [user]);

    /** Updates the staff member when it is changed in the dropdown */
    const onStaffMemberChanged = (staff: StaffLink) => {
        setClinician(staff);
    };

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date).startOf("month"));
    };

    /** Updates the calendar when it is changed in the dropdown */
    const onCalendarChanged = (calendarSummary: CalendarSummary) => {
        setCalendar(calendarSummary);
    };

    /** Updates the audit type when it is changed in the dropdown */
    const onAuditTypeChanged = (value: string | number) => {
        setAuditType(getAuditTypeFromString(value.toString()));
    };

    // Anytime there is a change, we want to set the page number to 0
    useEffect(() => {
        const request = buildRequest(auditType, startDate, clinician, calendar);

        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [auditType, clinician, startDate, calendar]);

    const buildRequest = (
        incomingAuditType: AuditType,
        incomingStartDate: Moment,
        incomingClinician: StaffLink,
        incomingCalendar: CalendarSummary
    ): AuditListRequest => {
        const auditTypes = incomingAuditType === AuditType.All ? [] : [incomingAuditType];
        return {
            pageNum: 0,
            numPerPage: pagedRequestConfig.resultsPerPage,
            creationDate: {
                startDate: incomingStartDate.clone().startOf("month").unix(),
                endDate: incomingStartDate.clone().endOf("month").unix()
            },
            auditTypes,
            clinicianId:
                incomingClinician.staffId.length > 0 ? incomingClinician.staffId : undefined,
            calendarId: incomingCalendar.id > 0 ? incomingCalendar.id : undefined
        };
    };

    const buildUrlHistory = (request: AuditListRequest) => {
        const queryStrings: string[] = [];

        if (request.auditTypes.length > 0) {
            queryStrings.push(`auditType=${request.auditTypes[0]}`);
        }

        if (request.clinicianId) {
            queryStrings.push(`clinicianId=${request.clinicianId}`);
        }

        if (request.calendarId) {
            queryStrings.push(`calendarId=${request.calendarId}`);
        }

        queryStrings.push(`dateRangeStart=${request.creationDate.startDate}`);
        queryStrings.push(`dateRangeEnd=${request.creationDate.endDate}`);

        history.push({search: `?${queryStrings.join("&")}`});
    };
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-item">
                        <h6>Staff Member</h6>
                        <StaffDropdown
                            searchable={true}
                            changeOption={onStaffMemberChanged}
                            clearable={true}
                            id={clinician.staffId}
                            disabled={false}
                        />
                    </div>
                    <div className="filter-item">
                        <h6>Calendar</h6>
                        <CalendarDropdown
                            changeOption={onCalendarChanged}
                            id={calendar.id}
                            clearable={true}
                        />
                    </div>
                    <div className="filter-item">
                        <h6>Date</h6>
                        <DatePicker
                            selected={startDate.toDate()}
                            onChange={onDateChanged}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </div>
                    <div className="filter-item">
                        <h6>Audit Type</h6>
                        <GenericTypeDropdown
                            enumOptions={AllAuditTypeFilters}
                            splitByCapitalLetter={true}
                            clearable={false}
                            searchable={false}
                            changeOption={onAuditTypeChanged}
                            id={auditType}
                            disabled={false}
                        />
                    </div>
                </AuthAmI>
                <AuthAmI accessLevels={[StaffAccessLevel.DutyManager, StaffAccessLevel.Staff]}>
                    <div className="filter-item">
                        <h6>Date</h6>
                        <DatePicker
                            selected={startDate.toDate()}
                            onChange={onDateChanged}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </div>
                </AuthAmI>
            </div>
        </React.Fragment>
    );
};

export default AuditTrailLogsFilters;
