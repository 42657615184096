import React from "react";
import {OutcomeQuestions} from "../../../../../../../api/prf";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyNotConveyedOutcome = (props: OutcomeQuestions) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer {...props.discharged} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.referredToGp} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.referredToEolc} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.referredToMh} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.referredToHcp} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.madeOwnWay} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.taxiConveyance} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.refused} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.conveyedByNhs} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.role} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.noTrace} isOptionalQuestion={false} />
        </React.Fragment>
    );
};

export default ReadOnlyNotConveyedOutcome;
