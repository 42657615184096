import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {routeNames} from "../../../../Navigation/routeNames";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {nullifyComplianceReportStore} from "../../../../../store/reports/compliance/actions/ComplianceReportActions";
import FilterContainer from "../../../../Filters/FilterContainer";
import ReportFooter from "../../Components/ReportFooter";
import FailedQuestionsFilters from "./Components/FailedQuestionsFilters";
import ReportContainer from "../../Components/ReportContainer";
import {FailQueryRequest} from "../../../../../api/prf";
import {fetchFailedQuestions} from "../../../../../store/reports/failedReport/actions/FailedQuestionsReportActions";
import FailedQuestionTable from "./Components/FailedQuestionTable";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(FailedQuestionTable);
const FailedQuestionsReport = () => {
    const dispatch = useDispatch();
    const [request, setRequest] = useState<FailQueryRequest>();

    const failedQuestionsReportStore = useSelector(
        (state: RootStore) => state.failedQuestionsReport
    );

    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            },
            {
                name: routeNames.failedQuestions.name,
                path: routeNames.failedQuestions.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));

        return function () {
            dispatch(nullifyComplianceReportStore());
        };
    }, []);

    const onRequestChanged = (incomingRequest: FailQueryRequest) => {
        if (!request) {
            setRequest(incomingRequest);
            fetchReport(incomingRequest);
            return;
        }

        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(incomingRequest)) return;

        setRequest(incomingRequest);
        fetchReport(incomingRequest);
    };

    // Fetches Audits
    const fetchReport = (incomingRequest: FailQueryRequest) => {
        dispatch(fetchFailedQuestions({...incomingRequest, auditType: undefined}));
    };
    return (
        <ReportContainer>
            <FilterContainer closed={false}>
                <FailedQuestionsFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <ServiceWrapper
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={false}
                placeholderBlockCount={1}
                {...failedQuestionsReportStore}
            />
            <ReportFooter />
        </ReportContainer>
    );
};

export default FailedQuestionsReport;
