import PrfApiModel from "../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {STAFF_AUDIT_STATS_STORE, StaffAuditStatsDispatchTypes} from "./StaffAuditStatsActionTypes";
import {
    generateDonutStatForStaffAuditStats,
    generateStaffAuditStats
} from "../../helpers/prfStatsHelpers";
import {BasicStatsRequest} from "../../../../api/prf";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyStaffAuditStatsStore = () => {
    return async (dispatch: Dispatch<StaffAuditStatsDispatchTypes>) => {
        dispatch({
            type: STAFF_AUDIT_STATS_STORE.SUCCESS,
            data: null,
            loading: false,
            error: null
        });
    };
};

/** Gets a specific Staff Audit Stat by staff Id */
export const getStaffAuditStatsByStaffId = (id: string, request: BasicStatsRequest) => {
    return async (dispatch: Dispatch<StaffAuditStatsDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                STAFF_AUDIT_STATS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getClinicianAuditStats(id, request),
                statusCodeCallback
            );

            const staffAuditStat = stats ? generateDonutStatForStaffAuditStats(stats) : null;

            dispatch({
                type: STAFF_AUDIT_STATS_STORE.SUCCESS,
                error: null,
                loading: false,
                data: staffAuditStat
            });
        } catch (e: any) {
            dispatch({
                type: STAFF_AUDIT_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** For Development purposes only */
/** Mocks up stats  */
export const generateMockStaffAuditStats = () => {
    return async (dispatch: Dispatch<StaffAuditStatsDispatchTypes>) => {
        dispatch({
            type: STAFF_AUDIT_STATS_STORE.LOADING,
            error: null,
            loading: true
        });

        dispatch({
            type: STAFF_AUDIT_STATS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: generateDonutStatForStaffAuditStats(generateStaffAuditStats())
        });
    };
};
