import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AuditListResponse} from "../../../api/prf";
import {AUDIT_TRAIL_LOGS_STORE} from "../actions/AuditTrailLogsActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<AuditListResponse>(createBlankAuditListResponse());

/** Infer type of the reducer for code completion and quality */
const auditTrailLogsReducer = (
    state: StoreServiceData<AuditListResponse> = defaultState,
    action: ServiceActionTypes<AuditListResponse>
): StoreServiceData<AuditListResponse> =>
    createReducer(state, action, AUDIT_TRAIL_LOGS_STORE, () => showErrorToast(action.error));

export default auditTrailLogsReducer;

export function createBlankAuditListResponse(): AuditListResponse {
    return {
        auditTypes: [],
        creationDate: {startDate: 0, endDate: 0},
        numPerPage: 0,
        pageNum: 0,
        results: [],
        totalResults: 0
    };
}
