import {ReportResponse} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const COMPLIANCE_REPORT_STORE = createStoreState("compliance_report");

interface ComplianceReportLoading extends StoreServiceData<ReportResponse> {
    type: typeof COMPLIANCE_REPORT_STORE.LOADING;
}
interface ComplianceReportError extends StoreServiceData<ReportResponse> {
    type: typeof COMPLIANCE_REPORT_STORE.ERROR;
}
interface ComplianceReportSuccess extends StoreServiceData<ReportResponse> {
    type: typeof COMPLIANCE_REPORT_STORE.SUCCESS;
}

export type ComplianceReportDispatchTypes =
    | ComplianceReportLoading
    | ComplianceReportError
    | ComplianceReportSuccess;
