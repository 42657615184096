import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const CALENDAR_AUDIT_STATS_LIST_STORE = createStoreState("calendar_audit_stats_list");

interface CalendarAuditStatsListLoading extends StoreServiceData<ChartData[]> {
    type: typeof CALENDAR_AUDIT_STATS_LIST_STORE.LOADING;
}

interface CalendarAuditStatsListError extends StoreServiceData<ChartData[]> {
    type: typeof CALENDAR_AUDIT_STATS_LIST_STORE.ERROR;
}

interface CalendarAuditStatsListSuccess extends StoreServiceData<ChartData[]> {
    type: typeof CALENDAR_AUDIT_STATS_LIST_STORE.SUCCESS;
}

export type CalendarAuditStatsDispatchTypes =
    | CalendarAuditStatsListLoading
    | CalendarAuditStatsListError
    | CalendarAuditStatsListSuccess;
