import {Answer, GeneralAudit, QuestionType} from "../../../api/prf";
import {newQuestionWithAnswer} from "./auditHelpers";
import {
    generateAppropriateMedicationGiven,
    generateAssessmentDocumented,
    generateCorrectClinicianAdministering,
    generateCorrectDosage,
    generateCorrectRouteOfAdministration,
    generateDocumentedCorrectly,
    generateInterpretationCorrect,
    generateManagementCorrect
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getGeneralQuestionsForNewAudit(): GeneralAudit {
    return {
        ...generateGeneralAudit(),
        assessmentDocumented: undefined,
        interpretationCorrect: undefined,
        managementCorrect: undefined,
        appropriateMedicationAdministered: undefined,
        correctRouteOfAdministration: undefined,
        correctDosage: undefined,
        documentedCorrectly: undefined,
        correctClinicianAdministering: undefined,
        nameOfHospital: undefined,
        handoverTimes: undefined
    };
}

/** General Audit */
export function generateGeneralAudit(): GeneralAudit {
    return {
        historyOfPresentingComplaint: newQuestionWithAnswer(
            1,
            "History of presenting complaint?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        observationRecorded: newQuestionWithAnswer(1, "Observation recorded?", QuestionType.Normal),
        leadEcg: newQuestionWithAnswer(2, "12 lead ECG?", QuestionType.Normal),
        interpretationCorrect: generateInterpretationCorrect(),
        managementCorrect: generateManagementCorrect(),
        clinicAssessmentAppropriate: newQuestionWithAnswer(
            5,
            "Clinical assessment appropriate?",
            QuestionType.Normal
        ),
        differentialDiagnosticReasonable: newQuestionWithAnswer(
            2,
            "Differential diagnosis reasonable?",
            QuestionType.Normal
        ),
        medicationGiven: newQuestionWithAnswer(2, "Medication given?", QuestionType.Normal),
        appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
        correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
        correctDosage: generateCorrectDosage(),
        documentedCorrectly: generateDocumentedCorrectly(),
        correctClinicianAdministering: generateCorrectClinicianAdministering(),
        patientOutcomeReasonable: newQuestionWithAnswer(
            5,
            "Patient outcome reasonable?",
            QuestionType.Normal
        ),
        destination: newQuestionWithAnswer(1, "Destination?", QuestionType.Normal),
        nameOfHospital: generateNameOfHospital(),
        handoverTimes: generateHandoverTimes(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: GeneralAudit
): GeneralAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function update12LeadEcgQuestionBasedOnAnswer(
    answer: Answer,
    audit: GeneralAudit
): GeneralAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                interpretationCorrect: generateInterpretationCorrect(),
                managementCorrect: generateManagementCorrect()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                interpretationCorrect: undefined,
                managementCorrect: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: GeneralAudit
): GeneralAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
                correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
                correctDosage: generateCorrectDosage(),
                documentedCorrectly: generateDocumentedCorrectly(),
                correctClinicianAdministering: generateCorrectClinicianAdministering()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                appropriateMedicationAdministered: undefined,
                correctRouteOfAdministration: undefined,
                correctDosage: undefined,
                documentedCorrectly: undefined,
                correctClinicianAdministering: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateDestinationQuestionBasedOnAnswer(
    answer: Answer,
    audit: GeneralAudit
): GeneralAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                nameOfHospital: generateNameOfHospital(),
                handoverTimes: generateHandoverTimes()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                nameOfHospital: undefined,
                handoverTimes: undefined
            };
        default:
            return audit;
    }
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

function generateNameOfHospital() {
    return newQuestionWithAnswer(1, "Name of Hospital?", QuestionType.Normal);
}

function generateHandoverTimes() {
    return newQuestionWithAnswer(1, "Handover times?", QuestionType.Normal);
}
