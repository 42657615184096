import {CalendarEntry} from "../../../api/grs";
import {StaffLink} from "../../../api/prf";

/** Gets all assigned staff on shift*/
export function getAssignedStaffMembersFromCalendarEntry(entry: CalendarEntry): StaffLink[] {
    const assignedStaff: StaffLink[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                if (!assignment.staffMember) continue;

                assignedStaff.push(assignment.staffMember);
            }
        }
    }

    return assignedStaff;
}
