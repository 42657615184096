import {Answer, Conveyance, QuestionType, StrokeAudit} from "../../../api/prf";
import {newQuestionWithAnswer, newQuestionWithConveyance} from "./auditHelpers";
import {
    generateAppropriateMedicationGiven,
    generateAssessmentDocumented,
    generateCalContacted,
    generateCorrectClinicianAdministering,
    generateCorrectDosage,
    generateCorrectRouteOfAdministration,
    generateDocumentedCorrectly,
    generateHandoverTimes,
    generateInterpretationCorrect,
    generateManagementCorrect,
    generateNews2ScoreRecorded,
    generatePathfinderOutcome,
    generatePatientOutcomeReasonable,
    generateWorseningCareAdviceDocumented
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getStrokeQuestionsForNewAudit(): StrokeAudit {
    return {
        ...generateStrokeAudit(),
        assessmentDocumented: undefined,
        interpretationCorrect: undefined,
        managementCorrect: undefined,
        appropriateMedicationAdministered: undefined,
        correctRouteOfAdministration: undefined,
        correctDosage: undefined,
        documentedCorrectly: undefined,
        correctClinicianAdministering: undefined,
        hasu: undefined,
        stoke60Achieved: undefined,
        handoverTimes: undefined,
        patientOutcomeReasonable: undefined,
        pathfinderOutcome: undefined,
        calContacted: undefined,
        news2ScoreRecorded: undefined,
        worseningCareAdviceGiven: undefined
    };
}

/** Stroke audit */
export function generateStrokeAudit(): StrokeAudit {
    return {
        historyOfPresentingComplaint: newQuestionWithAnswer(
            1,
            "History of presenting complaint?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        observationRecorded: newQuestionWithAnswer(2, "Observation recorded?", QuestionType.Normal),
        leadEcg: newQuestionWithAnswer(2, "12 lead ECG?", QuestionType.Normal),
        interpretationCorrect: generateInterpretationCorrect(),
        managementCorrect: generateManagementCorrect(),
        fastRecordedCorrectly: newQuestionWithAnswer(
            4,
            "FAST recorded correctly?",
            QuestionType.Quality
        ),
        bmRecorded: newQuestionWithAnswer(4, "BM Recorded?", QuestionType.Quality),
        bpRecorded: newQuestionWithAnswer(4, "BP Recorded?", QuestionType.Quality),
        clinicAssessmentAppropriate: newQuestionWithAnswer(
            5,
            "Clinical assessment appropriate?",
            QuestionType.Normal
        ),
        differentialDiagnosticReasonable: newQuestionWithAnswer(
            2,
            "Differential diagnosis reasonable?",
            QuestionType.Normal
        ),
        medicationGiven: newQuestionWithAnswer(1, "Medication given?", QuestionType.Normal),
        appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
        correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
        correctDosage: generateCorrectDosage(),
        documentedCorrectly: generateDocumentedCorrectly(),
        correctClinicianAdministering: generateCorrectClinicianAdministering(),
        destination: newQuestionWithConveyance(0, "Destination?"),
        hasu: generateHasu(),
        stoke60Achieved: generateStroke60Achieved(),
        handoverTimes: generateHandoverTimes(),
        patientOutcomeReasonable: generatePatientOutcomeReasonable(),
        pathfinderOutcome: generatePathfinderOutcome(),
        calContacted: generateCalContacted(),
        news2ScoreRecorded: generateNews2ScoreRecorded(),
        worseningCareAdviceGiven: generateWorseningCareAdviceDocumented(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: StrokeAudit
): StrokeAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function update12LeadEcgQuestionBasedOnAnswer(
    answer: Answer,
    audit: StrokeAudit
): StrokeAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                interpretationCorrect: generateInterpretationCorrect(),
                managementCorrect: generateManagementCorrect()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                interpretationCorrect: undefined,
                managementCorrect: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: StrokeAudit
): StrokeAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
                correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
                correctDosage: generateCorrectDosage(),
                documentedCorrectly: generateDocumentedCorrectly(),
                correctClinicianAdministering: generateCorrectClinicianAdministering()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                appropriateMedicationAdministered: undefined,
                correctRouteOfAdministration: undefined,
                correctDosage: undefined,
                documentedCorrectly: undefined,
                correctClinicianAdministering: undefined
            };
        default:
            return audit;
    }
}

// Updates the questions in the Mental health and restraint audit based on conveyance
export function updateStrokeAuditBasedOnConveyance(
    conveyance: Conveyance,
    audit: StrokeAudit
): StrokeAudit {
    switch (conveyance) {
        case Conveyance.Conveyed:
            return {
                ...audit,
                hasu: generateHasu(),
                stoke60Achieved: generateStroke60Achieved(),
                handoverTimes: generateHandoverTimes(),
                patientOutcomeReasonable: undefined,
                pathfinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceGiven: undefined
            };
        case Conveyance.NotConveyed:
        case Conveyance.Referred:
        case Conveyance.Refused:
            return {
                ...audit,
                hasu: undefined,
                stoke60Achieved: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: generatePatientOutcomeReasonable(),
                pathfinderOutcome: generatePathfinderOutcome(),
                calContacted: generateCalContacted(),
                news2ScoreRecorded: generateNews2ScoreRecorded(),
                worseningCareAdviceGiven: generateWorseningCareAdviceDocumented()
            };
        case Conveyance.RecognitionOfLifeExtinct:
        case Conveyance.NotApplicable:
            return {
                ...audit,
                hasu: undefined,
                stoke60Achieved: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: undefined,
                pathfinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceGiven: undefined
            };
        default:
            return audit;
    }
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

function generateHasu() {
    return newQuestionWithAnswer(5, "HASU?", QuestionType.Normal);
}

function generateStroke60Achieved() {
    return newQuestionWithAnswer(0, "Stroke 60 achieved?", QuestionType.Normal);
}
