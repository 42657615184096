import {VENUE_LIST_STORE_STATE, VenueListDispatchTypes} from "./VenueListActionTypes";
import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the venue list store. */
export const nullifyVenueListStore = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        dispatch({
            type: VENUE_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets all venues from the MC DB */
export const fetchAllVenues = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            const venues = await getDataFromServiceWithData(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getAllVenues(),
                statusCodeCallback
            );
            dispatch({
                type: VENUE_LIST_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: venues
            });
            return venues;
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets a list of venues for a specific calendar. */
export const getVenuesForCalendarId = (calendarId: number) => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithData(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getVenuesForCalendarId(calendarId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
