import React from "react";
import {AuditForm, OutcomeType} from "../../../../../../api/prf";
import ConveyedOutcome from "./Outcomes/ConveyedOutcome";
import NotConveyedOutcome from "./Outcomes/NotConveyedOutcome";
import PatientReferralOutcome from "./Outcomes/PatientReferralOutcome";
import FormHeader from "../../../../../Form/FormHeader";

const OutcomeData = (props: AuditForm) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Outcome Data"} />
            {props.outcome === OutcomeType.Conveyed && <ConveyedOutcome {...props.outcomeData} />}
            {props.outcome === OutcomeType.NotConveyed && (
                <NotConveyedOutcome {...props.outcomeData} />
            )}
            {props.outcome === OutcomeType.PatientReferral && (
                <PatientReferralOutcome {...props.outcomeData} />
            )}
        </React.Fragment>
    );
};

export default OutcomeData;
