import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import venueListReducer from "./venueList/reducer/VenueListReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import calendarSummaryListReducer from "./calendarSummaryList/reducer/CalendarSummaryListReducer";
import calendarAuditStatsReducer from "./statistics/calendar/reducer/CalendarAuditStatsReducer";
import calendarAuditStatsListReducer from "./statistics/calendarList/reducer/CalendarAuditStatsListReducer";
import venueAuditStatsReducer from "./statistics/venue/reducer/VenueAuditStatsReducer";
import venueAuditStatsListReducer from "./statistics/venueList/reducer/VenueAuditStatsListReducer";
import staffAuditStatsReducer from "./statistics/staff/reducer/StaffAuditStatsReducer";
import auditTypeStatsReducer from "./statistics/auditType/reducer/AuditTypeStatsReducer";
import auditTypeStatsListReducer from "./statistics/auditTypeList/reducer/AuditTypeStatsListReducer";
import numberOfCompletedAuditsReducer from "./statistics/numberOfCompletedAudits/reducer/NumberOfCompletedAuditsReducer";
import overallComplianceReducer from "./statistics/overallCompliance/reducer/OverallComplianceReducer";
import calendarEntryReducer from "./calendarEntry/reducer/CalendarEntryReducer";
import calendarEntryListReducer from "./calendarEntryList/reducer/CalendarEntryListReducer";
import auditReducer from "./audit/reducer/AuditReducer";
import feedbackReducer from "./feedback/reducer/FeedbackReducer";
import auditTrailLogsReducer from "./auditTrailLogs/reducer/AuditTrailLogsReducer";
import auditHistoricReducer from "./auditHistoric/reducer/auditHistoricReducer";
import auditTypeReportReducer from "./reports/auditType/reducer/AuditTypeReportReducer";
import complianceReportReducer from "./reports/compliance/reducer/ComplianceReportReducer";
import outcomeReportReducer from "./reports/outcome/reducer/OutcomeReportReducer";
import qualityReportReducer from "./reports/quality/reducer/QuailtyReportReducer";
import venueReportReducer from "./reports/venue/reducer/VenueReportReducer";
import failedQuestionsReportReducer from "./reports/failedReport/reducer/FailedQuestionsReportReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    venueList: venueListReducer,
    staffList: staffListReducer,
    calendarSummaryList: calendarSummaryListReducer,
    calendarAuditStats: calendarAuditStatsReducer,
    calendarAuditStatsList: calendarAuditStatsListReducer,
    venueAuditStats: venueAuditStatsReducer,
    venueAuditStatsList: venueAuditStatsListReducer,
    staffAuditStats: staffAuditStatsReducer,
    auditTypeStats: auditTypeStatsReducer,
    auditTypeStatsList: auditTypeStatsListReducer,
    numberOfCompletedAudits: numberOfCompletedAuditsReducer,
    overallCompliance: overallComplianceReducer,
    calendarEntry: calendarEntryReducer,
    calendarEntryList: calendarEntryListReducer,
    audit: auditReducer,
    feedback: feedbackReducer,
    auditTrailLogs: auditTrailLogsReducer,
    auditHistoric: auditHistoricReducer,
    auditTypeReport: auditTypeReportReducer,
    complianceReport: complianceReportReducer,
    outcomeReport: outcomeReportReducer,
    qualityReport: qualityReportReducer,
    venueReport: venueReportReducer,
    failedQuestionsReport: failedQuestionsReportReducer
});

export default RootReducer;
