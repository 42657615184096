import React from "react";
import {ChartData} from "../../../utils/donutChartUtils";
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";
import HorizontalBarChart from "./HorizontalBarChart";
import {nanoid} from "nanoid";

const HorizontalBarChartContainer = (props: ChartData) => {
    return (
        <React.Fragment>
            {props.totalValue > 0 ? (
                <div className="horizontal-bar-chart-container">
                    {props.percentageDataEntries.map((entry: DataEntry, index: number) => {
                        return (
                            <HorizontalBarChart
                                key={index}
                                uid={nanoid()}
                                maxValue={100}
                                currentValue={entry.value}
                                barColour={entry.color}
                                tooltipText={getTooltipText(props, index, entry)}
                            />
                        );
                    })}
                    <h5 className="text-center pt-2 mb-0 header-font">
                        {props.outerLabel} ({props.totalValue})
                    </h5>
                </div>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">Stats do not exist for these parameters</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default HorizontalBarChartContainer;

function getTooltipText(chartData: ChartData, index: number, entry: DataEntry): string {
    return `${entry.title}: ${chartData.rawValueDataEntries[index].value} / ${chartData.totalValue} (${entry.value}%)`;
}
