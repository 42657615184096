import PrfApiModel from "../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {
    CALENDAR_AUDIT_STATS_LIST_STORE,
    CalendarAuditStatsDispatchTypes
} from "./CalendarAuditStatsListActionTypes";
import {generateDonutStatForCalendarAuditStatsList} from "../../helpers/prfStatsHelpers";
import {BasicStatsRequest} from "../../../../api/prf";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyCalendarAuditStatsListStore = () => {
    return async (dispatch: Dispatch<CalendarAuditStatsDispatchTypes>) => {
        dispatch({
            type: CALENDAR_AUDIT_STATS_LIST_STORE.SUCCESS,
            data: [],
            loading: false,
            error: null
        });
    };
};

/** Gets all the stats for the calendar audit stats list. */
export const getCalendarAuditStatsList = (request: BasicStatsRequest) => {
    return async (dispatch: Dispatch<CalendarAuditStatsDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                CALENDAR_AUDIT_STATS_LIST_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAllCalendarAuditStats(request),
                statusCodeCallback
            );

            const calendarAuditListStats = stats
                ? generateDonutStatForCalendarAuditStatsList(stats)
                : [];

            dispatch({
                type: CALENDAR_AUDIT_STATS_LIST_STORE.SUCCESS,
                error: null,
                loading: false,
                data: calendarAuditListStats
            });
        } catch (e: any) {
            dispatch({
                type: CALENDAR_AUDIT_STATS_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
