import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select, {SingleValue} from "react-select";
import {
    CalendarEntryDropdownProps,
    DDProps,
    getSelectedDropdownOptionByValue,
    sortCalendarEntriesToDropdownProps
} from "../Helpers/dropdownUtils";
import {RootStore} from "../../../store/Store";
import {
    CalendarEntry,
    CalendarSummary,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    Venue
} from "../../../api/grs";
import moment from "moment";
import {
    getCalendarEntriesList,
    nullifyCalendarEntriesListStore
} from "../../../store/calendarEntryList/actions/CalendarEntryListActions";
import {convertLocationDetailsToGrsLocation} from "../../../store/audit/helpers/auditHelpers";
import {
    fetchAllVenues,
    nullifyVenueListStore
} from "../../../store/venueList/actions/VenueListActions";
import {
    getCalendarSummaryList,
    nullifyCalendarSummaryListStore
} from "../../../store/calendarSummaryList/actions/CalendarSummaryListActions";
import {Loading} from "store-fetch-wrappers";

/** Calendar Entry Dropdown */
const CalendarEntryDropdown = (props: CalendarEntryDropdownProps) => {
    const currentStartDate = useRef<number>(0);
    const calendarEntryListStore = useSelector((state: RootStore) => state.calendarEntryList);
    const [calendarEntryOptions, setCalendarEntryOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: "", label: ""});
    const [venueList, setVenueList] = useState<Venue[]>([]);
    const [calendarList, setCalendarList] = useState<CalendarSummary[]>([]);
    const [entryList, setEntryList] = useState<CalendarEntry[]>([]);
    const dispatch = useDispatch();

    /** Nuke state of these stores when component is unmounted */
    useEffect(() => {
        return function () {
            dispatch(nullifyCalendarEntriesListStore());
            dispatch(nullifyVenueListStore());
            dispatch(nullifyCalendarSummaryListStore());
        };
    }, []);

    /** Get all entries for date */
    useEffect(() => {
        /** Only make a request if the start date has changed */
        if (currentStartDate.current === props.startDate) return;
        currentStartDate.current = props.startDate;

        resetOptions();

        const actualStart = moment.unix(props.startDate).startOf("day").unix();
        const actualEnd = moment.unix(props.startDate).endOf("day").unix();
        (async function getEntriesForDay() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const entries: EventCalendarEntry[] | FrontlineCalendarEntry[] = await dispatch(
                getCalendarEntriesList({startDate: actualStart, endDate: actualEnd})
            );
            setEntryList(entries);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const venues: Venue[] = await dispatch(fetchAllVenues());
            setVenueList(venues);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const calendars: CalendarSummary[] = await dispatch(getCalendarSummaryList());
            setCalendarList(calendars);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            processCalendarEntriesIncoming(entries, venues, calendars);
        })();
    }, [props.startDate]);

    const processCalendarEntriesIncoming = (
        entries: CalendarEntry[],
        venues: Venue[],
        calendars: CalendarSummary[]
    ) => {
        const options = sortCalendarEntriesToDropdownProps(entries, venues);
        setCalendarEntryOptions(options);
        getSelectedOptionFromList(options, entries, venues, calendars);
    };

    const getSelectedOptionFromList = (
        options: DDProps[],
        entries: CalendarEntry[],
        venues: Venue[],
        calendars: CalendarSummary[]
    ) => {
        const id = props.location ? props.location.entryId : 0;

        const option = getSelectedDropdownOptionByValue(id, options);
        if (!option) return;
        setSelectedOption(option);

        const grsLocation = convertLocationDetailsToGrsLocation(
            +option.value,
            entries,
            venues,
            calendars
        );

        props.changeOption(grsLocation);
    };

    /** Fired when the dropdown selection is changed */
    const handleEntryChange = (p: SingleValue<DDProps>) => {
        if (!p) return;
        const option = getSelectedDropdownOptionByValue(p.value, calendarEntryOptions);
        setSelectedOption(option);

        const grsLocation = convertLocationDetailsToGrsLocation(
            +option.value,
            entryList,
            venueList,
            calendarList
        );

        props.changeOption(grsLocation);
    };

    /** Anytime we make a request to the service, we want to reset state of these objects */
    const resetOptions = () => {
        setCalendarEntryOptions([]);
    };

    return (
        <React.Fragment>
            {calendarEntryOptions.length > 0 ? (
                <React.Fragment>
                    {calendarEntryListStore.loading && (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    )}
                    <Select
                        className={`search-bar ${props.className}`}
                        classNamePrefix="select-options"
                        options={calendarEntryOptions}
                        onChange={handleEntryChange}
                        isSearchable={props.searchable}
                        value={selectedOption}
                        noOptionsMessage={() => "No Entries Found"}
                        placeholder="Select Entry"
                        isDisabled={props.disabled}
                        isClearable={false}
                        menuPortalTarget={document.body}
                    />
                </React.Fragment>
            ) : (
                <p className="section-header mt-2 pl-3">
                    There are no events for the date selected
                </p>
            )}
        </React.Fragment>
    );
};

export default CalendarEntryDropdown;
