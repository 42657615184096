import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AUDIT_TYPE_REPORT_STORE} from "../actions/AuditTypeReportActionTypes";
import {ReportResponse} from "../../../../api/prf";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ReportResponse>(null);

/** Infer type of the reducer for code completion and quality */
const auditTypeReportReducer = (
    state: StoreServiceData<ReportResponse> = defaultState,
    action: ServiceActionTypes<ReportResponse>
): StoreServiceData<ReportResponse> =>
    createReducer(state, action, AUDIT_TYPE_REPORT_STORE, () => showErrorToast(action.error));

export default auditTypeReportReducer;
