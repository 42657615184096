import React from "react";
import {YesAnswerMonthlyAuditTypeResult} from "../../../../../../api/prf";
import {useReportDateView} from "../../../../../Hooks/useReportDateView";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {formatUnixToMMMMYYYY, formatUnixToYYYY} from "../../../../../../utils/momentUtils";
import FormHeader from "../../../../../Form/FormHeader";

const QualityReportTableHeader = (props: YesAnswerMonthlyAuditTypeResult) => {
    const {dateStart, showMonth} = useReportDateView();

    const dateRangeText = (value: boolean, date: number) => {
        return value ? formatUnixToMMMMYYYY(date) : formatUnixToYYYY(date);
    };

    return (
        <FormHeader
            headerName={`Quality report for ${getUiFriendlyText(
                props.auditType
            )} audits for ${dateRangeText(showMonth, dateStart)}`}
        />
    );
};

export default QualityReportTableHeader;
