import PrfApiModel from "../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {
    OVERALL_COMPLIANCE_STORE,
    OverallComplianceDispatchTypes
} from "./OverallComplianceActionTypes";
import {generateOverallComplianceStats} from "../../helpers/prfStatsHelpers";
import {BasicStatsRequest} from "../../../../api/prf";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyOverallComplianceStore = () => {
    return async (dispatch: Dispatch<OverallComplianceDispatchTypes>) => {
        dispatch({
            type: OVERALL_COMPLIANCE_STORE.SUCCESS,
            data: null,
            loading: false,
            error: null
        });
    };
};

/** Gets all overall stats for audit compliance. */
export const getOverallStatsList = (request: BasicStatsRequest) => {
    return async (dispatch: Dispatch<OverallComplianceDispatchTypes>) => {
        try {
            const stats = await getDataFromServiceWithData(
                OVERALL_COMPLIANCE_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAllAuditTypeStats(request),
                statusCodeCallback
            );

            const overallComplianceStats = stats ? generateOverallComplianceStats(stats) : null;

            dispatch({
                type: OVERALL_COMPLIANCE_STORE.SUCCESS,
                error: null,
                loading: false,
                data: overallComplianceStats
            });
        } catch (e: any) {
            dispatch({
                type: OVERALL_COMPLIANCE_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
