import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {VENUE_AUDIT_STATS_STORE} from "../actions/VenueAuditStatsActionTypes";
import {ChartData} from "../../../../utils/donutChartUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<ChartData>(null);

/** Infer type of the reducer for code completion and quality */
const venueAuditStatsReducer = (
    state: StoreServiceData<ChartData> = defaultState,
    action: ServiceActionTypes<ChartData>
): StoreServiceData<ChartData> =>
    createReducer(state, action, VENUE_AUDIT_STATS_STORE, () => showErrorToast(action.error));

export default venueAuditStatsReducer;
