import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";
import Icon, {IconType} from "../Icon/Icon";

const FormRowQuestion = ({
    children,
    rowName,
    rowClassName,
    formRowClassName,
    columnHeaderClassName,
    columnDetailClassName,
    isOptionalQuestion,
    showIcon,
    iconType,
    iconClassName
}: FormRowProps) => {
    return (
        <div
            className={`${
                isOptionalQuestion ? "mc-form-row-optional" : "mc-form-row"
            } ${formRowClassName}`}
        >
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnHeaderClassName}`}>
                    <h6 className="mb-0">
                        {rowName}
                        {showIcon && iconType && (
                            <Icon
                                rootElement={"Span"}
                                icon={iconType}
                                size={"Large"}
                                className={iconClassName || ""}
                            />
                        )}
                    </h6>
                </div>
            </div>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnDetailClassName}`}>{children}</div>
            </div>
        </div>
    );
};

export default FormRowQuestion;

interface FormRowProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnHeaderClassName?: string;
    columnDetailClassName?: string;
    formRowClassName?: string;
    isOptionalQuestion: boolean;
    showIcon?: boolean;
    iconType?: IconType;
    iconClassName?: string;
}
