import React from "react";
import {
    Answer,
    Conveyance,
    MentalHealthAndRestraintAudit,
    QuestionWithAnswer,
    QuestionWithConveyance
} from "../../../../../../../api/prf";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import QuestionAndConveyance from "../../Question/QuestionAndConveyance";
import {updateMentalHealthAndOrRestraintQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {useDispatch} from "react-redux";
import {
    updateCapacityAssessedQuestionBasedOnAnswer,
    updateMentalHealthAuditBasedOnConveyance,
    updateNeedForRestraintQuestionBasedOnAnswer
} from "../../../../../../../store/audit/helpers/mentalHealthAndRestraintAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const MentalHealthAndRestraintQuestions = (props: MentalHealthAndRestraintAudit) => {
    const dispatch = useDispatch();

    /** Updates Primary Survey correctly */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates Observation Recorded correctly */
    const updateObservationRecorded = (answer: Answer) => {
        const observationRecorded: QuestionWithAnswer = {
            ...props.observationRecorded,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                observationRecorded
            })
        );
    };

    /** Updates BM Recorded correctly */
    const updateBmRecorded = (answer: Answer) => {
        const bmRecorded: QuestionWithAnswer = {
            ...props.bmRecorded,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                bmRecorded
            })
        );
    };

    /** Updates Consent Obtained correctly */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates Capacity Assessed correctly */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };

        dispatch(
            updateMentalHealthAndOrRestraintQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates Assessment Documented correctly */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates Current MH Care Facility Recorded correctly */
    const updateCurrentMhCareFacilityRecorded = (answer: Answer) => {
        const currentMhCareFacilityRecorded: QuestionWithAnswer = {
            ...props.currentMhCareFacilityRecorded,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                currentMhCareFacilityRecorded
            })
        );
    };

    /** Updates Psychiatric History Recorded correctly */
    const updatePsychiatricHistoryRecorded = (answer: Answer) => {
        const psychiatricHistoryRecorded: QuestionWithAnswer = {
            ...props.psychiatricHistoryRecorded,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                psychiatricHistoryRecorded
            })
        );
    };

    /** Updates Mh Risk Assessment Undertaken correctly */
    const updateMhRiskAssessmentUndertaken = (answer: Answer) => {
        const mhRiskAssessmentUndertaken: QuestionWithAnswer = {
            ...props.mhRiskAssessmentUndertaken,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                mhRiskAssessmentUndertaken
            })
        );
    };

    /** Updates Suicide Risk Assessment Undertaken correctly */
    const updateSuicideRiskAssessmentUndertaken = (answer: Answer) => {
        const suicideRiskAssessmentUndertaken: QuestionWithAnswer = {
            ...props.suicideRiskAssessmentUndertaken,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                suicideRiskAssessmentUndertaken
            })
        );
    };

    /** Updates Patients Behaviour Documented correctly */
    const updatePatientsBehaviourDocumented = (answer: Answer) => {
        const patientsBehaviourDocumented: QuestionWithAnswer = {
            ...props.patientsBehaviourDocumented,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                patientsBehaviourDocumented
            })
        );
    };

    /** Updates Need For Restraint correctly */
    const updateNeedForRestraint = (answer: Answer) => {
        const needForRestraint: QuestionWithAnswer = {
            ...props.needForRestraint,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions(
                updateNeedForRestraintQuestionBasedOnAnswer(answer, {...props, needForRestraint})
            )
        );
    };

    /** Updates Restraint In Proportion Or Best Interest correctly */
    const updateRestraintInProportionOrBestInterest = (answer: Answer) => {
        if (!props.restraintInProportionOrBestInterests) return;
        const restraintInProportionOrBestInterests: QuestionWithAnswer = {
            ...props.restraintInProportionOrBestInterests,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                restraintInProportionOrBestInterests
            })
        );
    };

    /** Updates Restraint necessary correctly */
    const updateRestraintNecessary = (answer: Answer) => {
        if (!props.restraintNecessary) return;
        const restraintNecessary: QuestionWithAnswer = {
            ...props.restraintNecessary,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                restraintNecessary
            })
        );
    };

    /** Updates destination correctly */
    const updateDestination = (conveyance: Conveyance) => {
        const destination: QuestionWithConveyance = {
            ...props.destination,
            conveyance
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions(
                updateMentalHealthAuditBasedOnConveyance(conveyance, {...props, destination})
            )
        );
    };

    /** Updates Appropriate Destination */
    const updateAppropriateDestination = (answer: Answer) => {
        if (!props.appropriateDestination) return;
        const appropriateDestination: QuestionWithAnswer = {
            ...props.appropriateDestination,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                appropriateDestination
            })
        );
    };

    /** Updates Handover times correctly */
    const updateHandoverTimes = (answer: Answer) => {
        if (!props.handoverTimes) return;
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    /** Updates Patient Outcome correctly */
    const updatePatientOutcomeReasonable = (answer: Answer) => {
        if (!props.patientOutcomeReasonable) return;
        const patientOutcomeReasonable: QuestionWithAnswer = {
            ...props.patientOutcomeReasonable,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                patientOutcomeReasonable
            })
        );
    };

    /** Updates Pathfinder Outcome correctly */
    const updatePathfinderOutcome = (answer: Answer) => {
        if (!props.pathFinderOutcome) return;
        const pathFinderOutcome: QuestionWithAnswer = {
            ...props.pathFinderOutcome,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                pathFinderOutcome
            })
        );
    };

    /** Updates CAL Contacted correctly */
    const updateCalContacted = (answer: Answer) => {
        if (!props.calContacted) return;
        const calContacted: QuestionWithAnswer = {
            ...props.calContacted,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                calContacted
            })
        );
    };

    /** Updates News2 Score Recorded correctly */
    const updateNews2ScoreRecorded = (answer: Answer) => {
        if (!props.news2ScoreRecorded) return;
        const news2ScoreRecorded: QuestionWithAnswer = {
            ...props.news2ScoreRecorded,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                news2ScoreRecorded
            })
        );
    };

    /** Updates Worsening Care Advice Documented */
    const updateWorseningCareAdviceDocumented = (answer: Answer) => {
        if (!props.worseningCareAdviceDocumented) return;
        const worseningCareAdviceDocumented: QuestionWithAnswer = {
            ...props.worseningCareAdviceDocumented,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                worseningCareAdviceDocumented
            })
        );
    };

    /** Updates Call closed correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateMentalHealthAndOrRestraintQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Mental Health And Or Restraint Audit"} />
            {/** Primary survey */}
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            {/** Observation recorded */}
            <QuestionAndAnswer
                {...props.observationRecorded}
                onChange={updateObservationRecorded}
                isOptionalQuestion={false}
            />
            {/** BM recorded */}
            <QuestionAndAnswer
                {...props.bmRecorded}
                onChange={updateBmRecorded}
                isOptionalQuestion={false}
            />
            {/** Consent obtained */}
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            {/** Capacity assessed */}
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {/** Assessment Documented */}
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            {/** Current MH care facility recorded*/}
            <QuestionAndAnswer
                {...props.currentMhCareFacilityRecorded}
                onChange={updateCurrentMhCareFacilityRecorded}
                isOptionalQuestion={false}
            />
            {/** Psychiatric history recorded */}
            <QuestionAndAnswer
                {...props.psychiatricHistoryRecorded}
                onChange={updatePsychiatricHistoryRecorded}
                isOptionalQuestion={false}
            />
            {/** MH risk assessment undertaken */}
            <QuestionAndAnswer
                {...props.mhRiskAssessmentUndertaken}
                onChange={updateMhRiskAssessmentUndertaken}
                isOptionalQuestion={false}
            />
            {/** Suicide risk assessment undertaken */}
            <QuestionAndAnswer
                {...props.suicideRiskAssessmentUndertaken}
                onChange={updateSuicideRiskAssessmentUndertaken}
                isOptionalQuestion={false}
            />
            {/** Patient's behaviour documented */}
            <QuestionAndAnswer
                {...props.patientsBehaviourDocumented}
                onChange={updatePatientsBehaviourDocumented}
                isOptionalQuestion={false}
            />
            {/** Need for restraint */}
            <QuestionAndAnswer
                {...props.needForRestraint}
                onChange={updateNeedForRestraint}
                isOptionalQuestion={false}
            />
            {/** Restraint proportionate/best interests */}
            {props.restraintInProportionOrBestInterests && (
                <QuestionAndAnswer
                    {...props.restraintInProportionOrBestInterests}
                    onChange={updateRestraintInProportionOrBestInterest}
                    isOptionalQuestion={true}
                />
            )}
            {/** Restraint necessary */}
            {props.restraintNecessary && (
                <QuestionAndAnswer
                    {...props.restraintNecessary}
                    onChange={updateRestraintNecessary}
                    isOptionalQuestion={true}
                />
            )}
            {/** Destination */}
            <QuestionAndConveyance
                dropdownOptions={MentalHealthAndRestraintDestinations}
                {...props.destination}
                onChange={updateDestination}
            />
            {/** Appropriate destination */}
            {props.appropriateDestination && (
                <QuestionAndAnswer
                    {...props.appropriateDestination}
                    onChange={updateAppropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {/** Handover times */}
            {props.handoverTimes && (
                <QuestionAndAnswer
                    {...props.handoverTimes}
                    onChange={updateHandoverTimes}
                    isOptionalQuestion={true}
                />
            )}
            {/** Patient outcome reasonable */}
            {props.patientOutcomeReasonable && (
                <QuestionAndAnswer
                    {...props.patientOutcomeReasonable}
                    onChange={updatePatientOutcomeReasonable}
                    isOptionalQuestion={true}
                />
            )}
            {/** Pathfinder outcome */}
            {props.pathFinderOutcome && (
                <QuestionAndAnswer
                    {...props.pathFinderOutcome}
                    onChange={updatePathfinderOutcome}
                    isOptionalQuestion={true}
                />
            )}
            {/** CAL contacted */}
            {props.calContacted && (
                <QuestionAndAnswer
                    {...props.calContacted}
                    onChange={updateCalContacted}
                    isOptionalQuestion={true}
                />
            )}
            {/** NEWS2 score recorded */}
            {props.news2ScoreRecorded && (
                <QuestionAndAnswer
                    {...props.news2ScoreRecorded}
                    onChange={updateNews2ScoreRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {/** Worsening care advice documented */}
            {props.worseningCareAdviceDocumented && (
                <QuestionAndAnswer
                    {...props.worseningCareAdviceDocumented}
                    onChange={updateWorseningCareAdviceDocumented}
                    isOptionalQuestion={true}
                />
            )}
            {/** Call closed correctly */}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default MentalHealthAndRestraintQuestions;

// eslint-disable-next-line no-shadow
enum MentalHealthAndRestraintDestinations {
    Conveyed = "conveyed",
    NotConveyed = "notConveyed",
    Refused = "refused",
    Referred = "referred",
    NotApplicable = "notApplicable"
}
