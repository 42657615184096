import {BaseAudit, QuestionType, QuestionWithAnswer} from "../../../api/prf";
import {newQuestionWithAnswer} from "./auditHelpers";

/** Generates the base mandatory questions without the optional fields created */
export function getBaseQuestionsForNewAudit(): BaseAudit {
    return {
        ...generateBaseQuestions(),
        nameOfSchool: undefined,
        accompanyingAdult: undefined
    };
}

/** Generates the base mandatory questions */
export function generateBaseQuestions(): BaseAudit {
    return {
        locationOfCall: newQuestionWithAnswer(1, "Location of call?", QuestionType.Normal),
        activationAndResponseTimes: newQuestionWithAnswer(
            2,
            "Activation and response times?",
            QuestionType.Normal
        ),
        crewDetails: newQuestionWithAnswer(1, "Crew details?", QuestionType.Normal),
        patientsName: newQuestionWithAnswer(1, "Patient's name?", QuestionType.Normal),
        dob: newQuestionWithAnswer(1, "Date of Birth?", QuestionType.Normal),
        patientsHomeAddress: newQuestionWithAnswer(
            1,
            "Patient's home address?",
            QuestionType.Normal
        ),
        gp: newQuestionWithAnswer(1, "GP?", QuestionType.Normal),
        nextOfKin: newQuestionWithAnswer(1, "Next of kin?", QuestionType.Normal),
        ethnicity: newQuestionWithAnswer(2, "Ethnicity?", QuestionType.Normal),
        nhsNumber: newQuestionWithAnswer(2, "NHS Number?", QuestionType.Normal),
        paediatricPatient: newQuestionWithAnswer(0, "Paediatric patient?", QuestionType.Normal),
        nameOfSchool: generateNameOfSchoolQuestion(),
        accompanyingAdult: generateAccompanyingAdultQuestion(),
        medicalHistory: newQuestionWithAnswer(1, "Medical history?", QuestionType.Normal),
        drugHistory: newQuestionWithAnswer(1, "Drug history?", QuestionType.Normal),
        allergies: newQuestionWithAnswer(1, "Allergies?", QuestionType.Normal)
    };
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

// Generates the name of the school question
export function generateNameOfSchoolQuestion(): QuestionWithAnswer {
    return newQuestionWithAnswer(2, "Name of school?", QuestionType.Normal);
}

// Generates the accompanying adult
export function generateAccompanyingAdultQuestion(): QuestionWithAnswer {
    return newQuestionWithAnswer(2, "Accompanying adult?", QuestionType.Normal);
}
