import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useHistory} from "react-router-dom";
import FormHeader from "../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";

const PrfReports = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    /** Page setup */
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));
    }, []);

    // Goes to Audit Type report
    const goToAuditTypeReport = () => {
        history.push(routeNames.auditTypeReport.path);
    };

    // Goes to Audit Compliance report
    const goToAuditComplianceReport = () => {
        history.push(routeNames.auditComplianceReport.path);
    };

    // Goes to Outcome report
    const goToOutcomeReport = () => {
        history.push(routeNames.outcomeReport.path);
    };

    // Goes to Quality report
    const goToQualityReport = () => {
        history.push(routeNames.qualityReport.path);
    };

    // Goes to Venue report
    const goToVenueComplianceReport = () => {
        history.push(routeNames.venueReport.path);
    };

    // Goes to failed questions report
    const goToFailedQuestionsReport = () => {
        history.push(routeNames.failedQuestions.path);
    };

    return (
        <React.Fragment>
            <div className="page-container mt-5">
                <FormHeader headerName={"Select a report to view"} />
                <div className="row ml-0 mr-0 mt-3">
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Audit Type Report"}
                            onClick={goToAuditTypeReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Compliance Report"}
                            onClick={goToAuditComplianceReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Outcome Report"}
                            onClick={goToOutcomeReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Quality Report"}
                            onClick={goToQualityReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Failed Questions Report"}
                            onClick={goToFailedQuestionsReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Venue Report"}
                            onClick={goToVenueComplianceReport}
                            colour={ButtonColourOptions.DarkBlue}
                            className={"w-100"}
                        />
                    </div>
                </div>
            </div>
            {/*<div className="md-layout mt-5">*/}
            {/*    <div className="md-layout-item">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col">*/}
            {/*                <p className="section-header mb-0 float-left">*/}
            {/*                    <b>Select a report to view:</b>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col">*/}
            {/*                <div className="section-borders">*/}
            {/*                    <div className="row m-3">*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToAuditTypeReport}*/}
            {/*                            >*/}
            {/*                                Audit Type Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToAuditComplianceReport}*/}
            {/*                            >*/}
            {/*                                Compliance Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToOutcomeReport}*/}
            {/*                            >*/}
            {/*                                Outcome Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="row m-3">*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToQualityReport}*/}
            {/*                            >*/}
            {/*                                Quality Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToFailedQuestionsReport}*/}
            {/*                            >*/}
            {/*                                Failed Questions Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-4">*/}
            {/*                            <button*/}
            {/*                                className="btn-base-lg category-btn-lg font-weight-normal"*/}
            {/*                                onClick={goToVenueComplianceReport}*/}
            {/*                            >*/}
            {/*                                Venue Report*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

export default PrfReports;
