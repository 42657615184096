import {CalendarApi, ReportsApi, StaffApi, VenueApi} from "../../api/grs";

export default class GrsApiModel {
    private static instance: GrsApiModel;
    private static calendarApi: CalendarApi;
    private static reportsApi: ReportsApi;
    private static staffApi: StaffApi;
    private static venueApi: VenueApi;

    public static getInstance(): GrsApiModel {
        if (!GrsApiModel.instance) {
            GrsApiModel.instance = new GrsApiModel();
        }

        return GrsApiModel.instance;
    }

    public static getCalendarApi(): CalendarApi {
        if (!GrsApiModel.calendarApi) {
            GrsApiModel.calendarApi = new CalendarApi({
                basePath: process.env.REACT_APP_GRS_API
            });
        }

        return GrsApiModel.calendarApi;
    }

    public static getReportsApi(): ReportsApi {
        if (!GrsApiModel.reportsApi) {
            GrsApiModel.reportsApi = new ReportsApi({
                basePath: process.env.REACT_APP_GRS_API
            });
        }

        return GrsApiModel.reportsApi;
    }

    public static getStaffApi(): StaffApi {
        if (!GrsApiModel.staffApi) {
            GrsApiModel.staffApi = new StaffApi({
                basePath: process.env.REACT_APP_GRS_API
            });
        }

        return GrsApiModel.staffApi;
    }

    public static getVenueApi(): VenueApi {
        if (!GrsApiModel.venueApi) {
            GrsApiModel.venueApi = new VenueApi({
                basePath: process.env.REACT_APP_GRS_API
            });
        }

        return GrsApiModel.venueApi;
    }
}
