import React from "react";
import {YesAnswerMonthlyQuestionResult} from "../../../../../../api/prf";
import {toArray} from "../../../../../../utils/sortingUtils";

const OutcomeReportListHeader = (props: YesAnswerMonthlyQuestionResult[]) => {
    return (
        <tr className="pulse-table-header">
            <th className="p-3 pulse-table-header-item">-</th>
            {toArray(props).map((result: YesAnswerMonthlyQuestionResult, index: number) => {
                return (
                    <th className="p-3 pulse-table-header-item text-center" key={index}>
                        {result.question}
                    </th>
                );
            })}
        </tr>
    );
};

export default OutcomeReportListHeader;
