import {
    Answer,
    AuditForm,
    AuditType,
    CardiacArrestAndROSCAudit,
    CardiacChestPainAudit,
    Conveyance,
    GeneralAudit,
    GrsLocation,
    MentalHealthAndRestraintAudit,
    NotConveyedAudit,
    OutcomeQuestions,
    OutcomeType,
    PrfAudit,
    QuestionType,
    QuestionWithAnswer,
    QuestionWithAnswerAndComments,
    QuestionWithConveyance,
    RecognitionOfLifeExtinctAudit,
    SepsisAudit,
    StaffLink,
    StrokeAudit
} from "../../../api/prf";
import moment from "moment";
import {UserData} from "../../../api/staff";
import {
    CalendarEntryState,
    CalendarSummary,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    Venue
} from "../../../api/grs";
import {getBaseQuestionsForNewAudit} from "./baseAuditHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

/** Creates a blank audit */
export function createNewBlankAudit(createdBy: UserData): PrfAudit {
    const originalAuthor: StaffLink = {
        staffId: createdBy.username,
        staffName: `${createdBy.firstName} ${createdBy.lastName}`
    };

    const startOfDay = moment().startOf("day").unix();

    return {
        id: 0,
        originalAuthor,
        modifiedAuthor: originalAuthor,
        creationDate: startOfDay,
        modifiedDate: -1,
        score: 100,
        clinician: {
            staffName: "",
            staffId: ""
        },
        auditDate: startOfDay,
        auditType: AuditType.None,
        payload: JSON.stringify(generateAuditForm())
    };
}

export function generateAuditForm(): AuditForm {
    return {
        baseQuestions: getBaseQuestionsForNewAudit(),
        outcome: OutcomeType.None,
        outcomeData: generateOutcomeQuestions(),
        sendFeedback: false
    };
}

/** Outcome questions */
export function generateOutcomeQuestions(): OutcomeQuestions {
    return {
        //Conveyed
        generalDistrictHospital: newQuestionWithAnswer(
            0,
            "General District Hospital",
            QuestionType.Outcome
        ),
        preAlert: newQuestionWithAnswer(0, "Pre-alert", QuestionType.Outcome),
        ppci: newQuestionWithAnswer(0, "PPCI", QuestionType.Outcome),
        hac: newQuestionWithAnswer(0, "HAC", QuestionType.Outcome),
        arrhythmiaCentre: newQuestionWithAnswer(0, "Arrhythmia Centre", QuestionType.Outcome),
        hasu: newQuestionWithAnswer(0, "HASU", QuestionType.Outcome),
        mtc: newQuestionWithAnswer(0, "MTC", QuestionType.Outcome),
        // Not conveyed
        discharged: newQuestionWithAnswer(0, "Discharged", QuestionType.Outcome),
        referredToGp: newQuestionWithAnswer(0, "Referred to GP", QuestionType.Outcome),
        referredToEolc: newQuestionWithAnswer(0, "Referred to EoLC", QuestionType.Outcome),
        referredToMh: newQuestionWithAnswer(0, "Referred to MH", QuestionType.Outcome),
        referredToHcp: newQuestionWithAnswer(0, "Referred to HCP", QuestionType.Outcome),
        madeOwnWay: newQuestionWithAnswer(0, "Made own way", QuestionType.Outcome),
        taxiConveyance: newQuestionWithAnswer(0, "Taxi conveyance", QuestionType.Outcome),
        refused: newQuestionWithAnswer(0, "Refused", QuestionType.Outcome),
        role: newQuestionWithAnswer(0, "ROLE", QuestionType.Outcome),
        noTrace: newQuestionWithAnswer(0, "No trace", QuestionType.Outcome),
        conveyedByNhs: newQuestionWithAnswer(0, "Conveyed by NHS", QuestionType.Outcome),
        //Patient referral
        safeguardingReferral: newQuestionWithAnswerAndComments(
            "Safeguarding referral",
            QuestionType.Outcome
        ),
        fallsReferral: newQuestionWithAnswerAndComments("Falls referral", QuestionType.Outcome),
        diabeticReferral: newQuestionWithAnswerAndComments(
            "Diabetic referral",
            QuestionType.Outcome
        )
    };
}

/** Generates a basic question with answer */
export function newQuestionWithAnswer(
    scoreChange: number,
    question: string,
    questionType: QuestionType
): QuestionWithAnswer {
    return {
        question,
        questionType,
        answer: Answer.NotApplicable,
        scoreChange
    };
}

/** Generates a basic question with conveyance */
export function newQuestionWithConveyance(
    scoreChange: number,
    question: string
): QuestionWithConveyance {
    return {
        scoreChange,
        questionType: QuestionType.Normal,
        question,
        conveyance: Conveyance.NotApplicable
    };
}

export function newQuestionWithAnswerAndComments(
    question: string,
    questionType: QuestionType
): QuestionWithAnswerAndComments {
    return {
        question,
        questionType,
        scoreChange: 0,
        comments: "",
        answer: Answer.NotApplicable
    };
}

/** Calculates score for audit */
export function calculateScoreForAuditForm(auditForm: AuditForm, auditType: AuditType): number {
    const selectedAudit = getSelectedAudit(auditForm, auditType);

    //The selected audit was undefined.
    if (!selectedAudit) return -1;

    const valueToSubtractForBaseQuestions = getScoreForQuestions(auditForm.baseQuestions);
    const valueToSubtractForSelectedAudit = getScoreForQuestions(selectedAudit);

    const score = 100 - (valueToSubtractForBaseQuestions + valueToSubtractForSelectedAudit);

    // Check if the score goes below 0, if so, we floor it to 0
    return score > -1 ? score : 0;
}

/** Gets the audit that has been completed based on the type. */
export function getSelectedAudit(
    auditForm: AuditForm,
    auditType: AuditType
):
    | GeneralAudit
    | NotConveyedAudit
    | CardiacArrestAndROSCAudit
    | CardiacChestPainAudit
    | StrokeAudit
    | SepsisAudit
    | RecognitionOfLifeExtinctAudit
    | MentalHealthAndRestraintAudit
    | undefined {
    const {
        generalAudit,
        strokeAudit,
        sepsisAudit,
        notConveyedAudit,
        cardiacChestPainAudit,
        cardiacArrestAndROSCAudit,
        mentalHealthAndRestraintAudit,
        recognitionOfLifeExtinctAudit
    } = auditForm;
    switch (auditType) {
        case AuditType.Stroke:
            return strokeAudit;
        case AuditType.CardiacArrestAndRosc:
            return cardiacArrestAndROSCAudit;
        case AuditType.CardiacChestPain:
            return cardiacChestPainAudit;
        case AuditType.General:
            return generalAudit;
        case AuditType.Sepsis:
            return sepsisAudit;
        case AuditType.MentalHealthAndOrRestraint:
            return mentalHealthAndRestraintAudit;
        case AuditType.NotConveyed:
            return notConveyedAudit;
        case AuditType.RecognitionOfLifeExtinct:
            return recognitionOfLifeExtinctAudit;
        default:
            return undefined;
    }
}

/** Scans through the questions and will return the amount the total score needs to be subtracted by */
export function getScoreForQuestions<T>(audit: T): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keys = Object.keys(audit);
    let score = 0;
    for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const question: QuestionWithAnswer = audit[key];

        // If the question is undefined, we want to loop over it.
        if (!question) {
            continue;
        }
        // Conveyance questions don't affect the score, so if we detect one in the questions, we just loop over it.
        if ("conveyance" in question) {
            continue;
        }

        // Get the increment value of the score change if the answer is no, otherwise, we don't increment the score.
        const inc = question.answer === Answer.No ? question.scoreChange : 0;

        score += inc;
    }

    return score;
}

/** Converts information in dropdown to GrsLocation */
export function convertLocationDetailsToGrsLocation(
    entryId: number,
    entries: EventCalendarEntry[] | FrontlineCalendarEntry[],
    venues: Venue[],
    calendars: CalendarSummary[]
): GrsLocation | undefined {
    if (entries.length === 0) return undefined;
    const entry = getEntryFromEntryList(entryId, entries);
    return {
        entryId: entry.id,
        entryName: entry.description ? entry.description : "New Calendar Entry",
        venueName: getVenueForCalendarEntry(entry, venues).name,
        venueId: getVenueForCalendarEntry(entry, venues).id,
        calendarType: getCalendarForCalendarEntry(entry, calendars).calendarType,
        calendarId: getCalendarForCalendarEntry(entry, calendars).id,
        calendarName: getCalendarForCalendarEntry(entry, calendars).name
    };
}

/** Finds entry in list */
function getEntryFromEntryList(
    entryId: number,
    entries: EventCalendarEntry[] | FrontlineCalendarEntry[]
): EventCalendarEntry | FrontlineCalendarEntry {
    const deletedEntry: EventCalendarEntry | FrontlineCalendarEntry = {
        actualEndDate: 0,
        actualStartDate: 0,
        description: "",
        venueId: 0,
        calendarId: 0,
        calendarType: CalendarTypeEnum.Event,
        endDate: 0,
        id: 0,
        version: 0,
        state: CalendarEntryState.Active,
        startDate: 0,
        requiredStaff: {
            sections: []
        }
    };

    const entry = entries.find(
        (el: EventCalendarEntry | FrontlineCalendarEntry) => el.id === entryId
    );

    return entry ? entry : deletedEntry;
}

/** gets the associated venue for the entry */
function getVenueForCalendarEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[]
): Venue {
    const deletedVenue: Venue = {
        name: "Deleted Venue",
        id: 0,
        calendarId: 0,
        postcode: "",
        address: "",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: "Active"
    };

    const venue = venues.find((el: Venue) => el.id === entry.venueId);

    return venue ? venue : deletedVenue;
}

/** Gets the associated calendar for the Calendar Entry */
function getCalendarForCalendarEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    calendars: CalendarSummary[]
): CalendarSummary {
    const deletedCalendar: CalendarSummary = {
        calendarType: CalendarTypeEnum.Event,
        version: 0,
        id: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        billingType: "Daily",
        bgImageUrl: "",
        name: "Deleted Calendar"
    };

    const calendar = calendars.find((el: CalendarSummary) => el.id === entry.calendarId);

    return calendar ? calendar : deletedCalendar;
}

/** Validates the audit */
export function validateAudit(prfAudit: PrfAudit): boolean {
    const auditForm: AuditForm = JSON.parse(prfAudit.payload);

    if (prfAudit.auditType === AuditType.None) {
        showErrorToast("Audit type must be selected.");
        return false;
    }

    if (!prfAudit.grsLocation) {
        showErrorToast("A location must be selected.");
        return false;
    }

    if (auditForm.outcome === OutcomeType.None) {
        showErrorToast("Outcome must be selected.");
        return false;
    }

    if (auditForm.sendFeedback && !auditForm.comments) {
        showErrorToast("Comments cannot be blank when sending feedback.");
        return false;
    }

    if (auditForm.sendFeedback && auditForm.comments && auditForm.comments.length === 0) {
        showErrorToast("Comments cannot be blank when sending feedback.");
        return false;
    }

    if (prfAudit.clinician.staffId.length === 0) {
        showErrorToast("A clinician must be selected.");
        return false;
    }

    return true;
}
