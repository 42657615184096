import React from "react";
import {FailQueryResponse} from "../../../../../../api/prf";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";
import {pagedRequestConfig} from "../../../../../Filters/helpers/filterHelpers";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import Tooltip from "../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../Icon/Icon";
import {PulseTableWithLocalPagination} from "pulse_table";

const FailedQuestionTable = (props: FailQueryResponse) => {
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    /** Allows for most recent version of an audit to be edited */
    const editAudit = (auditId: number) => {
        history.push({
            pathname: `${routeNames.editAudit.path}/${auditId}`,
            search: searchQueries
        });
    };

    return (
        <React.Fragment>
            <div className="mt-5">
                <PulseTableWithLocalPagination
                    headers={{
                        clinician: "Clinician",
                        auditDate: "Audit Date",
                        auditType: "Audit Type",
                        sectionName: "Section",
                        question: "Question",
                        auditId: "Actions"
                    }}
                    items={getQuestionsForTable(props)}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no items matching the filters!
                                </h5>
                            </div>
                        </div>
                    }
                    customRenderers={{
                        auditId: (item: FailedQuestionRow) => (
                            <Tooltip tooltipText={"Edit this audit"} size={"md"} place={"left"}>
                                <Icon
                                    onClick={() => editAudit(item.auditId)}
                                    rootElement={"Span"}
                                    icon={IconType.Edit}
                                    size={"Medium"}
                                    className="cursor-pointer"
                                />
                            </Tooltip>
                        )
                    }}
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                />
            </div>
        </React.Fragment>
    );
};

export default FailedQuestionTable;

interface FailedQuestionRow {
    clinician: string;
    auditDate: string;
    auditType: string;
    sectionName: string;
    question: string;
    auditId: number;
}

function getQuestionsForTable(resp: FailQueryResponse): FailedQuestionRow[] {
    const rows: FailedQuestionRow[] = [];

    for (const entry of resp.results) {
        for (const question of entry.failQuestions) {
            rows.push({
                clinician: entry.clinician.staffName,
                auditDate: formatUnixToDDMMYYYY(entry.auditDate),
                auditType: getUiFriendlyText(entry.auditType),
                sectionName: getUiFriendlyText(question.sectionName),
                question: question.questionName,
                auditId: entry.auditId
            });
        }
    }

    return rows;
}
