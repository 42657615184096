import React, {useEffect} from "react";
import {useToggle} from "../../../../../Hooks/useToggle";
import {nanoid} from "nanoid";
import Tooltip from "../../../../../Tooltip/Tooltip";

const DateSwitcher = (props: DateSwitcherProps) => {
    const [toggled, setToggled] = useToggle(props.showMonth);
    const buttonId = nanoid();

    useEffect(() => {
        props.onChange(toggled);
    }, [toggled]);
    return (
        <React.Fragment>
            <Tooltip
                tooltipText={toggled ? "Month View" : "Year View"}
                size={"md"}
                rootElement={"span"}
                place={"left"}
            >
                <button
                    data-tip
                    data-for={buttonId}
                    onClick={setToggled}
                    className="date-switcher-button font-weight-bolder"
                >
                    {toggled ? "M" : "Y"}
                </button>
            </Tooltip>
        </React.Fragment>
    );
};

export default DateSwitcher;

interface DateSwitcherProps {
    showMonth: boolean;
    onChange: (value: boolean) => void;
}
