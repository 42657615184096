import {Dispatch} from "redux";
import {
    CALENDAR_SUMMARY_LIST_STORE,
    CalendarSummaryListDispatchType
} from "./CalendarSummaryListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Resets the state of the store. */
export const nullifyCalendarSummaryListStore = () => {
    return async (dispatch: Dispatch<CalendarSummaryListDispatchType>) => {
        dispatch({
            type: CALENDAR_SUMMARY_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets the list of calendars within the GRS */
export const getCalendarSummaryList = () => {
    return async (dispatch: Dispatch<CalendarSummaryListDispatchType>) => {
        try {
            const calendars = await getDataFromServiceWithData(
                CALENDAR_SUMMARY_LIST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarSummary(),
                statusCodeCallback
            );
            dispatch({
                type: CALENDAR_SUMMARY_LIST_STORE.SUCCESS,
                error: null,
                loading: false,
                data: calendars
            });
            return calendars || [];
        } catch (e: any) {
            dispatch({
                type: CALENDAR_SUMMARY_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
