import React from "react";
import {getFlooredPercentage} from "../../../utils/mathUtils";
import ReactTooltip from "react-tooltip";

const HorizontalBarChart = ({
    currentValue,
    barColour,
    maxValue,
    tooltipText,
    uid
}: BarChartProps) => {
    const getBarWidth = () => {
        return getFlooredPercentage(currentValue, maxValue);
    };
    return (
        <React.Fragment>
            <div className="horizontal-bar-axis row ml-0 mr-0">
                <div className="horizontal-bar-base">
                    <div
                        data-tip
                        data-for={uid}
                        className="horizontal-bar-value cursor-pointer"
                        style={{width: `${getBarWidth()}%`, background: barColour}}
                    >
                        <p
                            className={`horizontal-bar-axis-label header-font ${
                                isValueZero(currentValue) ? "" : "text-center"
                            }`}
                        >
                            {currentValue}%
                        </p>
                    </div>
                    <ReactTooltip
                        place="top"
                        id={uid}
                        type="dark"
                        effect="solid"
                        className="fadeIn tooltip-override-lg"
                    >
                        <React.Fragment>
                            <p>{tooltipText}</p>
                        </React.Fragment>
                    </ReactTooltip>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HorizontalBarChart;

interface BarChartProps {
    uid: string;
    maxValue: number;
    currentValue: number;
    barColour: string;
    tooltipText: string;
}

function isValueZero(value: number): boolean {
    return +value.toFixed(0) === 0;
}
