import React, {useEffect, useState} from "react";
import CalendarDropdown from "../../../../../Dropdown/Components/CalendarDropdown";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import DatePicker from "react-datepicker";
import {AuditType, ReportRequest, StaffLink} from "../../../../../../api/prf";
import {RequestFilterProps} from "../../../../../Filters/helpers/filterHelpers";
import moment, {Moment} from "moment";
import {CalendarSummary, CalendarTypeEnum, Venue} from "../../../../../../api/grs";
import {capitalizeFirstLetter} from "../../../../../../utils/textUtils";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../../Hooks/useQuery";
import StaffDropdown from "../../../../../Dropdown/Components/StaffDropdown";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";
import VenueDropdown from "../../../../../Dropdown/Components/VenueDropdown";
import DateSwitcher from "./DateSwitcher";
import {convertStringToBool} from "../../../../../../utils/boolUtils";

const ReportFilters = (props: ReportRequestFilterProps<ReportRequest>) => {
    const history = useHistory();
    const query = useQuery();
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [showMonth, setShowMonth] = useState<boolean>();
    const [calendar, setCalendar] = useState<CalendarSummary>({
        version: 0,
        id: 0,
        name: "",
        calendarType: CalendarTypeEnum.Event,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        billingType: "Daily",
        bgImageUrl: ""
    });
    const [auditType, setAuditType] = useState<AuditType>(
        props.reduceAuditTypeFilters ? AuditType.General : AuditType.All
    );
    const [staffMember, setStaffMember] = useState<StaffLink>({staffId: "", staffName: ""});
    const [venue, setVenue] = useState<Venue>({
        id: 0,
        name: "",
        calendarId: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: "Active",
        address: "",
        postcode: ""
    });

    /** Upon mounting, check for the query strings*/
    useEffect(() => {
        const auditFilter = query.get("auditType");
        const calendarFilter = query.get("calendarId");
        const staffFilter = query.get("staffId");
        const startDateFilter = query.get("dateRangeStart");
        const isMonthShown = query.get("showMonth");

        if (auditFilter) {
            setAuditType(getAuditTypeFromString(capitalizeFirstLetter(auditFilter)));
        }
        if (calendarFilter) {
            setCalendar({
                ...calendar,
                id: +calendarFilter
            });
        }
        if (staffFilter) {
            setStaffMember({
                ...staffMember,
                staffId: staffFilter
            });
        }
        if (startDateFilter) {
            setStartDate(moment.unix(+startDateFilter));
        }

        setShowMonth(isMonthShown ? convertStringToBool(isMonthShown) : true);
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Updates the staff member when it is changed in the dropdown */
    const onStaffMemberChanged = (staff: StaffLink) => {
        setStaffMember(staff);
    };

    /** Updates the calendar when it is changed in the dropdown */
    const onCalendarChanged = (calendarSummary: CalendarSummary) => {
        setCalendar(calendarSummary);
    };

    /** Updates the audit type when it is changed in the dropdown */
    const onAuditTypeChanged = (value: string | number) => {
        setAuditType(getAuditTypeFromString(value.toString()));
    };

    /** Triggered when the venue is changed */
    const onVenueChange = (newVenue: Venue) => {
        setVenue(newVenue);
    };

    /** Triggered when date has been switched */
    const onDateSwitched = (value: boolean) => {
        setShowMonth(value);
    };
    // Anytime there is a change, we want to create a new request
    useEffect(() => {
        if (showMonth === undefined) return;
        const request = buildRequest(auditType, startDate, calendar, staffMember, venue, showMonth);
        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [auditType, startDate, calendar, staffMember, venue, showMonth]);

    const buildRequest = (
        incomingAuditType: AuditType,
        incomingStartDate: Moment,
        incomingCalendar: CalendarSummary,
        incomingStaffMember: StaffLink,
        incomingVenue: Venue,
        shouldShowMonth: boolean
    ): ReportRequest => {
        const unitOfTime = shouldShowMonth ? "month" : "year";
        const auditTypes = incomingAuditType === AuditType.All ? [] : [incomingAuditType];
        return {
            date: {
                startDate: incomingStartDate.clone().startOf(unitOfTime).unix(),
                endDate: incomingStartDate.clone().endOf(unitOfTime).unix()
            },
            auditTypes,
            calendarId: incomingCalendar.id > 0 ? incomingCalendar.id : undefined,
            staffId:
                incomingStaffMember.staffId.length > 0 ? incomingStaffMember.staffId : undefined,
            venueId: incomingVenue.id > 0 ? incomingVenue.id : undefined
        };
    };
    const buildUrlHistory = (request: ReportRequest) => {
        const queryStrings: string[] = [];

        if (request.auditTypes.length > 0) {
            queryStrings.push(`auditType=${request.auditTypes[0]}`);
        }

        if (request.calendarId) {
            queryStrings.push(`calendarId=${request.calendarId}`);
        }

        if (request.venueId) {
            queryStrings.push(`venueId=${request.venueId}`);
        }

        if (request.staffId) {
            queryStrings.push(`staffId=${request.staffId}`);
        }

        if (showMonth !== undefined) {
            queryStrings.push(`showMonth=${showMonth}`);
        }

        queryStrings.push(`dateRangeStart=${request.date.startDate}`);
        queryStrings.push(`dateRangeEnd=${request.date.endDate}`);

        history.push({search: `?${queryStrings.join("&")}`});
    };
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Audit Type</h6>
                    <GenericTypeDropdown
                        enumOptions={
                            props.reduceAuditTypeFilters
                                ? ReducedAuditTypeFilters
                                : AllAuditTypeFilters
                        }
                        splitByCapitalLetter={true}
                        clearable={false}
                        searchable={false}
                        changeOption={onAuditTypeChanged}
                        id={auditType}
                        disabled={false}
                    />
                </div>
                <div className="filter-item">
                    <h6>Date</h6>
                    <div className="filter-item-inner-wrapper">
                        <div className="filter-item-inner">
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker={showMonth}
                                showYearPicker={!showMonth}
                                dateFormat={!showMonth ? "yyyy" : "MMMM yyyy"}
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        </div>
                        <div className="filter-item-inner">
                            {showMonth !== undefined && (
                                <DateSwitcher showMonth={showMonth} onChange={onDateSwitched} />
                            )}
                        </div>
                    </div>
                </div>
                {props.showCalendarFilter && (
                    <div className="filter-item">
                        <h6>Calendar</h6>
                        <CalendarDropdown
                            changeOption={onCalendarChanged}
                            id={calendar.id}
                            clearable={true}
                        />
                    </div>
                )}
                {props.showStaffFilter && (
                    <div className="filter-item">
                        <h6>Staff Member</h6>
                        <StaffDropdown
                            searchable={true}
                            changeOption={onStaffMemberChanged}
                            clearable={true}
                            id={staffMember.staffId}
                            disabled={false}
                        />
                    </div>
                )}
                {props.showVenueFilter && (
                    <div className="filter-item">
                        <h6>Venue</h6>
                        <VenueDropdown
                            calendarId={calendar.id}
                            initialVenue={venue}
                            changeOption={onVenueChange}
                            clearable={true}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ReportFilters;

export interface ReportRequestFilterProps<T> extends RequestFilterProps<T> {
    showCalendarFilter: boolean;
    showStaffFilter: boolean;
    showVenueFilter: boolean;
    reduceAuditTypeFilters: boolean;
}

// eslint-disable-next-line no-shadow
export enum AllAuditTypeFilters {
    All = "all",
    General = "general",
    NotConveyed = "notConveyed",
    CardiacChestPain = "cardiacChestPain",
    Sepsis = "sepsis",
    Stroke = "stroke",
    CardiacArrestAndRosc = "cardiacArrestAndRosc",
    RecognitionOfLifeExtinct = "recognitionOfLifeExtinct",
    MentalHealthAndOrRestraint = "mentalHealthAndOrRestraint"
}

// eslint-disable-next-line no-shadow
export enum ReducedAuditTypeFilters {
    General = "general",
    NotConveyed = "notConveyed",
    CardiacChestPain = "cardiacChestPain",
    Sepsis = "sepsis",
    Stroke = "stroke",
    CardiacArrestAndRosc = "cardiacArrestAndRosc",
    RecognitionOfLifeExtinct = "recognitionOfLifeExtinct",
    MentalHealthAndOrRestraint = "mentalHealthAndOrRestraint"
}

/** Gets the value of the audit type from the string */
export function getAuditTypeFromString(value: string): AuditType {
    return AuditType[value as keyof typeof AuditType];
}
