import {Dispatch} from "redux";
import {
    FAILED_QUESTIONS_STORE,
    FailedQuestionsReportDispatchTypes
} from "./FailedQuestionsReportActionTypes";
import {FailQueryRequest} from "../../../../api/prf";
import PrfApiModel from "../../../apiModel/PrfApiModel";
import {AUDIT_TYPE_REPORT_STORE} from "../../auditType/actions/AuditTypeReportActionTypes";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyAuditTypeReportStore = () => {
    return async (dispatch: Dispatch<FailedQuestionsReportDispatchTypes>) => {
        dispatch({
            type: FAILED_QUESTIONS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets failed questions reports */
export const fetchFailedQuestions = (request: FailQueryRequest) => {
    return async (dispatch: Dispatch<FailedQuestionsReportDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                FAILED_QUESTIONS_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().queryAuditsWithFails(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_TYPE_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
