import {Answer, Conveyance, MentalHealthAndRestraintAudit, QuestionType} from "../../../api/prf";
import {newQuestionWithAnswer, newQuestionWithConveyance} from "./auditHelpers";
import {
    generateAppropriateDestination,
    generateAssessmentDocumented,
    generateCalContacted,
    generateHandoverTimes,
    generateNews2ScoreRecorded,
    generatePathfinderOutcome,
    generatePatientOutcomeReasonable,
    generateWorseningCareAdviceDocumented
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getMentalHealthAndRestraintQuestionsForNewAudit(): MentalHealthAndRestraintAudit {
    return {
        ...generateMentalHealthAndRestraintAudit(),
        assessmentDocumented: undefined,
        restraintNecessary: undefined,
        restraintInProportionOrBestInterests: undefined,
        appropriateDestination: undefined,
        handoverTimes: undefined,
        patientOutcomeReasonable: undefined,
        pathFinderOutcome: undefined,
        calContacted: undefined,
        news2ScoreRecorded: undefined,
        worseningCareAdviceDocumented: undefined
    };
}

/** Mental Health and/or Restraint Audit */
export function generateMentalHealthAndRestraintAudit(): MentalHealthAndRestraintAudit {
    return {
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        observationRecorded: newQuestionWithAnswer(1, "Observation recorded?", QuestionType.Normal),
        bmRecorded: newQuestionWithAnswer(1, "BM recorded?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        currentMhCareFacilityRecorded: newQuestionWithAnswer(
            1,
            "Current MH care facility recorded?",
            QuestionType.Normal
        ),
        psychiatricHistoryRecorded: newQuestionWithAnswer(
            2,
            "Psychiatric history recorded?",
            QuestionType.Normal
        ),
        mhRiskAssessmentUndertaken: newQuestionWithAnswer(
            4,
            "MH risk assessment undertaken?",
            QuestionType.Normal
        ),
        suicideRiskAssessmentUndertaken: newQuestionWithAnswer(
            4,
            "Suicide risk assessment undertaken?",
            QuestionType.Normal
        ),
        patientsBehaviourDocumented: newQuestionWithAnswer(
            2,
            "Patient's behaviour documented?",
            QuestionType.Normal
        ),
        needForRestraint: newQuestionWithAnswer(0, "Need for restraint?", QuestionType.Normal),
        restraintInProportionOrBestInterests: generateRestraintProportionate(),
        restraintNecessary: generateRestraintNecessary(),
        destination: newQuestionWithConveyance(0, "Destination?"),
        appropriateDestination: generateAppropriateDestination(),
        handoverTimes: generateHandoverTimes(),
        patientOutcomeReasonable: generatePatientOutcomeReasonable(),
        pathFinderOutcome: generatePathfinderOutcome(),
        calContacted: generateCalContacted(),
        news2ScoreRecorded: generateNews2ScoreRecorded(),
        worseningCareAdviceDocumented: generateWorseningCareAdviceDocumented(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}

// Updates the questions in the Mental health and restraint audit based on conveyance
export function updateMentalHealthAuditBasedOnConveyance(
    conveyance: Conveyance,
    audit: MentalHealthAndRestraintAudit
): MentalHealthAndRestraintAudit {
    switch (conveyance) {
        case Conveyance.Conveyed:
            return {
                ...audit,
                appropriateDestination: generateAppropriateDestination(),
                handoverTimes: generateHandoverTimes(),
                patientOutcomeReasonable: undefined,
                pathFinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceDocumented: undefined
            };
        case Conveyance.NotConveyed:
        case Conveyance.Referred:
        case Conveyance.Refused:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: generatePatientOutcomeReasonable(),
                pathFinderOutcome: generatePathfinderOutcome(),
                calContacted: generateCalContacted(),
                news2ScoreRecorded: generateNews2ScoreRecorded(),
                worseningCareAdviceDocumented: generateWorseningCareAdviceDocumented()
            };
        case Conveyance.RecognitionOfLifeExtinct:
        case Conveyance.NotApplicable:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: undefined,
                pathFinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: MentalHealthAndRestraintAudit
): MentalHealthAndRestraintAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateNeedForRestraintQuestionBasedOnAnswer(
    answer: Answer,
    audit: MentalHealthAndRestraintAudit
): MentalHealthAndRestraintAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                restraintInProportionOrBestInterests: generateRestraintProportionate(),
                restraintNecessary: generateRestraintNecessary()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                restraintInProportionOrBestInterests: undefined,
                restraintNecessary: undefined
            };
        default:
            return audit;
    }
}

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

// Generates the restraint proportionate question
function generateRestraintProportionate() {
    return newQuestionWithAnswer(
        6,
        "Restraint proportionate/best interests?",
        QuestionType.Quality
    );
}

// Generates the restraint necessary question
function generateRestraintNecessary() {
    return newQuestionWithAnswer(6, "Restraint necessary?", QuestionType.Quality);
}
