import React from "react";
import {getMonthsForYear} from "../../../../Components/Filters/Helpers/filterHelpers";

const QualityReportYearViewListHeader = () => (
    <tr className="pulse-table-header">
        <th className="p-3 pulse-table-header-item">-</th>
        {getMonthsForYear().map((month: string, index: number) => {
            return (
                <th className="p-3 pulse-table-header-item text-center" key={index}>
                    {month}
                </th>
            );
        })}
    </tr>
);

export default QualityReportYearViewListHeader;
