import {
    YesAnswerMonthlyAuditTypeResult,
    YesAnswerMonthlyQuestionResult,
    YesAnswerMonthlyReportResult
} from "../../../../../../api/prf";
import {
    getMonthNumber,
    getMonthsForYear,
    ReportDateView
} from "../../../Components/Filters/Helpers/filterHelpers";

//Function that adds up all outcome scores when the filter is set to 'All'
export function addAllOutcomeResultsTogether(
    response: YesAnswerMonthlyReportResult
): YesAnswerMonthlyQuestionResult[] {
    //If there are no results, return an empty array
    if (response.auditTypeResults.length === 0) return [];
    //If the auditTypes search is more than 1 (general etc) and there is a list of questions, we want to return this here
    if (response.auditTypes.length > 0 && response.auditTypeResults.length > 0) {
        return response.auditTypeResults[0].questions;
    }

    /** Can guarantee data here because of the if statements above */
    const questions = getQuestionsFromOutcomesReport(response.auditTypeResults[0]);

    for (const auditTypeResults of response.auditTypeResults) {
        for (let i = 0; i < auditTypeResults.questions.length; ++i) {
            const question = auditTypeResults.questions[i];

            for (let j = 0; j < question.monthCounts.length; ++j) {
                const count = question.monthCounts[j];

                questions[i].monthCounts[j] += count;
            }
        }
    }

    return questions;
}

/** Sorts it so the months are long the left as opposed to the top
 * if the filters need to show a month, it will return the month only */
export function sortOutcomeReportResultRowsToVertical(
    questionResults: YesAnswerMonthlyQuestionResult[],
    reportDateView: ReportDateView
): OutcomeResults[] {
    const results: OutcomeResults[] = [];
    const months = getMonthsForYear();

    for (let i = 0; i < months.length; ++i) {
        const result: OutcomeResults = {
            month: months[i],
            results: []
        };

        // For each of the results, we want to push the value at the index of the month,
        // Month counts = 12
        for (const res of questionResults) {
            result.results.push(res.monthCounts[i]);
        }

        results.push(result);
    }

    if (reportDateView.showMonth) {
        const monthNumber = getMonthNumber(reportDateView);

        return [results[monthNumber]];
    }
    return results;
}

export interface OutcomeResults {
    month: string;
    results: number[];
}

/** Gets the questions a list of questions with blank month scores */
function getQuestionsFromOutcomesReport(
    auditTypeResult: YesAnswerMonthlyAuditTypeResult
): YesAnswerMonthlyQuestionResult[] {
    const questions: YesAnswerMonthlyQuestionResult[] = [];
    for (const result of auditTypeResult.questions) {
        questions.push({
            ...result,
            monthCounts: getBlankMonthScoreArray()
        });
    }

    return questions;
}

/** Creates 12 blank entries */
function getBlankMonthScoreArray(): number[] {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
}
