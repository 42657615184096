import {YesAnswerMonthlyReportResult} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const OUTCOME_REPORT_STORE = createStoreState("outcome_report");

interface OutcomeReportLoading extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof OUTCOME_REPORT_STORE.LOADING;
}
interface OutcomeReportError extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof OUTCOME_REPORT_STORE.ERROR;
}
interface OutcomeReportSuccess extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof OUTCOME_REPORT_STORE.SUCCESS;
}

export type OutcomeReportDispatchTypes =
    | OutcomeReportLoading
    | OutcomeReportError
    | OutcomeReportSuccess;
