import React from "react";
import {OutcomeQuestions} from "../../../../../../../api/prf";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyPatientReferralOutcome = (props: OutcomeQuestions) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer {...props.safeguardingReferral} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.fallsReferral} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.diabeticReferral} isOptionalQuestion={false} />
        </React.Fragment>
    );
};

export default ReadOnlyPatientReferralOutcome;
