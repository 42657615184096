import React from "react";
import {Answer, OutcomeQuestions, QuestionWithAnswer} from "../../../../../../../api/prf";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import {useDispatch} from "react-redux";
import {setAuditFormOutcomeQuestions} from "../../../../../../../store/audit/actions/AuditActions";

const ConveyedOutcome = (props: OutcomeQuestions) => {
    const dispatch = useDispatch();

    /** Updates General District Hospital */
    const updateGeneralDistrictHospital = (answer: Answer) => {
        const generalDistrictHospital: QuestionWithAnswer = {
            ...props.generalDistrictHospital,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, generalDistrictHospital}));
    };

    /** Updates pre Alert */
    const updatePreAlert = (answer: Answer) => {
        const preAlert: QuestionWithAnswer = {
            ...props.preAlert,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, preAlert}));
    };

    /** Updates ppci */
    const updatePpci = (answer: Answer) => {
        const ppci: QuestionWithAnswer = {
            ...props.ppci,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, ppci}));
    };

    /** Updates hac */
    const updateHac = (answer: Answer) => {
        const hac: QuestionWithAnswer = {
            ...props.hac,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, hac}));
    };

    /** Updates arrhythmia Centre */
    const updateArrhythmiaCentre = (answer: Answer) => {
        const arrhythmiaCentre: QuestionWithAnswer = {
            ...props.arrhythmiaCentre,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, arrhythmiaCentre}));
    };

    /** Updates hasu */
    const updateHasu = (answer: Answer) => {
        const hasu: QuestionWithAnswer = {
            ...props.hasu,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, hasu}));
    };

    /** Updates mtc */
    const updateMtc = (answer: Answer) => {
        const mtc: QuestionWithAnswer = {
            ...props.mtc,
            answer
        };

        dispatch(setAuditFormOutcomeQuestions({...props, mtc}));
    };

    return (
        <React.Fragment>
            <QuestionAndAnswer
                {...props.generalDistrictHospital}
                isOptionalQuestion={false}
                onChange={updateGeneralDistrictHospital}
            />
            <QuestionAndAnswer
                {...props.preAlert}
                isOptionalQuestion={false}
                onChange={updatePreAlert}
            />
            <QuestionAndAnswer {...props.ppci} isOptionalQuestion={false} onChange={updatePpci} />
            <QuestionAndAnswer {...props.hac} isOptionalQuestion={false} onChange={updateHac} />
            <QuestionAndAnswer
                {...props.arrhythmiaCentre}
                isOptionalQuestion={false}
                onChange={updateArrhythmiaCentre}
            />
            <QuestionAndAnswer {...props.hasu} isOptionalQuestion={false} onChange={updateHasu} />
            <QuestionAndAnswer {...props.mtc} isOptionalQuestion={false} onChange={updateMtc} />
        </React.Fragment>
    );
};

export default ConveyedOutcome;
