import {Dispatch} from "redux";
import {CALENDAR_ENTRY_STORE_STATE, CalendarEntryDispatchTypes} from "./CalendarEntryActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Removes stale date from the store. */
export const nullifyCalendarEntryStore = () => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Get the calendar Entry by the id. */
export const getCalendarEntry = (id: number) => {
    return async (dispatch: Dispatch<CalendarEntryDispatchTypes>) => {
        try {
            const entry = await getDataFromServiceWithData(
                CALENDAR_ENTRY_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getCalendarEntryById(id),
                statusCodeCallback
            );

            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.SUCCESS,
                loading: false,
                error: null,
                data: entry
            });

            return entry;
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
