import React from "react";
import QualityReportYearViewListHeader from "./QualityReportYearViewListHeader";
import QualityReportYearViewListItem from "./QualityReportYearViewListItem";
import {
    YesAnswerMonthlyAuditTypeResult,
    YesAnswerMonthlyQuestionResult
} from "../../../../../../../api/prf";

const QualityReportYearViewTable = (props: YesAnswerMonthlyAuditTypeResult) => {
    return (
        <React.Fragment>
            <table className="pulse-table fadeIn d-block overflow-auto">
                <tbody>
                    <QualityReportYearViewListHeader />
                    {props.questions.map(
                        (result: YesAnswerMonthlyQuestionResult, index: number) => {
                            return <QualityReportYearViewListItem {...result} key={index} />;
                        }
                    )}
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default QualityReportYearViewTable;
