import React from "react";
import {OutcomeResults} from "../Helpers/outcomeReportHelpers";

const OutcomeReportListItem = (props: OutcomeResults) => {
    return (
        <tr className="pulse-table-row">
            <td className="p-3 pulse-table-row-item">{props.month}</td>
            {props.results.map((result: number, index: number) => {
                return (
                    <td className="p-3 text-center pulse-table-row-item" key={index}>
                        {result}
                    </td>
                );
            })}
        </tr>
    );
};

export default OutcomeReportListItem;
