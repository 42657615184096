import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../../../Navigation/routeNames";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {
    fetchQualityReport,
    nullifyQualityReportStore
} from "../../../../../store/reports/quality/actions/QualityReportActions";
import ReportContainer from "../../Components/ReportContainer";
import FilterContainer from "../../../../Filters/FilterContainer";
import {AuditTypeStatsRequest, ReportRequest} from "../../../../../api/prf";
import {RootStore} from "../../../../../store/Store";
import QualityReportList from "./Components/QualityReportList";
import {WithServiceState} from "store-fetch-wrappers";
import ReportFooter from "../../Components/ReportFooter";
import {
    getAllAuditTypeStats,
    getAuditTypeStats,
    nullifyAuditTypeStatsStore
} from "../../../../../store/statistics/auditType/actions/AuditTypeStatsActions";
import QualityReportStats from "./Components/Stats/QualityReportStats";
import ReportFilters from "../../Components/Filters/Components/ReportFilters";

const QualityReportWrapper = WithServiceState(QualityReportList);
const QualityReportStatsWrapper = WithServiceState(QualityReportStats);

const QualityReport = () => {
    const dispatch = useDispatch();
    const qualityReportStore = useSelector((state: RootStore) => state.qualityReport);
    const auditTypeStatsStore = useSelector((state: RootStore) => state.auditTypeStats);
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            },
            {
                name: routeNames.qualityReport.name,
                path: routeNames.qualityReport.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));

        return function () {
            dispatch(nullifyQualityReportStore());
            dispatch(nullifyAuditTypeStatsStore());
        };
    }, []);

    /** Fires when quality report request has changed */
    const onQualityReportRequestChanged = (request: ReportRequest) => {
        dispatch(fetchQualityReport(request));
        const auditTypeStatsRequest: AuditTypeStatsRequest = {
            creationDate: request.date,
            calendarId: request.calendarId
        };
        if (request.auditTypes.length === 0) {
            dispatch(getAllAuditTypeStats(auditTypeStatsRequest));
            return;
        }
        dispatch(getAuditTypeStats(request.auditTypes[0], auditTypeStatsRequest));
    };

    return (
        <React.Fragment>
            <ReportContainer>
                <FilterContainer closed={false}>
                    <ReportFilters
                        onRequestChanged={onQualityReportRequestChanged}
                        showCalendarFilter={true}
                        showStaffFilter={false}
                        showVenueFilter={false}
                        reduceAuditTypeFilters={false}
                    />
                </FilterContainer>
                <QualityReportStatsWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...auditTypeStatsStore}
                />
                <QualityReportWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    placeholderBlockCount={1}
                    {...qualityReportStore}
                />
                <ReportFooter />
            </ReportContainer>
        </React.Fragment>
    );
};

export default QualityReport;
