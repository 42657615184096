import React, {useEffect} from "react";
import {PrfAudit} from "../../../../../../api/prf";
import AuditFormParentDetails from "./AuditFormParentDetails";
// import EditAuditForm from "../Children/EditAuditForm";
// import {PrfAuditContext} from "../../../../../Context/PrfAuditContext";
import {useDispatch} from "react-redux";
import {
    nullifyAuditStore,
    saveAuditToService
} from "../../../../../../store/audit/actions/AuditActions";
import {useHistory} from "react-router-dom";
import DeleteAuditAction from "./DeleteAuditAction";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import {routeNames} from "../../../../../Navigation/routeNames";
import WarningMessage from "./WarningMessage";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import EditAuditForm from "../Children/EditAuditForm";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";

const AuditFormParent = (props: PrfAudit) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isHistoric = useIsHistoricAudit();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        return function () {
            dispatch(nullifyAuditStore());
        };
    }, []);

    /** save Audit to service */
    const saveAudit = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveAuditToService());

        if (success) backToOverview();
    };

    /** Goes back to overview page */
    const backToOverview = () => {
        history.push({
            pathname: routeNames.auditList.path,
            search: searchQueries
        });
    };

    return (
        <React.Fragment>
            <AuditFormParentDetails {...props} />
            {isHistoric && (
                <WarningMessage
                    message={
                        "NOTE: You are viewing a historic version of an audit. You will not be able, to edit this version"
                    }
                />
            )}
            <EditAuditForm {...JSON.parse(props.payload)} />
            <div className="row ml-0 mr-0 mt-3">
                {!isHistoric && (
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={saveAudit}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                )}
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToOverview}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
                {props.id > 0 && !isHistoric && (
                    <DeleteAuditAction audit={props} onSuccess={backToOverview} />
                )}
            </div>
        </React.Fragment>
    );
};

export default AuditFormParent;
