import {Dispatch} from "redux";
import {AUDIT_HISTORIC_STORE, AuditHistoricDispatchTypes} from "./AuditHistoricActionTypes";
import PrfApiModel from "../../apiModel/PrfApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyAuditHistoricStore = () => {
    return async (dispatch: Dispatch<AuditHistoricDispatchTypes>) => {
        dispatch({
            type: AUDIT_HISTORIC_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets historic information based on audit Id */
export const getAuditHistoricDataByAuditId = (auditId: number) => {
    return async (dispatch: Dispatch<AuditHistoricDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AUDIT_HISTORIC_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().listAuditHistoricVersions(auditId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_HISTORIC_STORE.ERROR,
                error: null,
                loading: false
            });
        }
    };
};
