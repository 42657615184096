import React, {useEffect} from "react";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useDispatch} from "react-redux";
import {routeNames} from "../../../Navigation/routeNames";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {useUserData} from "../../../Hooks/useUserData";

const NotFound = () => {
    const dispatch = useDispatch();
    const user = useUserData();

    useEffect(() => {
        if (user.username.length === 0) return;
        //Set Nav Items
        dispatch(setNavigationItems(getPathsForAuthPages(user)));
    }, [user]);

    return (
        <div className="page background-stars">
            <h3 className="text-center title pt-5 header-font">Error 404 - Not Found</h3>
            <div className="mt-5 text-center">
                <p className="body-font">Could not find the resource that was requested.</p>
            </div>
        </div>
    );
};

export default NotFound;

export function getPathsForAuthPages(user: UserData): NavigationItem[] {
    switch (user.accessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                }
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.overview.name,
                    path: routeNames.overview.path
                },
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                },
                {
                    name: routeNames.reports.name,
                    path: routeNames.reports.path
                }
            ];
    }
}
