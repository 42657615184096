import React from "react";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import FormActionContainer from "../../../Form/FormActionContainer";

const ReportFooter = () => {
    const history = useHistory();
    return (
        <FormActionContainer>
            <MCButton
                onClick={() => history.push(routeNames.reports.path)}
                innerValue={"Back"}
                size={ButtonSize.Large}
                colour={ButtonColourOptions.DarkBlue}
                roundedCorner
            />
        </FormActionContainer>
    );
};

export default ReportFooter;
