import {useQuery} from "./useQuery";

/** Checks to see if the audit is a historic audit */
export const useIsHistoricAudit = () => {
    const query = useQuery();

    const hid = query.get("hid");

    return !!hid;
};
