import {Dispatch} from "redux";
import {QUALITY_REPORT_STORE, QualityReportDispatchTypes} from "./QualityReportActionTypes";
import {ReportRequest} from "../../../../api/prf";
import PrfApiModel from "../../../apiModel/PrfApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyQualityReportStore = () => {
    return async (dispatch: Dispatch<QualityReportDispatchTypes>) => {
        dispatch({
            type: QUALITY_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets quality reports */
export const fetchQualityReport = (request: ReportRequest) => {
    return async (dispatch: Dispatch<QualityReportDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                QUALITY_REPORT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getQualityReport(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: QUALITY_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
