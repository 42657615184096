import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const VENUE_AUDIT_STATS_STORE = createStoreState("venue_audit_stats");

interface VenueAuditStatsLoading extends StoreServiceData<ChartData> {
    type: typeof VENUE_AUDIT_STATS_STORE.LOADING;
}

interface VenueAuditStatsError extends StoreServiceData<ChartData> {
    type: typeof VENUE_AUDIT_STATS_STORE.ERROR;
}

interface VenueAuditStatsSuccess extends StoreServiceData<ChartData> {
    type: typeof VENUE_AUDIT_STATS_STORE.SUCCESS;
}

export type VenueAuditStatsDispatchTypes =
    | VenueAuditStatsLoading
    | VenueAuditStatsError
    | VenueAuditStatsSuccess;
