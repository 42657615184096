import React, {useEffect} from "react";
import {AuditHistoryInfo, AuditHistoryOverview} from "../../../../../api/prf";
import {useDispatch} from "react-redux";
import {nullifyAuditHistoricStore} from "../../../../../store/auditHistoric/actions/AuditHistoricActions";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import {PulseTable} from "pulse_table";
import {getDotClassName} from "../../../../../store/reports/helpers/reportHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {routeNames} from "../../../../Navigation/routeNames";

const AuditTrailLogsHistoryTable = (props: AuditHistoryOverview) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    useEffect(() => {
        return function () {
            dispatch(nullifyAuditHistoricStore());
        };
    }, []);
    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props, history, searchQueries)}
                headers={{
                    modifiedBy: "Modified by",
                    date: "Date modified",
                    score: "Previous score",
                    actions: "Actions"
                }}
                customRenderers={{
                    score: (item: TableRow) => (
                        <p className="mb-0">
                            {item.score}
                            <span className={`mb-0 ml-2 ${getDotClassName(item)}`} />
                        </p>
                    ),
                    actions: (item: TableRow) => <React.Fragment>{item.actions}</React.Fragment>
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no historic audits for this audit
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default AuditTrailLogsHistoryTable;

interface TableRow {
    modifiedBy: string;
    date: string;
    score: number;
    actions: JSX.Element;
}

function toTableRow(
    historyOverview: AuditHistoryOverview,
    history: any,
    searchQueries: string
): TableRow[] {
    return historyOverview.historicEntries.map((item) => {
        return {
            modifiedBy: item.modifiedAuthor.staffName,
            date: formatUnixToDDMMYYYY(item.date),
            score: item.score,
            actions: getActionItemToRender(item, history, searchQueries)
        };
    });
}

function getActionItemToRender(
    item: AuditHistoryInfo,
    history: any,
    searchQueries: string
): JSX.Element {
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-3">
                <Tooltip tooltipText={"View historic audit"} size={"md"} place={"left"}>
                    <Icon
                        onClick={() => {
                            const pathname = `${routeNames.editAudit.path}/${item.id}`;
                            history.push({
                                pathname,
                                search: `${searchQueries}&hid=${item.hid}`
                            });
                        }}
                        rootElement={"Span"}
                        icon={IconType.Eye}
                        size={"Medium"}
                        className="cursor-pointer"
                    />
                </Tooltip>
            </div>
        </React.Fragment>
    );
}
