import React, {useEffect, useState} from "react";
import {ChartData} from "../../../utils/donutChartUtils";
import {toArray} from "../../../utils/sortingUtils";
import VerticalBarChart from "./VerticalBarChart";

/** Wrapper for Bar charts */
const VerticalBarChartListContainer = (props: ChartData[]) => {
    const [chartData, setChartData] = useState<ChartData[]>(toArray(props));

    useEffect(() => {
        setChartData(toArray(props));
    }, [props]);

    return (
        <React.Fragment>
            {chartData.length > 0 ? (
                <React.Fragment>
                    <div className="row ml-0 mr-0">
                        {chartData.map((el: ChartData, index: number) => {
                            return <VerticalBarChart {...el} key={index} />;
                        })}
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                Stats do not exist for this container
                            </h5>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default VerticalBarChartListContainer;
