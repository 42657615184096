import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ChartData} from "../../../../utils/donutChartUtils";

export const OVERALL_COMPLIANCE_STORE = createStoreState("overall_compliance");

interface OverallComplianceLoading extends StoreServiceData<ChartData> {
    type: typeof OVERALL_COMPLIANCE_STORE.LOADING;
}

interface OverallComplianceError extends StoreServiceData<ChartData> {
    type: typeof OVERALL_COMPLIANCE_STORE.ERROR;
}

interface OverallComplianceSuccess extends StoreServiceData<ChartData> {
    type: typeof OVERALL_COMPLIANCE_STORE.SUCCESS;
}

export type OverallComplianceDispatchTypes =
    | OverallComplianceLoading
    | OverallComplianceError
    | OverallComplianceSuccess;
