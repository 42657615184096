import React from "react";
import {AuditForm, OutcomeType} from "../../../../../../api/prf";
import BaseQuestions from "./BaseQuestions";
import PresentingComplaint from "./PresentingComplaint";
import RecognitionOfLifeExtinctQuestions from "./Audits/RecognitionOfLifeExtinctQuestions";
import MentalHealthAndRestraintQuestions from "./Audits/MentalHealthAndRestraintQuestions";
import CardiacArrestAndRoscQuestions from "./Audits/CardiacArrestAndRoscQuestions";
import StrokeQuestions from "./Audits/StrokeQuestions";
import GeneralQuestions from "./Audits/GeneralQuestions";
import NotConveyedQuestions from "./Audits/NotConveyedQuestions";
import SepsisQuestions from "./Audits/SepsisQuestions";
import CardiacChestPainQuestions from "./Audits/CardiacChestPainQuestions";
import Outcome from "./Outcome";
import OutcomeData from "./OutcomeData";
import AuditComments from "./AuditComments";

const EditAuditForm = (props: AuditForm) => {
    return (
        <React.Fragment>
            <BaseQuestions {...props.baseQuestions} />
            <PresentingComplaint />
            {/** General Audit */}
            {props.generalAudit && <GeneralQuestions {...props.generalAudit} />}
            {/** Not Conveyed Audit */}
            {props.notConveyedAudit && <NotConveyedQuestions {...props.notConveyedAudit} />}
            {/** Cardiac Chest Pain Audit */}
            {props.cardiacChestPainAudit && (
                <CardiacChestPainQuestions {...props.cardiacChestPainAudit} />
            )}
            {/** Sepsis Audit */}
            {props.sepsisAudit && <SepsisQuestions {...props.sepsisAudit} />}
            {/** Stroke Audit */}
            {props.strokeAudit && <StrokeQuestions {...props.strokeAudit} />}
            {/** Cardiac Arrest and Rosc Audit */}
            {props.cardiacArrestAndROSCAudit && (
                <CardiacArrestAndRoscQuestions {...props.cardiacArrestAndROSCAudit} />
            )}
            {/** Recognition of Life Extinct Audit */}
            {props.recognitionOfLifeExtinctAudit && (
                <RecognitionOfLifeExtinctQuestions {...props.recognitionOfLifeExtinctAudit} />
            )}
            {/** Mental Health and Or Restraint Audit */}
            {props.mentalHealthAndRestraintAudit && (
                <MentalHealthAndRestraintQuestions {...props.mentalHealthAndRestraintAudit} />
            )}
            <Outcome {...props} />
            {props.outcome !== OutcomeType.None && <OutcomeData {...props} />}
            <AuditComments {...props} />
        </React.Fragment>
    );
};

export default EditAuditForm;
