import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers";
import {useParams} from "react-router-dom";
import AuditFormParent from "./Components/Parent/AuditFormParent";
import {RootStore} from "../../../../store/Store";
import {useQuery} from "../../../Hooks/useQuery";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {fetchAuditByHistoricId, fetchAuditById} from "../../../../store/audit/actions/AuditActions";
const AuditFormWrapper = WithServiceState(AuditFormParent);

const EditAudit = () => {
    const dispatch = useDispatch();
    const auditStore = useSelector((state: RootStore) => state.audit);
    const params: any = useParams();
    const query = useQuery();
    useEffect(() => {
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            },
            {
                name: routeNames.reports.name,
                path: routeNames.reports.path
            }
        ];
        dispatch(setNavigationItems(navigationItems));

        const historicId = query.get("hid");

        if (!historicId) {
            dispatch(fetchAuditById(+params.id));
            return;
        }
        dispatch(fetchAuditByHistoricId(+params.id, +historicId));
    }, []);
    return (
        <div className="page-container">
            <AuditFormWrapper
                showLoadingText={true}
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                placeholderBlockCount={1}
                {...auditStore}
            />
        </div>
    );
};

export default EditAudit;
