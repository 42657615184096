import {ReportResponse} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const AUDIT_TYPE_REPORT_STORE = createStoreState("audit_type_report");

interface AuditTypeReportLoading extends StoreServiceData<ReportResponse> {
    type: typeof AUDIT_TYPE_REPORT_STORE.LOADING;
}
interface AuditTypeReportError extends StoreServiceData<ReportResponse> {
    type: typeof AUDIT_TYPE_REPORT_STORE.ERROR;
}
interface AuditTypeReportSuccess extends StoreServiceData<ReportResponse> {
    type: typeof AUDIT_TYPE_REPORT_STORE.SUCCESS;
}

export type AuditTypeReportDispatchTypes =
    | AuditTypeReportLoading
    | AuditTypeReportError
    | AuditTypeReportSuccess;
