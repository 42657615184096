import React from "react";
import {CardiacArrestAndROSCAudit} from "../../../../../../../api/prf";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyCardiacArrestAndRoscAudit = ({
    questions
}: ReadOnlyQuestions<CardiacArrestAndROSCAudit>) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer
                {...questions.historyOfPresentingComplaintOrCollapse}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer
                {...questions.documentIfWitnessed}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.timeOfBystanderCpr}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.bystanderDefibUsed}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.timeOfMedicareCrewCpr}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.medicareDefibUsed}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.initialRhythmRecorded}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.numberOfShockDelivered}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.airwayCorrectlyManaged}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.advancedAirwayPlaced}
                isOptionalQuestion={false}
            />
            {questions.endTidalCo2Recorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.endTidalCo2Recorded}
                    isOptionalQuestion={true}
                />
            )}
            {questions.endTidalCo2Printed && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.endTidalCo2Printed}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.vascularAccessGained}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.oxygenGivenAppropriately}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.medicationGiven} isOptionalQuestion={false} />
            {questions.adrenalineGivenAppropriately && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.adrenalineGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {questions.amidaroneGivenAppropriately && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.amidaroneGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {questions.sodiumChlorideGivenAppropriately && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.sodiumChlorideGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {questions.otherMedicinesGivenAppropriately && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.otherMedicinesGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer {...questions.roscAchieved} isOptionalQuestion={false} />
            {questions.leadEcgAchieved && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.leadEcgAchieved}
                    isOptionalQuestion={true}
                />
            )}
            {questions.bmRecorded && (
                <ReadOnlyQuestionAndAnswer {...questions.bmRecorded} isOptionalQuestion={true} />
            )}
            {questions.endTidalCO2Recorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.endTidalCO2Recorded}
                    isOptionalQuestion={true}
                />
            )}
            {questions.oxygenDeliveredAndTirated && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.oxygenDeliveredAndTirated}
                    isOptionalQuestion={true}
                />
            )}
            {questions.systolicBpRecorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.systolicBpRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {questions.bolusScpGiven && (
                <ReadOnlyQuestionAndAnswer {...questions.bolusScpGiven} isOptionalQuestion={true} />
            )}
            <ReadOnlyQuestionAndAnswer {...questions.destination} isOptionalQuestion={false} />
            {questions.appropriateDestination && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.appropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {questions.handoverTimes && (
                <ReadOnlyQuestionAndAnswer {...questions.handoverTimes} isOptionalQuestion={true} />
            )}
            {questions.timeVerified && (
                <ReadOnlyQuestionAndAnswer {...questions.timeVerified} isOptionalQuestion={true} />
            )}
            {questions.guidelinesFollowed && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.guidelinesFollowed}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlyCardiacArrestAndRoscAudit;
