// Converts string to boolean
export function convertStringToBool(value: string): boolean {
    if (value === "true") {
        return true;
    }
    if (value === "false") {
        return false;
    }
    return false;
}
