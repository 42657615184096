import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AuditTypeStats} from "../../../../api/prf";

export const AUDIT_TYPE_STATS_STORE = createStoreState("audit_type_stats");

interface AuditTypeStatsLoading extends StoreServiceData<AuditTypeStats> {
    type: typeof AUDIT_TYPE_STATS_STORE.LOADING;
}

interface AuditTypeStatsError extends StoreServiceData<AuditTypeStats> {
    type: typeof AUDIT_TYPE_STATS_STORE.ERROR;
}

interface AuditTypeStatsSuccess extends StoreServiceData<AuditTypeStats> {
    type: typeof AUDIT_TYPE_STATS_STORE.SUCCESS;
}

export type AuditTypeStatsDispatchTypes =
    | AuditTypeStatsLoading
    | AuditTypeStatsError
    | AuditTypeStatsSuccess;
