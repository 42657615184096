import React from "react";
import {Answer, QuestionWithAnswerAndComments} from "../../../../../../api/prf";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import {useIsHistoricAudit} from "../../../../../Hooks/useIsHistoricAudit";
import FormRowQuestion from "../../../../../Form/FormRowQuestion";
import {DebounceInput} from "react-debounce-input";

const QuestionAndAnswerAndComments = (props: QuestionWithAnswerAndCommentsWithCallback) => {
    const isHistoric = useIsHistoricAudit();
    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };
    return (
        <React.Fragment>
            <FormRowQuestion
                isOptionalQuestion={props.isOptionalQuestion}
                rowName={props.question}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <React.Fragment>
                    <div className="mb-4">
                        <GenericTypeDropdown
                            enumOptions={Answer}
                            splitByCapitalLetter={true}
                            clearable={false}
                            searchable={false}
                            changeOption={(value: number | string) => {
                                props.onChange(
                                    getAnswerFromString(value.toString()),
                                    props.comments
                                );
                            }}
                            id={props.answer}
                            disabled={isHistoric}
                        />
                    </div>

                    {props.answer === Answer.Yes && (
                        <DebounceInput
                            className="input-fields"
                            element={"textarea"}
                            style={{minHeight: `150px`}}
                            value={props.comments}
                            onChange={(evt) => {
                                props.onChange(props.answer, evt.target.value);
                            }}
                            placeholder="Enter details here..."
                            disabled={isHistoric}
                        />
                    )}
                </React.Fragment>
            </FormRowQuestion>
            {/*<div*/}
            {/*    className={`question-row ${*/}
            {/*        props.isOptionalQuestion ? "question-row-optional" : ""*/}
            {/*    }`}*/}
            {/*>*/}
            {/*    <div className="row ml-0 mr-0">*/}
            {/*        <div className={`col pt-2 pb-2`}>*/}
            {/*            <p className="mt-2 mb-0">{props.question}</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="row ml-0 mr-0 mb-4">*/}
            {/*        <div className="col">*/}
            {/*            <GenericTypeDropdown*/}
            {/*                enumOptions={Answer}*/}
            {/*                splitByCapitalLetter={true}*/}
            {/*                clearable={false}*/}
            {/*                searchable={false}*/}
            {/*                changeOption={(value: number | string) => {*/}
            {/*                    props.onChange(*/}
            {/*                        getAnswerFromString(value.toString()),*/}
            {/*                        props.comments*/}
            {/*                    );*/}
            {/*                }}*/}
            {/*                id={props.answer}*/}
            {/*                disabled={isHistoric}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {props.answer === Answer.Yes && (*/}
            {/*        <div className="row ml-0 mr-0 mb-4">*/}
            {/*            <div className="col">*/}
            {/*                <textarea*/}
            {/*                    className="input-fields"*/}
            {/*                    style={{minHeight: `150px`}}*/}
            {/*                    value={props.comments}*/}
            {/*                    onChange={(evt) => {*/}
            {/*                        props.onChange(props.answer, evt.target.value);*/}
            {/*                    }}*/}
            {/*                    placeholder="Enter details here..."*/}
            {/*                    disabled={isHistoric}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

export default QuestionAndAnswerAndComments;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerAndCommentsWithCallback extends QuestionWithAnswerAndComments {
    onChange: (newAnswer: Answer, comments: string | undefined) => void;
    isOptionalQuestion: boolean;
}
