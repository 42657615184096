import React from "react";
import {YesAnswerMonthlyQuestionResult} from "../../../../../../../api/prf";

const QualityReportYearViewListItem = (props: YesAnswerMonthlyQuestionResult) => (
    <tr className="pulse-table-row">
        <td className="p-3 pulse-table-row-item">{props.question}</td>
        {props.monthCounts.map((value: number, index: number) => {
            return (
                <td className="p-3 text-center pulse-table-row-item" key={index}>
                    {value}
                </td>
            );
        })}
    </tr>
);

export default QualityReportYearViewListItem;
