import React, {useEffect, useState} from "react";
import {AuditType, ReportResponse, ReportResult} from "../../../../../../api/prf";
import {
    SortedReportResults,
    sortReportResultResponseToSortedResults
} from "../../../../../../store/reports/helpers/reportHelpers";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import AuditTypeReportListBlock from "./AuditTypeReportListBlock";

const AuditTypeReportList = (props: ReportResponse) => {
    const [reportResults, setReportResults] = useState<SortedReportResults<ReportResult>[]>(
        sortReportResultResponseToSortedResults(``, props.results)
    );

    useEffect(() => {
        const prefix = getPrefixName(props.auditTypes);
        setReportResults(sortReportResultResponseToSortedResults(prefix, props.results));
    }, [props]);

    const getPrefixName = (auditTypes: Array<AuditType>) => {
        return auditTypes.length > 0
            ? `${getUiFriendlyText(props.auditTypes[0])} Audits`
            : `Audits`;
    };

    const getNumberOfPrfsAuditedText = (auditTypes: Array<AuditType>) => {
        if (auditTypes.length > 0) {
            const prefix = getUiFriendlyText(props.auditTypes[0]);
            return `Number of ${prefix} PRFs audited:`;
        }

        return "Number of PRFs audited:";
    };

    return (
        <React.Fragment>
            {props.results.length > 0 && (
                <div className="mt-3">
                    <table className="d-block overflow-auto">
                        <tbody>
                            <tr>
                                <th className="p-3 w-50">
                                    <h5 className="mb-0">
                                        {getNumberOfPrfsAuditedText(props.auditTypes)}
                                    </h5>
                                </th>
                                <th className="p-3 w-50" />
                                <th className="p-3 w-50">
                                    <h5 className="mb-0 text-right pr-5">{props.results.length}</h5>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {reportResults.length > 0 ? (
                reportResults.map((result, key) => (
                    <AuditTypeReportListBlock {...result} key={key} />
                ))
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There are no reports that match the filters
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default AuditTypeReportList;
