import {newQuestionWithAnswer} from "./auditHelpers";
import {QuestionType} from "../../../api/prf";

/** These can be null during filling the form in
 * create a separate function for DRY purposes for one source of truth */

export function generateAssessmentDocumented() {
    return newQuestionWithAnswer(4, "Assessment documented?", QuestionType.Normal);
}

export function generateInterpretationCorrect() {
    return newQuestionWithAnswer(2, "Interpretation correct?", QuestionType.Normal);
}

export function generateManagementCorrect() {
    return newQuestionWithAnswer(2, "Management correct?", QuestionType.Normal);
}

export function generateAppropriateMedicationGiven() {
    return newQuestionWithAnswer(2, "Appropriate medication administered?", QuestionType.Normal);
}

export function generateCorrectRouteOfAdministration() {
    return newQuestionWithAnswer(4, "Correct route of administration?", QuestionType.Normal);
}

export function generateCorrectDosage() {
    return newQuestionWithAnswer(4, "Correct dosage?", QuestionType.Normal);
}

export function generateDocumentedCorrectly() {
    return newQuestionWithAnswer(4, "Documented correctly?", QuestionType.Normal);
}

export function generateCorrectClinicianAdministering() {
    return newQuestionWithAnswer(1, "Correct clinician administering?", QuestionType.Normal);
}

export function generateAppropriateDestination() {
    return newQuestionWithAnswer(2, "Appropriate destination?", QuestionType.Normal);
}

export function generateHandoverTimes() {
    return newQuestionWithAnswer(1, "Handover times?", QuestionType.Normal);
}

export function generatePatientOutcomeReasonable() {
    return newQuestionWithAnswer(5, "Patient outcome reasonable?", QuestionType.Normal);
}

export function generatePathfinderOutcome() {
    return newQuestionWithAnswer(4, "Pathfinder outcome?", QuestionType.Normal);
}

export function generateCalContacted() {
    return newQuestionWithAnswer(4, "CAL contacted?", QuestionType.Normal);
}

export function generateNews2ScoreRecorded() {
    return newQuestionWithAnswer(4, "NEWS2 score recorded?", QuestionType.Normal);
}

export function generateWorseningCareAdviceDocumented() {
    return newQuestionWithAnswer(6, "Worsening care advice documented?", QuestionType.Normal);
}
