import {QuestionType, RecognitionOfLifeExtinctAudit} from "../../../api/prf";
import {newQuestionWithAnswer} from "./auditHelpers";

/** Recognition Of Life Extinct audit */
export function generateRecognitionOfLifeExtinctAudit(): RecognitionOfLifeExtinctAudit {
    return {
        historyOfPresentingComplaintOrCollapse: newQuestionWithAnswer(
            1,
            "History of presenting complaint/collapse?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        cprNotPerformedInLast15Minutes: newQuestionWithAnswer(
            4,
            "CPR not performed within the last 15 minutes?",
            QuestionType.Normal
        ),
        asystoleRhythmStrip: newQuestionWithAnswer(
            4,
            "Asystole rhythm strip?",
            QuestionType.Normal
        ),
        rigorMortisPresent: newQuestionWithAnswer(4, "Rigor mortis present?", QuestionType.Normal),
        hypostasisPresent: newQuestionWithAnswer(4, "Hypostasis present?", QuestionType.Normal),
        factorsIncompatibleWithLifePresent: newQuestionWithAnswer(
            2,
            "Factors incompatible with life present?",
            QuestionType.Normal
        ),
        timeOfVerificationRecorded: newQuestionWithAnswer(
            4,
            "Time of verification recorded?",
            QuestionType.Normal
        ),
        guidelinesFollowed: newQuestionWithAnswer(6, "Guidelines followed?", QuestionType.Normal),
        policeRequested: newQuestionWithAnswer(2, "Police requested?", QuestionType.Normal),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly?", QuestionType.Normal)
    };
}
