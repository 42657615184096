import React from "react";
import {
    Answer,
    OutcomeQuestions,
    QuestionWithAnswerAndComments
} from "../../../../../../../api/prf";
import QuestionAndAnswerAndComments from "../../Question/QuestionAndAnswerAndComments";
import {useDispatch} from "react-redux";
import {setAuditFormOutcomeQuestions} from "../../../../../../../store/audit/actions/AuditActions";

const PatientReferralOutcome = (props: OutcomeQuestions) => {
    const dispatch = useDispatch();
    /** Updates Safeguarding referral */
    const updateSafeguardReferral = (answer: Answer, comments: string | undefined) => {
        const safeguardingReferral: QuestionWithAnswerAndComments = {
            ...props.safeguardingReferral,
            answer,
            //Reset the comment field only if the answer is not yes
            comments: shouldCommentsBeReset(answer, comments)
        };

        dispatch(setAuditFormOutcomeQuestions({...props, safeguardingReferral}));
    };

    /** Updates falls referral */
    const updateFallsReferral = (answer: Answer, comments: string | undefined) => {
        const fallsReferral: QuestionWithAnswerAndComments = {
            ...props.fallsReferral,
            answer,
            //Reset the comment field only if the answer is not yes
            comments: shouldCommentsBeReset(answer, comments)
        };

        dispatch(setAuditFormOutcomeQuestions({...props, fallsReferral}));
    };

    /** Updates diabetic referral */
    const updateDiabeticReferral = (answer: Answer, comments: string | undefined) => {
        const diabeticReferral: QuestionWithAnswerAndComments = {
            ...props.diabeticReferral,
            answer,
            //Reset the comment field only if the answer is not yes
            comments: shouldCommentsBeReset(answer, comments)
        };

        dispatch(setAuditFormOutcomeQuestions({...props, diabeticReferral}));
    };

    /** Checks to see if comments should be reset if the answer changes */
    const shouldCommentsBeReset = (
        answer: Answer,
        comments: string | undefined
    ): string | undefined => {
        return answer === Answer.Yes ? comments : undefined;
    };
    return (
        <React.Fragment>
            <QuestionAndAnswerAndComments
                {...props.safeguardingReferral}
                isOptionalQuestion={false}
                onChange={updateSafeguardReferral}
            />
            <QuestionAndAnswerAndComments
                {...props.fallsReferral}
                isOptionalQuestion={false}
                onChange={updateFallsReferral}
            />
            <QuestionAndAnswerAndComments
                {...props.diabeticReferral}
                isOptionalQuestion={false}
                onChange={updateDiabeticReferral}
            />
        </React.Fragment>
    );
};

export default PatientReferralOutcome;
