import React, {useEffect, useState} from "react";
import {CalendarSummary, CalendarTypeEnum} from "../../../api/grs";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {Loading} from "store-fetch-wrappers/dist";
import {
    CalendarDropdownProps,
    DDProps,
    sortCalendarSummaryListToDropdownProps
} from "../Helpers/dropdownUtils";
import Select, {SingleValue} from "react-select";
import {
    getCalendarSummaryList,
    nullifyCalendarSummaryListStore
} from "../../../store/calendarSummaryList/actions/CalendarSummaryListActions";

const CalendarDropdown = (props: CalendarDropdownProps) => {
    const dispatch = useDispatch();
    const calendarListStore = useSelector((state: RootStore) => state.calendarSummaryList);
    const [calendarList, setCalendarList] = useState<CalendarSummary[]>([]);
    const [calendarOptions, setCalendarOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);

    useEffect(() => {
        //Get all calendars upon mounting the component.
        (async function fetchCalendarList() {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const calendars: CalendarSummary[] = await dispatch(getCalendarSummaryList());
                processCalendarListIncoming(calendars);
            } catch (e: any) {
                //
            }
        })();

        return function () {
            dispatch(nullifyCalendarSummaryListStore());
        };
    }, []);

    /** Will update the component once the calendars list has been populated*/
    const processCalendarListIncoming = (calendars: CalendarSummary[]) => {
        setCalendarList(calendars);
        const options = sortCalendarSummaryListToDropdownProps(calendars);
        setCalendarOptions(options);

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(emptyCalendarSummary());
            return;
        }

        const calendar = getCalendarFromList(props.id, calendars);

        setSelectedOption({label: calendar.name, value: calendar.id});
        props.changeOption(calendar);
    };

    /** Finds the calendar in the list. */
    const getCalendarFromList = (
        calendarId: number,
        calendars: CalendarSummary[]
    ): CalendarSummary => {
        const calendar = calendars.find((el: CalendarSummary) => el.id === calendarId);

        if (!calendar) {
            return calendarList[0];
        }

        return calendar;
    };

    /** Fired when option in the dropdown has been altered*/
    const handleCalendarChange = (event: SingleValue<DDProps>) => {
        if (!event) {
            props.changeOption(emptyCalendarSummary());
            setSelectedOption(undefined);
            return;
        }
        const calendar = getCalendarFromList(+event.value, calendarList);
        setSelectedOption({label: calendar.name, value: calendar.id});
        props.changeOption(calendar);
    };

    /** Creates empty calendar summary */
    const emptyCalendarSummary = (): CalendarSummary => {
        return {
            version: 0,
            id: 0,
            name: "",
            calendarType: CalendarTypeEnum.Event,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            billingType: "Daily",
            bgImageUrl: ""
        };
    };
    return (
        <React.Fragment>
            {calendarListStore.loading && <Loading type={"three-ring"} showLoadingText={false} />}
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={calendarOptions}
                onChange={handleCalendarChange}
                value={selectedOption}
                noOptionsMessage={() => "No Calendars Found"}
                placeholder="Select Calendar"
                isDisabled={false}
                isClearable={props.clearable}
                isSearchable={false}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default CalendarDropdown;
