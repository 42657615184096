import React from "react";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import {NotConveyedAudit} from "../../../../../../../api/prf";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyNotConveyedAudit = ({questions}: ReadOnlyQuestions<NotConveyedAudit>) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer
                {...questions.historyOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.consentObtained} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.capacityAssessed} isOptionalQuestion={false} />
            {questions.assessmentDocumented && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.assessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.atLeast2Observations}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.leadEcg} isOptionalQuestion={false} />
            {questions.managementCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.managementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {questions.interpretationCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.interpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.clinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.differentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.medicationGiven} isOptionalQuestion={false} />
            {questions.appropriateMedicationAdministered && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.appropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctRouteOfAdministration && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctDosage && (
                <ReadOnlyQuestionAndAnswer {...questions.correctDosage} isOptionalQuestion={true} />
            )}
            {questions.documentedCorrectly && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.documentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctClinicianAdministering && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.patientOutcomeReasonable}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.pathfinderOutcome}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.calContacted} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer
                {...questions.news2ScoreRecorded}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.worseningCareAdviceGiven}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlyNotConveyedAudit;
