import React from "react";
import {
    Answer,
    CardiacArrestAndROSCAudit,
    Conveyance,
    QuestionWithAnswer,
    QuestionWithConveyance
} from "../../../../../../../api/prf";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import QuestionAndConveyance from "../../Question/QuestionAndConveyance";
import {useDispatch} from "react-redux";
import {updateCardiacArrestAndRoscQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {
    updateAdvancedAirwayQuestionBasedOnAnswer,
    updateCardiacArrestAndRoscAuditBasedOnConveyance,
    updateMedicationGivenQuestionBasedOnAnswer,
    updateRoscAchievedQuestionBasedOnAnswer
} from "../../../../../../../store/audit/helpers/cardiacArrestAndRoscAuditHelpers";
import FormHeader from "../../../../../../Form/FormHeader";

const CardiacArrestAndRoscQuestions = (props: CardiacArrestAndROSCAudit) => {
    const dispatch = useDispatch();

    // Update History Of Presenting Complaint Or Collapse
    const updateHistoryOfPresentingComplaintOrCollapse = (answer: Answer) => {
        const historyOfPresentingComplaintOrCollapse: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaintOrCollapse,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                historyOfPresentingComplaintOrCollapse
            })
        );
    };

    // Update Primary Survey
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    // Update Document If Witnessed
    const updateDocumentIfWitnessed = (answer: Answer) => {
        const documentIfWitnessed: QuestionWithAnswer = {
            ...props.documentIfWitnessed,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                documentIfWitnessed
            })
        );
    };

    // Update Time Of Bystander Cpr
    const updateTimeOfBystanderCpr = (answer: Answer) => {
        const timeOfBystanderCpr: QuestionWithAnswer = {
            ...props.timeOfBystanderCpr,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                timeOfBystanderCpr
            })
        );
    };

    // Update Bystander Defib Used
    const updateBystanderDefibUsed = (answer: Answer) => {
        const bystanderDefibUsed: QuestionWithAnswer = {
            ...props.bystanderDefibUsed,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                bystanderDefibUsed
            })
        );
    };

    // Update Time Of Medicare Crew Cpr
    const updateTimeOfMedicareCrewCpr = (answer: Answer) => {
        const timeOfMedicareCrewCpr: QuestionWithAnswer = {
            ...props.timeOfMedicareCrewCpr,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                timeOfMedicareCrewCpr
            })
        );
    };

    // Update Medicare Defib Used
    const updateMedicareDefibUsed = (answer: Answer) => {
        const medicareDefibUsed: QuestionWithAnswer = {
            ...props.medicareDefibUsed,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                medicareDefibUsed
            })
        );
    };

    // Update Initial Rhythm Recorded
    const updateInitialRhythmRecorded = (answer: Answer) => {
        const initialRhythmRecorded: QuestionWithAnswer = {
            ...props.initialRhythmRecorded,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                initialRhythmRecorded
            })
        );
    };

    // Update Number Of Shock Delivered
    const updateNumberOfShockDelivered = (answer: Answer) => {
        const numberOfShockDelivered: QuestionWithAnswer = {
            ...props.numberOfShockDelivered,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                numberOfShockDelivered
            })
        );
    };

    // Update Airway Correctly Managed
    const updateAirwayCorrectlyManaged = (answer: Answer) => {
        const airwayCorrectlyManaged: QuestionWithAnswer = {
            ...props.airwayCorrectlyManaged,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                airwayCorrectlyManaged
            })
        );
    };

    // Update Advanced Airway Placed
    const updateAdvancedAirwayPlaced = (answer: Answer) => {
        const advancedAirwayPlaced: QuestionWithAnswer = {
            ...props.advancedAirwayPlaced,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions(
                updateAdvancedAirwayQuestionBasedOnAnswer(answer, {...props, advancedAirwayPlaced})
            )
        );
    };

    // Update End Tidal Co2 Recorded
    const updateEndTidalCo2Recorded = (answer: Answer) => {
        if (!props.endTidalCo2Recorded) return;
        const endTidalCo2Recorded: QuestionWithAnswer = {
            ...props.endTidalCo2Recorded,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                endTidalCo2Recorded
            })
        );
    };

    // Update End Tidal Co2 Printed
    const updateEndTidalCo2Printed = (answer: Answer) => {
        if (!props.endTidalCo2Printed) return;
        const endTidalCo2Printed: QuestionWithAnswer = {
            ...props.endTidalCo2Printed,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                endTidalCo2Printed
            })
        );
    };

    // Update Vascular Access Gained
    const updateVascularAccessGained = (answer: Answer) => {
        const vascularAccessGained: QuestionWithAnswer = {
            ...props.vascularAccessGained,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                vascularAccessGained
            })
        );
    };

    // Update oxygen given appropriately
    const updateOxygenGivenAppropriately = (answer: Answer) => {
        const oxygenGivenAppropriately: QuestionWithAnswer = {
            ...props.oxygenGivenAppropriately,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                oxygenGivenAppropriately
            })
        );
    };

    // Update medication Given
    const updateMedicationGiven = (answer: Answer) => {
        const medicationGiven: QuestionWithAnswer = {
            ...props.medicationGiven,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions(
                updateMedicationGivenQuestionBasedOnAnswer(answer, {...props, medicationGiven})
            )
        );
    };

    // Update Adrenaline Given Appropriately
    const updateAdrenalineGivenAppropriately = (answer: Answer) => {
        if (!props.adrenalineGivenAppropriately) return;
        const adrenalineGivenAppropriately: QuestionWithAnswer = {
            ...props.adrenalineGivenAppropriately,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                adrenalineGivenAppropriately
            })
        );
    };

    // Update Amiodarone Given Appropriately
    const updateAmiodaroneGivenAppropriately = (answer: Answer) => {
        if (!props.amidaroneGivenAppropriately) return;
        const amidaroneGivenAppropriately: QuestionWithAnswer = {
            ...props.amidaroneGivenAppropriately,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                amidaroneGivenAppropriately
            })
        );
    };

    // Update Sodium Chloride Given Appropriately
    const updateSodiumChlorideGivenAppropriately = (answer: Answer) => {
        if (!props.sodiumChlorideGivenAppropriately) return;
        const sodiumChlorideGivenAppropriately: QuestionWithAnswer = {
            ...props.sodiumChlorideGivenAppropriately,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                sodiumChlorideGivenAppropriately
            })
        );
    };

    // Update Other Medicines Given Appropriately
    const updateOtherMedicinesGivenAppropriately = (answer: Answer) => {
        if (!props.otherMedicinesGivenAppropriately) return;
        const otherMedicinesGivenAppropriately: QuestionWithAnswer = {
            ...props.otherMedicinesGivenAppropriately,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                otherMedicinesGivenAppropriately
            })
        );
    };

    // Update rosc achieved
    const updateRoscAchieved = (answer: Answer) => {
        if (!props.roscAchieved) return;
        const roscAchieved: QuestionWithAnswer = {
            ...props.roscAchieved,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions(
                updateRoscAchievedQuestionBasedOnAnswer(answer, {...props, roscAchieved})
            )
        );
    };

    // Update lead ecg achieved
    const updateLeadEcgAchieved = (answer: Answer) => {
        if (!props.leadEcgAchieved) return;
        const leadEcgAchieved: QuestionWithAnswer = {
            ...props.leadEcgAchieved,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                leadEcgAchieved
            })
        );
    };

    // Update bm recorded
    const updateBmRecorded = (answer: Answer) => {
        if (!props.bmRecorded) return;
        const bmRecorded: QuestionWithAnswer = {
            ...props.bmRecorded,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                bmRecorded
            })
        );
    };

    // Update End Tidal CO2 recorded
    const updateEndTidalCO2Recorded = (answer: Answer) => {
        if (!props.endTidalCO2Recorded) return;
        const endTidalCO2Recorded: QuestionWithAnswer = {
            ...props.endTidalCO2Recorded,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                endTidalCO2Recorded
            })
        );
    };

    // Update Oxygen Given And Titrated
    const updateOxygenGivenAndTitrated = (answer: Answer) => {
        if (!props.oxygenDeliveredAndTirated) return;
        const oxygenDeliveredAndTirated: QuestionWithAnswer = {
            ...props.oxygenDeliveredAndTirated,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                oxygenDeliveredAndTirated
            })
        );
    };

    // Update systolic bp recorded
    const updateSystolicBpRecorded = (answer: Answer) => {
        if (!props.systolicBpRecorded) return;
        const systolicBpRecorded: QuestionWithAnswer = {
            ...props.systolicBpRecorded,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                systolicBpRecorded
            })
        );
    };

    // Update bolus scp given
    const updateBolusScpGiven = (answer: Answer) => {
        if (!props.bolusScpGiven) return;
        const bolusScpGiven: QuestionWithAnswer = {
            ...props.bolusScpGiven,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                bolusScpGiven
            })
        );
    };

    // Updates destination correctly
    const updateDestination = (conveyance: Conveyance) => {
        const destination: QuestionWithConveyance = {
            ...props.destination,
            conveyance
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions(
                updateCardiacArrestAndRoscAuditBasedOnConveyance(conveyance, {
                    ...props,
                    destination
                })
            )
        );
    };

    // Update appropriate destination
    const updateAppropriateDestination = (answer: Answer) => {
        if (!props.appropriateDestination) return;
        const appropriateDestination: QuestionWithAnswer = {
            ...props.appropriateDestination,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                appropriateDestination
            })
        );
    };

    // Update handover times
    const updateHandoverTimes = (answer: Answer) => {
        if (!props.handoverTimes) return;
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    // Update time verified
    const updateTimeVerified = (answer: Answer) => {
        if (!props.timeVerified) return;
        const timeVerified: QuestionWithAnswer = {
            ...props.timeVerified,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                timeVerified
            })
        );
    };

    // Update guidelines followed
    const updateGuidelinesFollowed = (answer: Answer) => {
        if (!props.guidelinesFollowed) return;
        const guidelinesFollowed: QuestionWithAnswer = {
            ...props.guidelinesFollowed,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                guidelinesFollowed
            })
        );
    };

    // Update call closed correctly
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateCardiacArrestAndRoscQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Cardiac Arrest and Rosc Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaintOrCollapse}
                onChange={updateHistoryOfPresentingComplaintOrCollapse}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.documentIfWitnessed}
                onChange={updateDocumentIfWitnessed}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.timeOfBystanderCpr}
                onChange={updateTimeOfBystanderCpr}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.bystanderDefibUsed}
                onChange={updateBystanderDefibUsed}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.timeOfMedicareCrewCpr}
                onChange={updateTimeOfMedicareCrewCpr}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.medicareDefibUsed}
                onChange={updateMedicareDefibUsed}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.initialRhythmRecorded}
                onChange={updateInitialRhythmRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.numberOfShockDelivered}
                onChange={updateNumberOfShockDelivered}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.airwayCorrectlyManaged}
                onChange={updateAirwayCorrectlyManaged}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.advancedAirwayPlaced}
                onChange={updateAdvancedAirwayPlaced}
                isOptionalQuestion={false}
            />
            {props.endTidalCo2Recorded && (
                <QuestionAndAnswer
                    {...props.endTidalCo2Recorded}
                    onChange={updateEndTidalCo2Recorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.endTidalCo2Printed && (
                <QuestionAndAnswer
                    {...props.endTidalCo2Printed}
                    onChange={updateEndTidalCo2Printed}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.vascularAccessGained}
                onChange={updateVascularAccessGained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.oxygenGivenAppropriately}
                onChange={updateOxygenGivenAppropriately}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.medicationGiven}
                onChange={updateMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.adrenalineGivenAppropriately && (
                <QuestionAndAnswer
                    {...props.adrenalineGivenAppropriately}
                    onChange={updateAdrenalineGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {props.amidaroneGivenAppropriately && (
                <QuestionAndAnswer
                    {...props.amidaroneGivenAppropriately}
                    onChange={updateAmiodaroneGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {props.sodiumChlorideGivenAppropriately && (
                <QuestionAndAnswer
                    {...props.sodiumChlorideGivenAppropriately}
                    onChange={updateSodiumChlorideGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            {props.otherMedicinesGivenAppropriately && (
                <QuestionAndAnswer
                    {...props.otherMedicinesGivenAppropriately}
                    onChange={updateOtherMedicinesGivenAppropriately}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.roscAchieved}
                onChange={updateRoscAchieved}
                isOptionalQuestion={false}
            />
            {props.leadEcgAchieved && (
                <QuestionAndAnswer
                    {...props.leadEcgAchieved}
                    onChange={updateLeadEcgAchieved}
                    isOptionalQuestion={true}
                />
            )}
            {props.bmRecorded && (
                <QuestionAndAnswer
                    {...props.bmRecorded}
                    onChange={updateBmRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.endTidalCO2Recorded && (
                <QuestionAndAnswer
                    {...props.endTidalCO2Recorded}
                    onChange={updateEndTidalCO2Recorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.oxygenDeliveredAndTirated && (
                <QuestionAndAnswer
                    {...props.oxygenDeliveredAndTirated}
                    onChange={updateOxygenGivenAndTitrated}
                    isOptionalQuestion={true}
                />
            )}
            {props.systolicBpRecorded && (
                <QuestionAndAnswer
                    {...props.systolicBpRecorded}
                    onChange={updateSystolicBpRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {props.bolusScpGiven && (
                <QuestionAndAnswer
                    {...props.bolusScpGiven}
                    onChange={updateBolusScpGiven}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndConveyance
                dropdownOptions={CardiacArrestAndRoscDestinations}
                {...props.destination}
                onChange={updateDestination}
            />
            {props.appropriateDestination && (
                <QuestionAndAnswer
                    {...props.appropriateDestination}
                    onChange={updateAppropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {props.handoverTimes && (
                <QuestionAndAnswer
                    {...props.handoverTimes}
                    onChange={updateHandoverTimes}
                    isOptionalQuestion={true}
                />
            )}
            {props.timeVerified && (
                <QuestionAndAnswer
                    {...props.timeVerified}
                    onChange={updateTimeVerified}
                    isOptionalQuestion={true}
                />
            )}
            {props.guidelinesFollowed && (
                <QuestionAndAnswer
                    {...props.guidelinesFollowed}
                    onChange={updateGuidelinesFollowed}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default CardiacArrestAndRoscQuestions;

// eslint-disable-next-line no-shadow
enum CardiacArrestAndRoscDestinations {
    Conveyed = "conveyed",
    RecognitionOfLifeExtinct = "recognitionOfLifeExtinct",
    NotApplicable = "notApplicable"
}
