import React from "react";
import {PrfAudit} from "../../../../../../api/prf";
import {useDispatch} from "react-redux";
import {deleteAuditById} from "../../../../../../store/audit/actions/AuditActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";

const DeleteAuditAction = (props: DeleteAuditActionProps) => {
    const {isShown, toggle} = useModal();
    const dispatch = useDispatch();

    //Confirms the deletion of the audit
    const confirmDelete = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteAuditById(props.audit.id));

        if (success) props.onSuccess();
    };

    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue={"Delete"}
                onClick={toggle}
                colour={ButtonColourOptions.Red}
                roundedCorner
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title="Delete Audit"
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">Are you sure you want to delete this audit?</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={confirmDelete}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default DeleteAuditAction;

interface DeleteAuditActionProps {
    audit: PrfAudit;
    onSuccess: () => void;
}
