import {Dispatch} from "redux";
import {AUDIT_TYPE_REPORT_STORE, AuditTypeReportDispatchTypes} from "./AuditTypeReportActionTypes";
import {ReportRequest} from "../../../../api/prf";
import PrfApiModel from "../../../apiModel/PrfApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers/dist";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyAuditTypeReportStore = () => {
    return async (dispatch: Dispatch<AuditTypeReportDispatchTypes>) => {
        dispatch({
            type: AUDIT_TYPE_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets compliance reports */
export const fetchAuditTypeReport = (request: ReportRequest) => {
    return async (dispatch: Dispatch<AuditTypeReportDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                AUDIT_TYPE_REPORT_STORE,
                dispatch,
                () => PrfApiModel.getPrfApi().getAuditTypeReport(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_TYPE_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
