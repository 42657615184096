import {Answer, CardiacChestPainAudit, Conveyance, QuestionType} from "../../../api/prf";
import {newQuestionWithAnswer, newQuestionWithConveyance} from "./auditHelpers";
import {
    generateAppropriateDestination,
    generateAppropriateMedicationGiven,
    generateAssessmentDocumented,
    generateCalContacted,
    generateCorrectClinicianAdministering,
    generateCorrectDosage,
    generateCorrectRouteOfAdministration,
    generateDocumentedCorrectly,
    generateHandoverTimes,
    generateInterpretationCorrect,
    generateManagementCorrect,
    generateNews2ScoreRecorded,
    generatePathfinderOutcome,
    generatePatientOutcomeReasonable,
    generateWorseningCareAdviceDocumented
} from "./sharedQuestions";

/** Generates the mandatory questions without the optional fields created */
export function getCardiacChestPainQuestionsForNewAudit(): CardiacChestPainAudit {
    return {
        ...generateCardiacChestPainAudit(),
        assessmentDocumented: undefined,
        interpretationCorrect: undefined,
        managementCorrect: undefined,
        v4rRecorded: undefined,
        posteriorCheckRecorded: undefined,
        appropriateMedicationAdministered: undefined,
        correctRouteOfAdministration: undefined,
        correctDosage: undefined,
        documentedCorrectly: undefined,
        correctClinicianAdministering: undefined,
        appropriateDestination: undefined,
        handoverTimes: undefined,
        patientOutcomeReasonable: undefined,
        conveyanceRefused: undefined,
        pathfinderOutcome: undefined,
        calContacted: undefined,
        news2ScoreRecorded: undefined,
        worseningCareAdviceGiven: undefined
    };
}

/** Cardiac chest pain audit */
export function generateCardiacChestPainAudit(): CardiacChestPainAudit {
    return {
        historyOfPresentingComplaint: newQuestionWithAnswer(
            1,
            "History of presenting complaint?",
            QuestionType.Normal
        ),
        primarySurvey: newQuestionWithAnswer(1, "Primary survey?", QuestionType.Normal),
        consentObtained: newQuestionWithAnswer(2, "Consent obtained?", QuestionType.Normal),
        capacityAssessed: newQuestionWithAnswer(2, "Capacity assessed?", QuestionType.Normal),
        assessmentDocumented: generateAssessmentDocumented(),
        observationRecorded: newQuestionWithAnswer(1, "Observation recorded?", QuestionType.Normal),
        twoPainScoreDocumented: newQuestionWithAnswer(
            2,
            "Two pain scores documented?",
            QuestionType.Quality
        ),
        leadEcg: newQuestionWithAnswer(5, "12 lead ECG?", QuestionType.Normal),
        interpretationCorrect: generateInterpretationCorrect(),
        managementCorrect: generateManagementCorrect(),
        additionalChecksRequired: newQuestionWithAnswer(
            2,
            "Additional checked required?",
            QuestionType.Normal
        ),
        v4rRecorded: generateV4rRecorded(),
        posteriorCheckRecorded: generatePosteriorCheckRecorded(),
        gtnAdministeredCorrectly: newQuestionWithAnswer(
            4,
            "GTN administered correctly?",
            QuestionType.Quality
        ),
        aspirinAdministeredCorrectly: newQuestionWithAnswer(
            4,
            "Aspirin administered correctly?",
            QuestionType.Quality
        ),
        appropriatePainReliefGivenCorrectly: newQuestionWithAnswer(
            4,
            "Appropriate pain relief given correctly?",
            QuestionType.Quality
        ),
        additionalMedicationGiven: newQuestionWithAnswer(
            1,
            "Additional medication given?",
            QuestionType.Normal
        ),
        appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
        correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
        correctDosage: generateCorrectDosage(),
        documentedCorrectly: generateDocumentedCorrectly(),
        correctClinicianAdministering: generateCorrectClinicianAdministering(),
        clinicAssessmentAppropriate: newQuestionWithAnswer(
            5,
            "Clinical assessment appropriate?",
            QuestionType.Normal
        ),
        differentialDiagnosticReasonable: newQuestionWithAnswer(
            2,
            "Differential diagnosis reasonable?",
            QuestionType.Normal
        ),
        destination: newQuestionWithConveyance(0, "Destination?"),
        appropriateDestination: generateAppropriateDestination(),
        handoverTimes: generateHandoverTimes(),
        patientOutcomeReasonable: generatePatientOutcomeReasonable(),
        conveyanceRefused: generateConveyanceRefused(),
        pathfinderOutcome: generatePathfinderOutcome(),
        calContacted: generateCalContacted(),
        news2ScoreRecorded: generateNews2ScoreRecorded(),
        worseningCareAdviceGiven: generateWorseningCareAdviceDocumented(),
        callClosedCorrectly: newQuestionWithAnswer(1, "Call closed correctly", QuestionType.Normal)
    };
}

/** Updates form based on answer for this question */
export function updateCapacityAssessedQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacChestPainAudit
): CardiacChestPainAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                assessmentDocumented: generateAssessmentDocumented()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                assessmentDocumented: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function update12LeadEcgQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacChestPainAudit
): CardiacChestPainAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                interpretationCorrect: generateInterpretationCorrect(),
                managementCorrect: generateManagementCorrect()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                interpretationCorrect: undefined,
                managementCorrect: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateAdditionalChecksRequiredQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacChestPainAudit
): CardiacChestPainAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                v4rRecorded: generateV4rRecorded(),
                posteriorCheckRecorded: generatePosteriorCheckRecorded()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                v4rRecorded: undefined,
                posteriorCheckRecorded: undefined
            };
        default:
            return audit;
    }
}

/** Updates form based on answer for this question */
export function updateAdditionalMedicationGivenQuestionBasedOnAnswer(
    answer: Answer,
    audit: CardiacChestPainAudit
): CardiacChestPainAudit {
    switch (answer) {
        case Answer.Yes:
            return {
                ...audit,
                appropriateMedicationAdministered: generateAppropriateMedicationGiven(),
                correctRouteOfAdministration: generateCorrectRouteOfAdministration(),
                correctDosage: generateCorrectDosage(),
                documentedCorrectly: generateDocumentedCorrectly(),
                correctClinicianAdministering: generateCorrectClinicianAdministering()
            };
        case Answer.No:
        case Answer.NotApplicable:
            return {
                ...audit,
                appropriateMedicationAdministered: undefined,
                correctRouteOfAdministration: undefined,
                correctDosage: undefined,
                documentedCorrectly: undefined,
                correctClinicianAdministering: undefined
            };
        default:
            return audit;
    }
}

// Updates the questions in the Mental health and restraint audit based on conveyance
export function updateStrokeAuditBasedOnConveyance(
    conveyance: Conveyance,
    audit: CardiacChestPainAudit
): CardiacChestPainAudit {
    switch (conveyance) {
        case Conveyance.Conveyed:
            return {
                ...audit,
                appropriateDestination: generateAppropriateDestination(),
                handoverTimes: generateHandoverTimes(),
                patientOutcomeReasonable: undefined,
                conveyanceRefused: undefined,
                pathfinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceGiven: undefined
            };
        case Conveyance.NotConveyed:
        case Conveyance.Referred:
        case Conveyance.Refused:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: generatePatientOutcomeReasonable(),
                conveyanceRefused: generateConveyanceRefused(),
                pathfinderOutcome: generatePathfinderOutcome(),
                calContacted: generateCalContacted(),
                news2ScoreRecorded: generateNews2ScoreRecorded(),
                worseningCareAdviceGiven: generateWorseningCareAdviceDocumented()
            };
        case Conveyance.RecognitionOfLifeExtinct:
        case Conveyance.NotApplicable:
            return {
                ...audit,
                appropriateDestination: undefined,
                handoverTimes: undefined,
                patientOutcomeReasonable: undefined,
                conveyanceRefused: undefined,
                pathfinderOutcome: undefined,
                calContacted: undefined,
                news2ScoreRecorded: undefined,
                worseningCareAdviceGiven: undefined
            };
        default:
            return audit;
    }
}

function generateV4rRecorded() {
    return newQuestionWithAnswer(3, "v4R recorded?", QuestionType.Normal);
}

function generatePosteriorCheckRecorded() {
    return newQuestionWithAnswer(3, "Posterior check recorded?", QuestionType.Normal);
}

function generateConveyanceRefused() {
    return newQuestionWithAnswer(0, "Conveyance refused?", QuestionType.Normal);
}
