import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AuditHistoryOverview} from "../../../api/prf";

export const AUDIT_HISTORIC_STORE = createStoreState("audit_historic");

interface AuditHistoricLoading extends StoreServiceData<AuditHistoryOverview> {
    type: typeof AUDIT_HISTORIC_STORE.LOADING;
}
interface AuditHistoricError extends StoreServiceData<AuditHistoryOverview> {
    type: typeof AUDIT_HISTORIC_STORE.ERROR;
}
interface AuditHistoricSuccess extends StoreServiceData<AuditHistoryOverview> {
    type: typeof AUDIT_HISTORIC_STORE.SUCCESS;
}

export type AuditHistoricDispatchTypes =
    | AuditHistoricLoading
    | AuditHistoricError
    | AuditHistoricSuccess;
