import React from "react";
import {Answer, QuestionWithAnswer, SepsisAudit} from "../../../../../../../api/prf";
import {useDispatch} from "react-redux";
import {updateSepsisQuestions} from "../../../../../../../store/audit/actions/AuditActions";
import {
    update12LeadEcgQuestionBasedOnAnswer,
    updateAdditionalMedicationGivenQuestionBasedOnAnswer,
    updateCapacityAssessedQuestionBasedOnAnswer
} from "../../../../../../../store/audit/helpers/sepsisAuditHelpers";
import QuestionAndAnswer from "../../Question/QuestionAndAnswer";
import FormHeader from "../../../../../../Form/FormHeader";

const SepsisQuestions = (props: SepsisAudit) => {
    const dispatch = useDispatch();

    /** Updates history Of Presenting Complaint */
    const updateHistoryOfPresentingComplaint = (answer: Answer) => {
        const historyOfPresentingComplaint: QuestionWithAnswer = {
            ...props.historyOfPresentingComplaint,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                historyOfPresentingComplaint
            })
        );
    };

    /** Updates primary Survey */
    const updatePrimarySurvey = (answer: Answer) => {
        const primarySurvey: QuestionWithAnswer = {
            ...props.primarySurvey,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                primarySurvey
            })
        );
    };

    /** Updates consent Obtained */
    const updateConsentObtained = (answer: Answer) => {
        const consentObtained: QuestionWithAnswer = {
            ...props.consentObtained,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                consentObtained
            })
        );
    };

    /** Updates capacity Assessed */
    const updateCapacityAssessed = (answer: Answer) => {
        const capacityAssessed: QuestionWithAnswer = {
            ...props.capacityAssessed,
            answer
        };
        dispatch(
            updateSepsisQuestions(
                updateCapacityAssessedQuestionBasedOnAnswer(answer, {...props, capacityAssessed})
            )
        );
    };

    /** Updates assessment Documented */
    const updateAssessmentDocumented = (answer: Answer) => {
        if (!props.assessmentDocumented) return;
        const assessmentDocumented: QuestionWithAnswer = {
            ...props.assessmentDocumented,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                assessmentDocumented
            })
        );
    };

    /** Updates observation Recorded */
    const updateObservationRecorded = (answer: Answer) => {
        const observationRecorded: QuestionWithAnswer = {
            ...props.observationRecorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                observationRecorded
            })
        );
    };

    /** Updates gcs Recorded */
    const updateGcsRecorded = (answer: Answer) => {
        const gcsRecorded: QuestionWithAnswer = {
            ...props.gcsRecorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                gcsRecorded
            })
        );
    };

    /** Updates bp Recorded */
    const updateBpRecorded = (answer: Answer) => {
        const bpRecorded: QuestionWithAnswer = {
            ...props.bpRecorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                bpRecorded
            })
        );
    };

    /** Updates sp02 Recorded */
    const updateSp02Recorded = (answer: Answer) => {
        const sp02Recorded: QuestionWithAnswer = {
            ...props.sp02Recorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                sp02Recorded
            })
        );
    };

    /** Updates respiratory Rate Recorded */
    const updateRespiratoryRateRecorded = (answer: Answer) => {
        const respiratoryRateRecorded: QuestionWithAnswer = {
            ...props.respiratoryRateRecorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                respiratoryRateRecorded
            })
        );
    };

    /** Updates news2 Recorded */
    const updateNews2Recorded = (answer: Answer) => {
        const news2Recorded: QuestionWithAnswer = {
            ...props.news2Recorded,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                news2Recorded
            })
        );
    };

    /** Updates lead Ecg */
    const updateLeadEcg = (answer: Answer) => {
        const leadEcg: QuestionWithAnswer = {
            ...props.leadEcg,
            answer
        };
        dispatch(
            updateSepsisQuestions(update12LeadEcgQuestionBasedOnAnswer(answer, {...props, leadEcg}))
        );
    };

    /** Updates interpretation Correct */
    const updateInterpretationCorrect = (answer: Answer) => {
        if (!props.interpretationCorrect) return;
        const interpretationCorrect: QuestionWithAnswer = {
            ...props.interpretationCorrect,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                interpretationCorrect
            })
        );
    };

    /** Updates management Correct */
    const updateManagementCorrect = (answer: Answer) => {
        if (!props.managementCorrect) return;
        const managementCorrect: QuestionWithAnswer = {
            ...props.managementCorrect,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                managementCorrect
            })
        );
    };

    /** Updates clinic Assessment Appropriate */
    const updateClinicAssessmentAppropriate = (answer: Answer) => {
        const clinicAssessmentAppropriate: QuestionWithAnswer = {
            ...props.clinicAssessmentAppropriate,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                clinicAssessmentAppropriate
            })
        );
    };

    /** Updates differential Diagnostic Reasonable */
    const updateDifferentialDiagnosticReasonable = (answer: Answer) => {
        const differentialDiagnosticReasonable: QuestionWithAnswer = {
            ...props.differentialDiagnosticReasonable,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                differentialDiagnosticReasonable
            })
        );
    };

    /** Updates high Flow Oxygen Administered */
    const updateHighFlowOxygenAdministered = (answer: Answer) => {
        const highFlowOxygenAdministered: QuestionWithAnswer = {
            ...props.highFlowOxygenAdministered,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                highFlowOxygenAdministered
            })
        );
    };

    /** Updates sodium Chloride Administered */
    const updateSodiumChlorideAdministered = (answer: Answer) => {
        const sodiumChlorideAdministered: QuestionWithAnswer = {
            ...props.sodiumChlorideAdministered,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                sodiumChlorideAdministered
            })
        );
    };

    /** Updates additional Medication Given */
    const updateAdditionalMedicationGiven = (answer: Answer) => {
        const additionalMedicationGiven: QuestionWithAnswer = {
            ...props.additionalMedicationGiven,
            answer
        };
        dispatch(
            updateSepsisQuestions(
                updateAdditionalMedicationGivenQuestionBasedOnAnswer(answer, {
                    ...props,
                    additionalMedicationGiven
                })
            )
        );
    };

    /** Updates other Medication Administered */
    const updateOtherMedicationAdministered = (answer: Answer) => {
        if (!props.otherMedicationAdministered) return;
        const otherMedicationAdministered: QuestionWithAnswer = {
            ...props.otherMedicationAdministered,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                otherMedicationAdministered
            })
        );
    };

    /** Updates correct Route Of Administration */
    const updateCorrectRouteOfAdministration = (answer: Answer) => {
        if (!props.correctRouteOfAdministration) return;
        const correctRouteOfAdministration: QuestionWithAnswer = {
            ...props.correctRouteOfAdministration,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                correctRouteOfAdministration
            })
        );
    };

    /** Updates correct Dosage */
    const updateCorrectDosage = (answer: Answer) => {
        if (!props.correctDosage) return;
        const correctDosage: QuestionWithAnswer = {
            ...props.correctDosage,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                correctDosage
            })
        );
    };

    /** Updates documented Correctly */
    const updateDocumentedCorrectly = (answer: Answer) => {
        if (!props.documentedCorrectly) return;
        const documentedCorrectly: QuestionWithAnswer = {
            ...props.documentedCorrectly,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                documentedCorrectly
            })
        );
    };

    /** Updates correct Clinician Administering */
    const updateCorrectClinicianAdministering = (answer: Answer) => {
        if (!props.correctClinicianAdministering) return;
        const correctClinicianAdministering: QuestionWithAnswer = {
            ...props.correctClinicianAdministering,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                correctClinicianAdministering
            })
        );
    };

    /** Updates pre Alert Placed */
    const updatePreAlertPlaced = (answer: Answer) => {
        const preAlertPlaced: QuestionWithAnswer = {
            ...props.preAlertPlaced,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                preAlertPlaced
            })
        );
    };

    /** Updates appropriate Destination */
    const updateAppropriateDestination = (answer: Answer) => {
        const appropriateDestination: QuestionWithAnswer = {
            ...props.appropriateDestination,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                appropriateDestination
            })
        );
    };

    /** Updates handover times */
    const updateHandoverTimes = (answer: Answer) => {
        const handoverTimes: QuestionWithAnswer = {
            ...props.handoverTimes,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                handoverTimes
            })
        );
    };

    /** Updates call Closed Correctly */
    const updateCallClosedCorrectly = (answer: Answer) => {
        const callClosedCorrectly: QuestionWithAnswer = {
            ...props.callClosedCorrectly,
            answer
        };
        dispatch(
            updateSepsisQuestions({
                ...props,
                callClosedCorrectly
            })
        );
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Sepsis Audit"} />
            <QuestionAndAnswer
                {...props.historyOfPresentingComplaint}
                onChange={updateHistoryOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.primarySurvey}
                onChange={updatePrimarySurvey}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.consentObtained}
                onChange={updateConsentObtained}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.capacityAssessed}
                onChange={updateCapacityAssessed}
                isOptionalQuestion={false}
            />
            {props.assessmentDocumented && (
                <QuestionAndAnswer
                    {...props.assessmentDocumented}
                    onChange={updateAssessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.observationRecorded}
                onChange={updateObservationRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.gcsRecorded}
                onChange={updateGcsRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.bpRecorded}
                onChange={updateBpRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.sp02Recorded}
                onChange={updateSp02Recorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.respiratoryRateRecorded}
                onChange={updateRespiratoryRateRecorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.news2Recorded}
                onChange={updateNews2Recorded}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.leadEcg}
                onChange={updateLeadEcg}
                isOptionalQuestion={false}
            />
            {props.managementCorrect && (
                <QuestionAndAnswer
                    {...props.managementCorrect}
                    onChange={updateManagementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {props.interpretationCorrect && (
                <QuestionAndAnswer
                    {...props.interpretationCorrect}
                    onChange={updateInterpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.clinicAssessmentAppropriate}
                onChange={updateClinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.differentialDiagnosticReasonable}
                onChange={updateDifferentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.highFlowOxygenAdministered}
                onChange={updateHighFlowOxygenAdministered}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.sodiumChlorideAdministered}
                onChange={updateSodiumChlorideAdministered}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.additionalMedicationGiven}
                onChange={updateAdditionalMedicationGiven}
                isOptionalQuestion={false}
            />
            {props.otherMedicationAdministered && (
                <QuestionAndAnswer
                    {...props.otherMedicationAdministered}
                    onChange={updateOtherMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctRouteOfAdministration && (
                <QuestionAndAnswer
                    {...props.correctRouteOfAdministration}
                    onChange={updateCorrectRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctDosage && (
                <QuestionAndAnswer
                    {...props.correctDosage}
                    onChange={updateCorrectDosage}
                    isOptionalQuestion={true}
                />
            )}
            {props.documentedCorrectly && (
                <QuestionAndAnswer
                    {...props.documentedCorrectly}
                    onChange={updateDocumentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {props.correctClinicianAdministering && (
                <QuestionAndAnswer
                    {...props.correctClinicianAdministering}
                    onChange={updateCorrectClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <QuestionAndAnswer
                {...props.preAlertPlaced}
                onChange={updatePreAlertPlaced}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.appropriateDestination}
                onChange={updateAppropriateDestination}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.handoverTimes}
                onChange={updateHandoverTimes}
                isOptionalQuestion={false}
            />
            <QuestionAndAnswer
                {...props.callClosedCorrectly}
                onChange={updateCallClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default SepsisQuestions;
