import React, {useEffect, useState} from "react";
import {useReportDateView} from "../../../../../../Hooks/useReportDateView";
import {
    FilteredQualityReportResult,
    getFilteredResultsForMonthView
} from "../../Helpers/qualityReportMonthViewHelpers";
import {YesAnswerMonthlyAuditTypeResult} from "../../../../../../../api/prf";
import {PulseTable} from "pulse_table";
import {formatUnixToMMMM} from "../../../../../../../utils/momentUtils";

const QualityReportMonthViewTable = (props: YesAnswerMonthlyAuditTypeResult) => {
    const {dateStart} = useReportDateView();
    const reportDateView = useReportDateView();
    const [filteredResults, setFilteredResults] = useState<FilteredQualityReportResult[]>(
        getFilteredResultsForMonthView(props, reportDateView)
    );

    useEffect(() => {
        setFilteredResults(getFilteredResultsForMonthView(props, reportDateView));
    }, [props]);

    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(filteredResults)}
                headers={{
                    question: "-",
                    monthValue: formatUnixToMMMM(dateStart)
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There is no quality data for these filters
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default QualityReportMonthViewTable;

interface TableRow {
    question: string;
    monthValue: number;
}

function toTableRow(results: FilteredQualityReportResult[]): TableRow[] {
    return results.map((item) => {
        return {
            question: item.question,
            monthValue: item.value
        };
    });
}
