import {YesAnswerMonthlyReportResult} from "../../../../api/prf";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const QUALITY_REPORT_STORE = createStoreState("quality_report");

interface QualityReportLoading extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof QUALITY_REPORT_STORE.LOADING;
}
interface QualityReportError extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof QUALITY_REPORT_STORE.ERROR;
}
interface QualityReportSuccess extends StoreServiceData<YesAnswerMonthlyReportResult> {
    type: typeof QUALITY_REPORT_STORE.SUCCESS;
}

export type QualityReportDispatchTypes =
    | QualityReportLoading
    | QualityReportError
    | QualityReportSuccess;
