export const routeNames = {
    overview: {
        path: "/overview",
        name: "PRF Audits"
    },
    newAudit: {
        path: "/audits/new",
        name: "New Audit"
    },
    editAudit: {
        path: "/audits/edit",
        name: "Edit Audit"
    },
    reviewAudit: {
        path: "/audits/review",
        name: "Review Audit"
    },
    auditList: {
        path: "/audits/list",
        name: "Audit List"
    },
    reports: {
        name: "Reports",
        path: "/reports"
    },
    venueReport: {
        name: "Venue Report",
        path: "/reports/venue"
    },
    auditComplianceReport: {
        name: "Compliance Report",
        path: "/reports/compliance"
    },
    auditTypeReport: {
        name: "Audit Type Report",
        path: "/reports/audit-type"
    },
    qualityReport: {
        name: "Quality Report",
        path: "/reports/quality"
    },
    outcomeReport: {
        name: "Outcome Report",
        path: "/reports/outcome"
    },
    failedQuestions: {
        name: "Failed Questions Report",
        path: "/reports/failed-questions"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
