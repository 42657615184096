import React, {useEffect} from "react";
import {PrfAudit} from "../../../../../api/prf";
import ReviewAuditFormParentDetails from "./Parent/ReviewAuditFormParentDetails";
import ReviewAuditForm from "./Children/ReviewAuditForm";
import {routeNames} from "../../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import FormActionContainer from "../../../../Form/FormActionContainer";
import {useDispatch} from "react-redux";
import {nullifyAuditStore} from "../../../../../store/audit/actions/AuditActions";
import {usePageUrl} from "../../../../Hooks/usePageUrl";

const ReviewAuditFormParent = (props: PrfAudit) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {searchQueries} = usePageUrl();
    useEffect(() => {
        return function () {
            dispatch(nullifyAuditStore());
        };
    }, []);

    /** Goes back to audit trail page*/
    const backToOverview = () => {
        history.push({pathname: routeNames.auditList.path, search: searchQueries});
    };

    return (
        <React.Fragment>
            <ReviewAuditFormParentDetails {...props} />
            <ReviewAuditForm {...JSON.parse(props.payload)} />
            <FormActionContainer>
                <MCButton
                    className="mr-3"
                    onClick={backToOverview}
                    innerValue={"Back"}
                    colour={ButtonColourOptions.DarkBlue}
                    size={ButtonSize.Large}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default ReviewAuditFormParent;
