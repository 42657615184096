import React from "react";
import {CardiacChestPainAudit} from "../../../../../../../api/prf";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyCardiacChestPainAudit = ({questions}: ReadOnlyQuestions<CardiacChestPainAudit>) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer
                {...questions.historyOfPresentingComplaint}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.consentObtained} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...questions.capacityAssessed} isOptionalQuestion={false} />
            {questions.assessmentDocumented && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.assessmentDocumented}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.observationRecorded}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.twoPainScoreDocumented}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.leadEcg} isOptionalQuestion={false} />
            {questions.managementCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.managementCorrect}
                    isOptionalQuestion={true}
                />
            )}
            {questions.interpretationCorrect && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.interpretationCorrect}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.additionalChecksRequired}
                isOptionalQuestion={false}
            />
            {questions.v4rRecorded && (
                <ReadOnlyQuestionAndAnswer {...questions.v4rRecorded} isOptionalQuestion={true} />
            )}
            {questions.posteriorCheckRecorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.posteriorCheckRecorded}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.gtnAdministeredCorrectly}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.aspirinAdministeredCorrectly}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.appropriatePainReliefGivenCorrectly}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.additionalMedicationGiven}
                isOptionalQuestion={false}
            />
            {questions.appropriateMedicationAdministered && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.appropriateMedicationAdministered}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctRouteOfAdministration && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctRouteOfAdministration}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctDosage && (
                <ReadOnlyQuestionAndAnswer {...questions.correctDosage} isOptionalQuestion={true} />
            )}
            {questions.documentedCorrectly && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.documentedCorrectly}
                    isOptionalQuestion={true}
                />
            )}
            {questions.correctClinicianAdministering && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.correctClinicianAdministering}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.clinicAssessmentAppropriate}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer
                {...questions.differentialDiagnosticReasonable}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...questions.destination} isOptionalQuestion={false} />
            {questions.appropriateDestination && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.appropriateDestination}
                    isOptionalQuestion={true}
                />
            )}
            {questions.handoverTimes && (
                <ReadOnlyQuestionAndAnswer {...questions.handoverTimes} isOptionalQuestion={true} />
            )}
            {questions.patientOutcomeReasonable && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.patientOutcomeReasonable}
                    isOptionalQuestion={true}
                />
            )}
            {questions.conveyanceRefused && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.conveyanceRefused}
                    isOptionalQuestion={true}
                />
            )}
            {questions.pathfinderOutcome && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.pathfinderOutcome}
                    isOptionalQuestion={true}
                />
            )}
            {/** CAL contacted */}
            {questions.calContacted && (
                <ReadOnlyQuestionAndAnswer {...questions.calContacted} isOptionalQuestion={true} />
            )}
            {/** NEWS2 score recorded */}
            {questions.news2ScoreRecorded && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.news2ScoreRecorded}
                    isOptionalQuestion={true}
                />
            )}
            {/** Worsening care advice documented */}
            {questions.worseningCareAdviceGiven && (
                <ReadOnlyQuestionAndAnswer
                    {...questions.worseningCareAdviceGiven}
                    isOptionalQuestion={true}
                />
            )}
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlyCardiacChestPainAudit;
