import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AuditListResponse} from "../../../api/prf";

export const AUDIT_TRAIL_LOGS_STORE = createStoreState("audit_trail_logs");

interface AuditTrailLogsLoading extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_TRAIL_LOGS_STORE.LOADING;
}
interface AuditTrailLogsError extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_TRAIL_LOGS_STORE.ERROR;
}
interface AuditTrailLogsSuccess extends StoreServiceData<AuditListResponse> {
    type: typeof AUDIT_TRAIL_LOGS_STORE.SUCCESS;
}

export type AuditTrailLogsDispatchTypes =
    | AuditTrailLogsLoading
    | AuditTrailLogsError
    | AuditTrailLogsSuccess;
