export function minutesToMilliseconds(x: number) {
    return x * 60000; //60000 = 1 minute in milliseconds
}

/** Calculates percentages */
export function getPercentage(value: number, max: number): number {
    return (value / max) * 100;
}

/** Generates a random number */
export function getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** Finds the value of a percentage  */
export function getValueFromPercentage(percentage: number, maxValue: number) {
    const value = (percentage / 100) * maxValue;
    return isNaN(value) ? 0 : value;
}

/** Calculates percentages */
export function getFlooredPercentage(value: number, max: number): number {
    return Math.floor((value / max) * 100);
}
