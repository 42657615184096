import {StaffAccessLevel, UserData} from "../../../../../api/staff";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../../Navigation/routeNames";
import {McConfig} from "../../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../../utils/userDataUtils";

export function getNavItemsForReadOnlyAuditRouteForUser(
    user: UserData,
    auditId: number,
    config: McConfig
): NavigationItem[] {
    const systemAccessLevel = getUserAccessLevel(user, config);
    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                },
                {
                    name: routeNames.reviewAudit.name,
                    path: getReviewAuditPathForSidebar(auditId)
                }
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.overview.name,
                    path: routeNames.overview.path
                },
                {
                    name: routeNames.auditList.name,
                    path: routeNames.auditList.path
                },
                {
                    name: routeNames.reports.name,
                    path: routeNames.reports.path
                },
                {
                    name: routeNames.reviewAudit.name,
                    path: getReviewAuditPathForSidebar(auditId)
                }
            ];
    }
}

function getReviewAuditPathForSidebar(auditId: number): string {
    return `${routeNames.reviewAudit.path}/${auditId}`;
}

export interface QuestionDetails {
    question: string;
    answer?: string;
    comments?: string;
    conveyance?: string;
    isOptionalQuestion: boolean;
}
