import React from "react";
import {RecognitionOfLifeExtinctAudit} from "../../../../../../../api/prf";
import {ReadOnlyQuestions} from "../ReadOnlyBaseQuestions";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyRecognitionOfLifeExtinctAudit = ({
    questions
}: ReadOnlyQuestions<RecognitionOfLifeExtinctAudit>) => {
    return (
        <React.Fragment>
            {/** History of presenting complaint/collapse */}
            <ReadOnlyQuestionAndAnswer
                {...questions.historyOfPresentingComplaintOrCollapse}
                isOptionalQuestion={false}
            />
            {/** Primary Survey */}
            <ReadOnlyQuestionAndAnswer {...questions.primarySurvey} isOptionalQuestion={false} />
            {/** CPR not performed in the last 15 minutes */}
            <ReadOnlyQuestionAndAnswer
                {...questions.cprNotPerformedInLast15Minutes}
                isOptionalQuestion={false}
            />
            {/** Asystole rhythm strip */}
            <ReadOnlyQuestionAndAnswer
                {...questions.asystoleRhythmStrip}
                isOptionalQuestion={false}
            />
            {/** Rigor mortis present */}
            <ReadOnlyQuestionAndAnswer
                {...questions.rigorMortisPresent}
                isOptionalQuestion={false}
            />
            {/** Hypostasis present */}
            <ReadOnlyQuestionAndAnswer
                {...questions.hypostasisPresent}
                isOptionalQuestion={false}
            />
            {/** Factors incompatible with life present */}
            <ReadOnlyQuestionAndAnswer
                {...questions.factorsIncompatibleWithLifePresent}
                isOptionalQuestion={false}
            />
            {/** Time of verification recorded */}
            <ReadOnlyQuestionAndAnswer
                {...questions.timeOfVerificationRecorded}
                isOptionalQuestion={false}
            />
            {/** Guidelines followed */}
            <ReadOnlyQuestionAndAnswer
                {...questions.guidelinesFollowed}
                isOptionalQuestion={false}
            />
            {/** Police requested */}
            <ReadOnlyQuestionAndAnswer {...questions.policeRequested} isOptionalQuestion={false} />
            {/** Call closed correctly */}
            <ReadOnlyQuestionAndAnswer
                {...questions.callClosedCorrectly}
                isOptionalQuestion={false}
            />
        </React.Fragment>
    );
};

export default ReadOnlyRecognitionOfLifeExtinctAudit;
