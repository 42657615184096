import React from "react";
import {OutcomeQuestions} from "../../../../../../../api/prf";
import ReadOnlyQuestionAndAnswer from "../../Question/ReadOnlyQuestionAndAnswer";

const ReadOnlyConveyedOutcome = (props: OutcomeQuestions) => {
    return (
        <React.Fragment>
            <ReadOnlyQuestionAndAnswer
                {...props.generalDistrictHospital}
                isOptionalQuestion={false}
            />
            <ReadOnlyQuestionAndAnswer {...props.preAlert} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.ppci} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.hac} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.arrhythmiaCentre} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.hasu} isOptionalQuestion={false} />
            <ReadOnlyQuestionAndAnswer {...props.mtc} isOptionalQuestion={false} />
        </React.Fragment>
    );
};

export default ReadOnlyConveyedOutcome;
