import {Enum} from "../../../utils/enumUtils";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {getUserFlags} from "../../../utils/userDataUtils";
import {
    CalendarSummary,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffLink,
    Venue
} from "../../../api/grs";
import moment from "moment";
import {formatUnixToHHmmddd} from "../../../utils/momentUtils";
import {GrsLocation} from "../../../api/prf";
import {
    decapitalizeFirstLetter,
    removeWhitespaceFromString,
    splitStringByCapitalLetter
} from "../../../utils/textUtils";

/** Props used for the staff dropdown */
export interface StaffDropdownProps extends BasicDropdownProps {
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember: StaffLink) => void;
    clearable: boolean;
}

/** Props used for the staff dropdown */
export interface AssignedStaffDropdownProps {
    clinicianId: string;
    location: GrsLocation | undefined;
    disabled: boolean;
    searchable: boolean;
    changeOption: (staffMember: StaffLink) => void;
    clearable: boolean;
    className: string;
}

/** Props used for the venue dropdown */
export interface VenueDropdownProps {
    calendarId?: number;
    initialVenue: Venue;
    changeOption: (venue: Venue) => void;
    clearable: boolean;
}

export interface CalendarEntryDropdownProps {
    startDate: number;
    changeOption: (location: GrsLocation | undefined) => void;
    searchable: boolean;
    location: GrsLocation | undefined;
    disabled: boolean;
    className: string;
}

/** Props used for the venue dropdown */
export interface CalendarDropdownProps {
    id: number;
    changeOption: (calendar: CalendarSummary) => void;
    clearable: boolean;
}

/** Props used for the staff assignment dropdown */
export interface StaffAssignmentDropdownProps {
    initialAssignment?: StaffLink;
    changeOption: (newAssignment?: StaffLink) => void;
    isClearable: boolean;
}

/** Props used for the generic type dropdown */
export interface GenericTypeDropDownProps<E extends Enum<E>> extends ExtendedDropdownProps {
    enumOptions: E;
    splitByCapitalLetter: boolean;
}

/** Props used for the extended props for dropdown props */
export interface ExtendedDropdownProps extends DropDownProps {
    clearable: boolean;
    searchable: boolean;
}

/** Props used for the extended props for basic dropdown props */
export interface DropDownProps extends BasicDropdownProps {
    changeOption: (id: number | string) => void;
}

/** limited access (id = undefined) and basic needed for dropdown props */
export interface LimitedDropdownProps {
    id?: number | string;
    disabled: boolean;
    changeOption: (id?: number | string) => void;
}

/** bare minimum needed for dropdown props */
export interface BasicDropdownProps {
    id: number | string;
    disabled: boolean;
}

/** Dropdown component takes these as arguments for its object. */
export interface DDProps {
    value: number | string;
    label: string;
}

/** Sorts the list of staff into dropdown props to be used by staff dropdown */
export function sortUserDataToDropdownProps(users: UserData[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const user of users) {
        const flags = getUserFlags(user.flags);
        ddProps.push({
            label: `${user.firstName} ${user.lastName} ${flags}`,
            value: user.username
        });
    }

    return ddProps;
}

/** Sorts the list of calendars into dropdown props to be used throughout PRF */
export function sortCalendarSummaryListToDropdownProps(summaries: CalendarSummary[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const summary of summaries) {
        ddProps.push({
            label: summary.name,
            value: summary.id
        });
    }
    return ddProps;
}

/** Sorts the list of venues into dropdown props to be used by staff dropdown */
export function sortVenuesToDropdownProps(venues: Venue[]): DDProps[] {
    const ddProps: DDProps[] = [];
    for (const venue of venues) {
        ddProps.push({
            label: venue.name,
            value: venue.id
        });
    }

    return ddProps;
}

/** Sorts the list of assigned staff members in an entry into dropdown props to be used by staff dropdown */
export function sortAssignedStaffInEntryToDropdownProps(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                const staff = assignment.staffMember;

                if (staff && staff.staffId.length > 0 && staff.staffName.length > 0) {
                    ddProps.push({
                        label: staff.staffName,
                        value: staff.staffId
                    });
                }
            }
        }
    }

    return ddProps;
}

/** Converts the staffLinkList to dd props */
export function convertStaffLinkDetailsToDropdownProps(staffList: StaffLink[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const staff of staffList) {
        ddProps.push({
            label: staff.staffName,
            value: staff.staffId
        });
    }
    return ddProps;
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOptionByValue(id: string | number, options: DDProps[]): DDProps {
    if (options.length === 0) {
        return {
            value: -1,
            label: "No available options"
        };
    }

    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return options[0];
    }

    return options[index];
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOptionByLabel(id: string | number, options: DDProps[]): DDProps {
    const standardisedEnums = convertDropdownLabelBackToStandardisedEnums(options);
    const index = standardisedEnums.findIndex((el: DDProps) => id === el.label);

    if (index < 0) {
        return options[0];
    }

    return options[index];
}

/** Converts the options that were generated by enums back into a standardised form */
function convertDropdownLabelBackToStandardisedEnums(options: DDProps[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const option of options) {
        ddProps.push({
            ...option,
            label: decapitalizeFirstLetter(removeWhitespaceFromString(option.label))
        });
    }

    return ddProps;
}

/** Generates time options for the entry */
export function generateDailyTimeOptions(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    optionsPerHour: number,
    numberOfDaysInHours = 24
): DDProps[] {
    const startDate = moment.unix(entry.startDate).startOf("day").unix();
    const options = generateTimeOptions(optionsPerHour, numberOfDaysInHours).map(
        (time: number) => startDate + time
    );
    const ddProps: DDProps[] = [];
    for (const timeOption of options) {
        ddProps.push({
            value: timeOption,
            label: formatUnixToHHmmddd(+timeOption)
        });
    }

    return ddProps;
}

/** Math functions to get epoch time in a readable format for the UI */
function generateTimeOptions(optionsPerHour: number, numberOfDaysInHours: number): number[] {
    const timeOptions: number[] = [];

    //3600 = 1 Hour
    const timeSegmentsPerHour = 3600 / optionsPerHour;
    let timeOffset = 0;

    //24 = Number of hours in a day.
    for (let i = 0; i < numberOfDaysInHours * optionsPerHour; ++i) {
        timeOptions.push(timeOffset);
        //Increment after it has been pushed to the list to get the starting hour of the day
        timeOffset += timeSegmentsPerHour;
    }
    return timeOptions;
}

//Sorts the raw list of entries into a usable format for the dropdown component
export function sortCalendarEntriesToDropdownProps(
    entries: EventCalendarEntry[] | FrontlineCalendarEntry[],
    venues: Venue[]
): DDProps[] {
    if (entries.length === 0) return [];

    const ddProps: DDProps[] = [];

    for (const entry of entries) {
        if (!entry.description) continue; //Not possible due to validation in GRS not allowing this.

        ddProps.push({
            value: entry.id,
            label: getCalendarEntryDropdownNameForDropdownOption(entry, venues)
        });
    }

    return ddProps;
}

/** converts the enum into dropdown options */
export function getGenericDropdownOptions(options: Enum<any>, splitString: boolean): DDProps[] {
    const ddProps: DDProps[] = [];
    const enumOptions: (string | number)[] = Object.keys(options);

    for (const option of enumOptions) {
        const label = splitString
            ? splitStringByCapitalLetter(option.toString())
            : option.toString();
        //Label is selectively rendered based on the props
        ddProps.push({
            value: option,
            label
        });
    }

    return ddProps;
}

function getCalendarEntryDropdownNameForDropdownOption(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[]
): string {
    switch (entry.calendarType) {
        case CalendarTypeEnum.Frontline:
            return entry.description ? entry.description : "";
        case CalendarTypeEnum.Event:
            return getVenueNameForEntry(entry, venues);
        default:
            return "";
    }
}

//Checks to see if the entry is a front line event. If so, return the entry name, otherwise, get the venue name
export function getVenueNameForEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[]
): string {
    if (!entry.description) return "";
    const index = venues.findIndex((el: Venue) => el.id === entry.venueId);

    //If the index is -1, return the entry description as a fallback
    if (index < 0) {
        return entry.description;
    }

    return venues[index].name;
}

/** Sorts staffLink data to DD Props */
export function sortStaffLinkArrayToDropdownProps(staffList: StaffLink[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const staff of staffList) {
        ddProps.push({
            label: staff.staffName,
            value: staff.staffId
        });
    }
    return ddProps;
}
