import store from "../store/Store";
import {StaffLink} from "../api/prf";
import {UserData} from "../api/staff";
import {McConfig} from "../McConfigPlugin";

/** Returns the user flags as one string */
export function getUserFlags(flags: string[] | undefined): string {
    //Undefined flags
    if (!flags) return "";

    //If flags exist but the array is empty.
    if (flags.length < 1) {
        return "";
    }

    //If one flag exists, and it is more than 0 characters.
    if (flags.length === 1 && flags[0].length > 0) {
        return `(${flags[0]})`;
    }

    //More than 1 flag.
    return `(${flags.join(", ")})`;
}

/** Gets the staff member from the staff list store and converts details into Staff link */
export function getStaffLinkFromUserDataForStaffMember(staffId: string): StaffLink {
    const staffList = store.getState().staffList.data;
    const userNotFound: StaffLink = {
        staffId,
        staffName: "Could not find user"
    };
    if (!staffList) {
        return userNotFound;
    }

    const index = staffList.findIndex((user: UserData) => user.username === staffId);

    if (index < 0) return userNotFound;

    const staffName = `${staffList[index].firstName} ${staffList[index].lastName}`;

    return {
        staffId,
        staffName
    };
}

/** Added in to account for custom ACLs users might have per system.
 * Gets users access level based on module. If there is no data, take
 * default access level and use that*/
export function getUserAccessLevel(user: UserData, config: McConfig) {
    const {moduleName} = config.systemDetails;

    return user.accessDetail![moduleName] || user.accessLevel;
}
